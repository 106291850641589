import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import css from './IconCamera.css';

const IconCamera = props => {
  const { rootClassName, className, wrapperClass, clickFunc } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={wrapperClass} onClick={clickFunc}>
      <svg
        className={classes}
        version="1.1"
        viewBox="0 0 84.667 76.187"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-53.994 170.47)">
          <path
            d="m59.295-94.864c-1.9788-0.75963-3.9806-2.7879-4.7309-4.7932-0.56973-1.5227-0.56986-1.529-0.56986-28.491s1.27e-4 -26.968 0.56986-28.491c0.76892-2.0551 2.7509-4.0372 4.8061-4.8061 1.4274-0.53405 1.9929-0.56985 9.0015-0.56985h7.4785l7.6451-8.4535h25.665l7.6451 8.4535h7.4784c7.0086 0 7.5741 0.0358 9.0015 0.56985 2.0551 0.76893 4.0372 2.751 4.8061 4.8061 0.56973 1.5228 0.56985 1.529 0.56985 28.491s-1.2e-4 26.968-0.56985 28.491c-0.76893 2.0551-2.751 4.0372-4.8061 4.8061l-1.5231 0.56986-35.51-0.0138c-35.412-0.0138-35.514-0.0153-36.956-0.56893zm42.397-12.891c1.1312-0.29187 3.1553-1.0944 4.4979-1.7835 2.0004-1.0266 2.8973-1.709 4.9679-3.7795 2.0706-2.0706 2.7529-2.9674 3.7795-4.9679 1.8233-3.5527 2.3692-5.8252 2.3692-9.862s-0.54592-6.3093-2.3692-9.862c-1.0266-2.0004-1.709-2.8973-3.7795-4.9679-2.0706-2.0706-2.9674-2.7529-4.9679-3.7795-3.5527-1.8233-5.8252-2.3692-9.862-2.3692-4.0368 0-6.3093 0.54592-9.862 2.3692-2.0004 1.0266-2.8973 1.709-4.9679 3.7795-2.0706 2.0706-2.7529 2.9674-3.7795 4.9679-1.8233 3.5527-2.3692 5.8252-2.3692 9.862s0.54592 6.3093 2.3692 9.862c1.0266 2.0004 1.709 2.8973 3.7795 4.9679 2.066 2.066 2.9609 2.7475 4.908 3.7376 1.3097 0.66598 2.8409 1.3582 3.4026 1.5383 3.5719 1.1451 8.1309 1.2553 11.883 0.28711zm-9.3863-7.41c-3.0422-0.97272-5.6312-2.9621-7.402-5.6878-2.7207-4.1878-2.8047-10.151-0.20132-14.307 2.6232-4.187 6.7396-6.4823 11.626-6.4823 4.8354 0 8.7414 2.1211 11.415 6.1986 2.8209 4.3024 2.8209 10.288 0 14.59-1.8345 2.7979-4.3666 4.739-7.4079 5.679-2.2038 0.6811-5.914 0.68517-8.0293 9e-3z"
            strokeWidth=".26458"
          />
        </g>
      </svg>
    </div>
  );
};

IconCamera.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconCamera.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconCamera;
