import classNames from 'classnames';
import { bool, func, number, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isSeller } from '../../util/userHelper';
import css from './TopbarDesktop.css';
import { useLocation } from 'react-router-dom';
const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    /* bottomBorder, */
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const isLandingPage = currentPage === 'LandingPage';
  const classes = classNames(
    rootClassName || css.root /* className, {
    [css.bottomBorder]: bottomBorder,
  } */
  );
  const location = useLocation();
  console.log(location);
  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      /* bottomBorder={bottomBorder} */
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
    style={location.pathname == '/inbox/sales' ? { "border-bottom": "4px solid #b21719", "color":"#b21719","text-decoration": "none"  , 'pointer-events': 'none' } : {}}
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        {/* {isLandingPage ? ( */}
        {/* <div className={css.textMenu}>Menu</div> */}
        {/* ) : ( */}
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        {/* )} */}
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {(isSeller(currentUser) && (
          <MenuItem key="ManageListingsPage">
            <NamedLink name={'ManageListingsPage'} className={css.yourListingsLink}>
              <div>
                <span className={css.menuItemBorder} />
                {/* {currentUserListing ? (
                  <FormattedMessage id="TopbarDesktop.editYourListingLink" />
                ) : (
                  <FormattedMessage id="TopbarDesktop.addYourListingLink" />
                )} */}
                Check <u>Your</u> Listings
              </div>
            </NamedLink>
          </MenuItem>
        )) || <MenuItem key="null"></MenuItem>}
        {/* <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            </NamedLink>
          </MenuItem> */}
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            {/* <FormattedMessage id="TopbarDesktop.accountSettingsLink" /> */}
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupButton}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const createListingLink =
    isAuthenticated && isSeller(currentUser) ? (
      <NamedLink 
      style={location.pathname.startsWith("/l")  ? { "border-bottom": "4px solid #b21719", "color":"#b21719","text-decoration": "none"   } : {}}
      className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          {/* <span className={css.redFancyFont}>Sell!</span> */} Sell Reservations
          {/* <FormattedMessage id="TopbarDesktop.createListing" /> */}
        </span>
      </NamedLink>
    ) : null;

  const buyReservationLink = (
    <NamedLink
      style={location.pathname == '/s' ? { "border-bottom": "4px solid #b21719", "color":"#b21719","text-decoration": "none"  ,'pointer-events': 'none'  } : {}}
      className={css.createListingLink}
      name="SearchPage"
      to={{ search: 'filter=false' }}
    >
      <span className={css.createListing}>
        {/* <span className={css.redFancyFont}>Sell!</span> */} Buy Reservation
        {/* <FormattedMessage id="TopbarDesktop.createListing" /> */}
      </span>
    </NamedLink>
  );
  return (
    <nav className={classes}>
      <NamedLink name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {/* {!isLandingPage && (
      <>{search}</>
      )} */}
      <div className={css.topRightNav}>
        {buyReservationLink}
        {createListingLink}
        {inboxLink}
        {profileMenu}
        <div className={css.topButton}>
          {loginLink}
          {signupLink}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
