import classNames from 'classnames';
import {arrayOf, bool, func, object, string } from 'prop-types';
import React from 'react';
import config from '../../config';
import { LocationSearchForm } from '../../forms';
import { NamedLink, OvalLinkType } from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { parse } from '../../util/urlHelpers';
import css from './SectionSearchBlock.css';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';


const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const SectionSearchBlock = props => {
  const { rootClassName, className, location, isAuthenticated, isSeller ,availabilityExceptions,updateInProgress,errors,showErrorMessage} = props;
  const { currentSearchParams, history } = props;
  const handleSubmit = values => {
    let locationParam = {};
    let dateParam = {};
    if(values.value != null){
      dateParam = {
        startTime:values.value.exceptionStartTime || moment(),
        endTime:values.value.exceptionEndTime || moment(),
      }
    }

    if(values.location != null){
     
      const { search, selectedPlace } = values.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      locationParam ={
        ...currentSearchParams,
        ...originMaybe,
        address: search,
        bounds,
      }
    }
    if(values.value == null && values.location == null){
      locationParam={
        filter:false
      }
    }
    
    const searchParams = {
      ...locationParam,
      ...dateParam
    };
    
    //  console.log(searchParams);return false;
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const classes = classNames(rootClassName || css.root, className);

  const { address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const locationFieldsPresent = config.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;
  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : null,
    keywords: null,
  };
  const link =
    isAuthenticated && isSeller ? (
      <NamedLink name={'NewListingPage'}>
        {/* <FormattedMessage id="SectionHero.secondTitle" /> */}
        Sell! Your Reservation <span className={css.goldFancyFont}>Here</span>
      </NamedLink>
    ) : isAuthenticated ? (
      <NamedLink name={'ProfileSettingsPage'}>
        {/* <FormattedMessage id="SectionHero.secondTitle" /> */}
        Sell! Your Reservation <span className={css.goldFancyFont}>Here</span>
      </NamedLink>
    ) : (
      <NamedLink name={'SignupPage'}>
        {/* <FormattedMessage id="SectionHero.secondTitle" /> */}
        Sell! Your Reservation <span className={css.goldFancyFont}>Here</span>
      </NamedLink>
    );

    const initialValuesException =
    !!availabilityExceptions.length && !!availabilityExceptions[0]
      ? {
          seats: 1,
          exceptionStartDate: { date: new Date(availabilityExceptions[0].attributes.start) },
          exceptionStartTime: String(availabilityExceptions[0].attributes.start.getTime()),
          exceptionEndDate: { date: new Date(availabilityExceptions[0].attributes.end) },
          exceptionEndTime: String(availabilityExceptions[0].attributes.end.getTime()),
        }
      : null;

  return (
    <div className={classes}>
      {/*<div className={css.sectionSearchBlockTitle}>
        <span className={css.redFancyFont}>Snatch!</span> That Reservation
         <FormattedMessage id="SectionHero.title" /> 
      </div>*/}
      <LocationSearchForm
        onSubmit={handleSubmit}
        initialValues={initialSearchFormValues}
        isMobile
        className={css.searchBackground}
        timeZone={defaultTimeZone()}
        availabilityExceptions={availabilityExceptions}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        initialValues={initialValuesException}
        showErrorMessage={showErrorMessage}
      />
      {/* <div className={css.sectionSearchBlockTitle}> */}
        {/* <FormattedMessage id="SectionHero.secondTitle" /> */}
        {/* <div className={css.linkHolder}> */}
          {/* <OvalLinkType link={link} /> */}

          {/* <KeywordSearchForm
        onSubmit={handleKeywordSubmit}
        initialValues={initialSearchFormValues}
        isMobile
      /> */}
        {/* </div> */}
      {/* </div> */}
    </div>
  );
};

SectionSearchBlock.defaultProps = { rootClassName: null, className: null ,availabilityExceptions: []};

SectionSearchBlock.propTypes = {
  rootClassName: string,
  className: string,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default SectionSearchBlock;
