import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  PaginationLinks,
  SearchResultsCard,
} from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { getPropByName } from '../../util/userHelper';
import css from './ManageListingsPage.css';
import {
  closeListing,
  getOwnListingsById,
  openListing,
  deleteListing,
  queryOwnListings,
} from './ManageListingsPage.duck';
import { createResourceLocatorString } from '../../util/routes';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

export class ManageListingsPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { listingMenuOpen: null, sortVariable: 'reservationStart' };
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.sortFunction = this.sortFunction.bind(this);
    this.handleClickHeader = this.handleClickHeader.bind(this);
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  sortFunction(a, b) {
    return getPropByName(a, this.state.sortVariable) > getPropByName(b, this.state.sortVariable)
      ? -1
      : 1;
  }

  handleClickHeader(val) {
    const sortVar = this.state.sortVariable === '-pub_' + val ? 'pub_' + val : '-pub_' + val;
    this.setState({ sortVariable: sortVar });
    this.handleSortBy(sortVar);
  }
  onToggleMenu(listing) {
    this.setState({ listingMenuOpen: listing });
  }

  handleSortBy(val) {
    const { history } = this.props;
    const queryParams = { sort: val };

    history.push(
      createResourceLocatorString('ManageListingsPage', routeConfiguration(), {}, queryParams)
    );
  }

  render() {
    const {
      closingListing,
      closingListingError,
      listings,
      onCloseListing,
      onOpenListing,
      onDeleteListing,
      openingListing,
      openingListingError,
      pagination,
      queryInProgress,
      queryListingsError,
      queryParams,
      scrollingDisabled,
      missingResults,
      intl,
    } = this.props;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const listingsAreLoaded = !queryInProgress && hasPaginationInfo;

    const loadingResults = (
      <h1 className={css.title}>
        <FormattedMessage id="ManageListingsPage.loadingOwnListings" />
      </h1>
    );

    const queryError = (
      <h2 className={css.error}>
        <FormattedMessage id="ManageListingsPage.queryError" />
      </h2>
    );

    const noResults =
      listingsAreLoaded && pagination.totalItems === 0 ? (
        <h1 className={css.title}>
          <FormattedMessage id="ManageListingsPage.noResults" />
        </h1>
      ) : (
        loadingResults
      );
    const heading =
      listingsAreLoaded && pagination.totalItems > 0 ? (
        <h1 className={css.title}>
          <FormattedMessage
            id="ManageListingsPage.youHaveListings"
            values={{ count: pagination.totalItems + missingResults }}
          />
        </h1>
      ) : (
        noResults
      );

    const page = queryParams ? queryParams.page : 1;
    const paginationLinks =
      listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="ManageListingsPage"
          pageSearchParams={{ page }}
          pagination={pagination}
        />
      ) : null;
    const listingMenuOpen = this.state.listingMenuOpen;
    const closingErrorListingId = !!closingListingError && closingListingError.listingId;
    const openingErrorListingId = !!openingListingError && openingListingError.listingId;

    const title = intl.formatMessage({ id: 'ManageListingsPage.title' });

    const panelWidth = 62.5;
    // Render hints for responsive image
    const renderSizes = [
      `(max-width: 767px) 100vw`,
      `(max-width: 1920px) ${panelWidth / 2}vw`,
      `${panelWidth / 3}vw`,
    ].join(', ');

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ManageListingsPage" bottomBorder />
            {/* <UserNav selectedPageName="ManageListingsPage" currentUser={currentUser} /> */}
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {/* {queryInProgress ? loadingResults : null} */}
            {queryListingsError ? queryError : null}
            <div className={css.listingPanel}>
              {heading}
              <div className={css.listingCards}>
                <table className={css.table}>
                  <thead className={css.thead}>
                    <tr className={css.tr}>
                      <th
                        className={classNames(css.th, css.headerLink)}
                        scope="col"
                        // onClick={e => {
                        //   e.preventDefault();
                        //   this.handleClickHeader('reservationStart');
                        // }}
                      >
                        Date
                      </th>
                      <th
                        className={classNames(css.th, css.headerLink)}
                        scope="col"
                        // onClick={e => {
                        //   e.preventDefault();
                        //   this.handleClickHeader('reservationStart');
                        // }}
                      >
                        Time
                      </th>
                      <th className={classNames(css.th, css.doubleCell)} scope="col">
                        Name
                      </th>
                      <th className={css.th} scope="col">
                        {' '}
                        ${' '}
                      </th>
                      <th
                        className={classNames(css.th, css.headerLink, css.hideSmall)}
                        scope="col"
                        // onClick={e => {
                        //   e.preventDefault();
                        //   this.handleClickHeader('capacity');
                        // }}
                      >
                        Table Size
                      </th>
                      <th className={classNames(css.th, css.hideSmall)} scope="col">
                        Cuisine Type
                      </th>
                      <th className={classNames(css.th)} scope="col">
                        Closed
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listings
                      .sort((a, b) => this.sortFunction(a, b))
                      .map(l => (
                        <SearchResultsCard
                          className={css.listingCard}
                          key={l.id.uuid}
                          listing={l}
                          isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
                          actionsInProgressListingId={openingListing || closingListing}
                          onToggleMenu={this.onToggleMenu}
                          onCloseListing={onCloseListing}
                          onOpenListing={onOpenListing}
                          onDeleteListing={onDeleteListing}
                          hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
                          hasClosingError={closingErrorListingId.uuid === l.id.uuid}
                          renderSizes={renderSizes}
                          manageCard
                          css={css}
                          page={'listing'}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
              {paginationLinks}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ManageListingsPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

ManageListingsPageComponent.propTypes = {
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(propTypes.ownListing),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = state.ManageListingsPage;
  const { currentUser } = state.user;
  const listings = getOwnListingsById(state, currentPageResultIds);
  const listingCount = listings.length;
  const resultCount = currentPageResultIds.length;
  const missingResults = listingCount - resultCount
  return {
    currentUser,
    currentPageResultIds,
    missingResults,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
  onDeleteListing: listingId => dispatch(deleteListing(listingId)),
});

const ManageListingsPage = compose(
  withRouter,

  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ManageListingsPageComponent);

ManageListingsPage.loadData = (params, search) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'author'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default ManageListingsPage;
