import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';
import {
  ExternalLink,
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  NamedLink,
} from '../../components';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { twitterPageURL } from '../../util/urlHelpers';
import css from './Footer.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <div key="fbLink" className={css.iconWrapper}>
      <ExternalLink
        key="linkToFacebook"
        href={siteFacebookPage}
        className={css.icon}
        title={goToFb}
      >
        <IconSocialMediaFacebook className={css.socialMediaIcon} />
      </ExternalLink>
    </div>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <div key="twLink" className={css.iconWrapper}>
      <ExternalLink
        key="linkToTwitter"
        href={siteTwitterPage}
        className={css.icon}
        title={goToTwitter}
      >
        <IconSocialMediaTwitter className={css.socialMediaIcon} />
      </ExternalLink>
    </div>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <div key="instaLink" className={css.iconWrapper}>
      <ExternalLink
        key="linkToInstagram"
        href={siteInstagramPage}
        className={css.icon}
        title={goToInsta}
      >
        <IconSocialMediaInstagram className={css.socialMediaIcon} />
      </ExternalLink>
    </div>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.desktopSocialLinks}>{socialMediaLinks}</div>
            <div className={css.infoLinks}>
              <div className={css.list}>
                <div className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </div>
                <div className={css.listItem}>
                  <NamedLink name="FAQPage" className={css.link}>
                    <FormattedMessage id="Footer.toFAQPage" />
                  </NamedLink>
                </div>
                <div className={css.listItem}>
                  <NamedLink name="ContactPage" className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </div>
                <div className={css.listItem}>
                  <NamedLink name="TermsOfServicePage" className={css.link}>
                    <FormattedMessage id="Footer.termsOfUse" />
                  </NamedLink>
                </div>
                <div className={css.listItem}>
                  <a className={css.link} href="mailto:TablesSnatchReso@gmail.com">
                    Claims
                  </a>
                </div>
                <div className={css.listItem}>
                  <a href="mailto:TableSnatcher@gmail.com" className={css.link}>
                    Support
                  </a>
                </div>
                <div className={css.listItem}>
                  <NamedLink name="PrivacyPolicyPage" className={css.link}>
                    <FormattedMessage id="Footer.privacyPolicy" />
                  </NamedLink>
                </div>
              </div>
            </div>
          </div>
          <div className={css.bottomLinks}>
            <div className={css.organizationInfo}>
              <p className={css.organizationCopyright}>
                <NamedLink name="LandingPage" className={css.copyrightLink}>
                  <FormattedMessage id="Footer.copyright" />
                  <span> • Powered by The Reservation Exchange, LLC • </span>
                </NamedLink>
                <span className={css.copyrightLink}>
                  <FormattedMessage id="Footer.patentPending" /> |{' '}
                  <FormattedMessage id="Footer.tradename" />
                </span>
              </p>
              {/* <p className={css.specialLegal}>
                <span className={css.copyrightLink}>
                  <FormattedMessage id="Footer.patentPending" /> |{' '}
                  <FormattedMessage id="Footer.tradename" />
                </span>
              </p> */}
            </div>

            <div className={css.legalMatters}>
              {/* <div className={css.tosAndPrivacy}>
                <div>
                  <a href="mailto:TablesSnatchReso@gmail.com" className={css.legalLink}>
                    Claims
                  </a>
                </div>
                <div>
                  <a href="mailto:TableSnatcher@gmail.com" className={css.legalLink}>
                    Support
                  </a>
                </div>
                <div>
                  <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                    <FormattedMessage id="Footer.privacyPolicy" />
                  </NamedLink>
                </div>
              </div> */}
              <div className={css.useDisclaimer}>
                <p className={css.useDisclaimerText}>
                  Using our site means you agree to the Terms of Use.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
