/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { intlShape } from '../../util/reactIntl';
import {  LINE_ITEM_PROCESSING_FEE, propTypes } from '../../util/types';
import css from './BookingBreakdown.css';

const LineItemProcessingFeeMaybe = props => {
  const { transaction, isProvider, intl } = props;

  const processingFeeLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROCESSING_FEE && !item.reversal
  );
  if (!processingFeeLineItem) {
    return null;
  }

  const label = humanizeLineItemCode(processingFeeLineItem.code);

  const formattedTotal = formatMoney(intl, processingFeeLineItem.lineTotal);
  return !isProvider ? (
    <React.Fragment>
      <div key={`${processingFeeLineItem.code}-item.code`} className={css.lineItem}>
        <span className={css.itemLabel}>{label}</span>
        <span className={css.itemValue}>{formattedTotal}</span>
      </div>
    </React.Fragment>
  ) : null;
};

LineItemProcessingFeeMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProcessingFeeMaybe;
