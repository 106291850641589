import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import css from './IconHelperMan.css';

const IconHelperMan = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      version="1.1"
      viewBox="0 0 596.4 1661.3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="clipPath18">
          <path d="m0 1599h1050v-1599h-1050z" />
        </clipPath>
      </defs>
      <g transform="matrix(1.3333 0 0 -1.3333 -401.8 1896.7)">
        <g clip-path="url(#clipPath18)">
          <g transform="translate(628.84 1122.7)">
            <path
              d="m0 0c-1.426-8.098-3.148-16.172-4.638-24.24l-0.957-5.402c-2.469-14.654-6.31-27.257-3.283-42.027 0.095-0.464-0.104-2.994 0-3.459 0.922-5.533 0.792-12.183 0.691-14.295-0.691-14.526-0.461-7.43-0.461-16.601 0-13.696-1.153-19.368-0.098-36.119l4.485-44.517c1.396-8.079 1.138-13.413 0.242-16.944 0 0 30.175-28.117 30.176-28.117 9.851 17.6 7.14 48.798 6.698 72.666-0.692 37.352-1.218 55.652-1.614 89.691-0.061 5.194-0.183 19.815-0.346 25.006-0.604 19.273-15.581 33.982-30.895 44.358"
              style={{ fill: '#ffd6b5' }}
            />
          </g>
          <g transform="translate(422.37 952.83)">
            <path
              d="m0 0c0 3e-3 2.306 44.961 2.306 44.961 0.231 7.148-1.153 12.03-1.153 18.907 0 6.928-1.756 13.43 0.922 19.829 1.203 2.872-0.083 5.494 1.614 7.839 0.146 4.785-2.807 9.584-3.441 14.327-0.132 0.986-0.794 3.818-1.785 7.839-0.07 0.287-0.143 0.58-0.217 0.88-0.111 0.448-0.225 0.91-0.343 1.384-0.079 0.316-0.159 0.638-0.24 0.964-0.327 1.308-0.675 2.699-1.041 4.158-0.229 0.912-0.465 1.851-0.707 2.812-0.194 0.77-0.391 1.554-0.591 2.351-3.077 12.247-6.889 27.517-9.284 38.869-6.127-5.047-12.423-10.668-16.028-17.808-4.347-8.614-5.832-18.467-6.138-28.019-1.075-33.5-1.117-67.213 0.091-100.75 1.147-31.88-6.331-69.37 6.669-79.37 7.926-6.097 14-7 30.42-9.097l52.9-3c0.108 0.61 0.21 1.219 0.296 1.824 1.045 7.35 1.574 14.769 1.601 22.189 0 0.948-23.174 5.723-25.462 6.288-8.306 2.05-16.612 4.099-24.924 6.124-0.75 0.183-1.525 0.377-2.135 0.851-1.636 1.273-1.956 4.473-2.425 6.386-0.772 3.145-1.044 6.446-1.179 9.673-0.273 6.478-0.058 13.117 0.274 19.591"
              style={{ fill: '#ffd6b5' }}
            />
          </g>
          <g transform="translate(622.8 1047.6)">
            <path
              d="m0 0c-0.104 0.464-0.207 0.928-0.302 1.393-3.027 14.769-1.717 29.422 0.753 44.076 0.303 1.801 0.624 3.601 0.956 5.402l4.329 24.448c-9.621 6.464-18.989 10.225-26.232 12.397-1.222-5.533-2.444-11.066-3.667-16.599-2.068-9.362-5.679-18.28-12.564-25.481-20.181-21.107-36.199-25.824-71.246-26.746-15.482-0.407-36.125 5.187-48.42 14.526-6.155 4.676-10.69 6.216-15.533 12.241-5.326 6.627-7.391 14.632-9.688 22.715-0.202 0.714-0.405 1.428-0.613 2.141-1.633 5.62-3.267 11.24-4.901 16.861-7.444-3.161-17.208-8.436-27.262-17.033 2.393-11.353 6.205-26.623 9.283-38.871 0.2-0.797 0.397-1.581 0.59-2.35 0.242-0.962 0.478-1.901 0.707-2.813 0.366-1.459 0.715-2.85 1.041-4.157 0.082-0.327 0.162-0.649 0.24-0.965 0.118-0.474 0.233-0.935 0.343-1.384 0.075-0.299 0.147-0.593 0.218-0.88 0.99-4.021 1.653-6.852 1.784-7.838 0.635-4.744 0.842-9.537 0.697-14.322-0.075-2.433-0.238-4.863-0.487-7.283-0.74-7.204-2.211-14.342-2.431-21.581-0.547-17.915 10.712-37.11 26.434-45.733 4.721-2.589 9.9-4.373 14.283-7.499 9.08-6.475 12.608-18.273 14.508-28.772 1.766-9.757 2.634-19.673 2.599-29.586l25.003-6.175c5.618 8.219 10.289 13.074 14.157 15.689-3.35 2.29-6.232 7.142 2.468 16.684 0 0 9.975 9.91 19.396-2.642 0 0 7.758-9.25 9.975-13.875l21.583-0.405c0.364 6.81 1.149 13.598 2.364 20.31 1.996 11.026 7.673 26.83 18.454 32.102 2.689 1.314 5.652 1.943 8.469 2.953 13.587 4.869 23.105 18.479 26.517 32.092 3.391 13.534 1.732 27.929-2.316 41.281-0.574 1.895-1.067 3.788-1.489 5.679"
              style={{ fill: '#a6cac8' }}
            />
          </g>
          <g transform="translate(570.1 1390.6)">
            <path
              d="m0 0c-1.487 9.128 11.455 21.782 18.248 25.725 22.513 13.067 54.752 3.677 72.629-13.01 49.065-45.796 36.522-119.89 8.36-172.91-6.823-12.846-27.095-37.341 0.704-40.21 0 0 20.108-0.591 21.938 13.004-1.421-10.557-4.042-20.776-13.531-27.442-18.593-13.06-46.108-4.452-60.903 10.215-16.695 16.549-20.426 38.854-23.983 60.679-2e-3 9e-3 -23.414 143.65-23.462 143.95"
                style={{ fill: '#141414' }}
            />
          </g>
          <g transform="translate(598.45 1391.9)">
            <path
              d="m0 0c-2.09-7.103-13.207-10.089-24.831-6.669-11.623 3.42-19.351 11.951-17.261 19.054s13.207 10.089 24.83 6.669c11.624-3.42 19.352-11.951 17.262-19.054"
              style={{ fill: '#a6cac8' }}
            />
          </g>
          <g transform="translate(442.43 1136.6)">
            <path
              d="M 0,0 153.79,0.23 C 129.696,8.917 108.575,20.503 104.431,29.468 88.63,18.788 66.706,9.796 49.173,24.645 39.343,10.895 0,0 0,0"
              style={{ fill: '#ffd6b5' }}
            />
          </g>
          <g transform="translate(546.86 1166.1)">
            <path
              d="m0 0c-2.69 5.819-2.556 11.246-1.393 15.707l-49.263-4.106c0.953-5.961-1.081-11.499-4.602-16.424 17.533-14.848 39.458-5.857 55.258 4.823"
              style={{ fill: '#f3caaa' }}
            />
          </g>
          <g transform="translate(431.03 1328.1)">
            <path
              d="m0 0s6.245-163.12 84.9-160.53c0 0 90.73-10.728 90.73 169.87l-28.874 50.86-104.31-2.508z"
              style={{ fill: '#ffd6b5' }}
            />
          </g>
          <g transform="translate(508.7 1281.5)">
            <path
              d="m0 0s-7.086-17.715-31.099-25.588c0 0 11.416-10.629 29.131-7.48"
              style={{fill:'none', strokeMiterlimit:'10', strokeWidth:'3.656',stroke:'#e4b69a'}}
            />
          </g>
          <g transform="translate(560.36 1391.8)">
            <path d="m0 0c2.458 10.809-16.958 17.62-24.253 19.915-17.743 5.583-36.263 3.84-53.631-2.271-19.04-6.699-35.739-19.718-46.724-37.019-12.879-20.284-20.197-44.744-20.432-69.358-0.126-13.281 4.596-27.714 17.177-32.803 0 0-10.431 32.866 19.312 36.804 32.854 3.422 92.945 16.155 108.55 84.732" />
          </g>
          <g transform="translate(528.92 1385.8)">
            <path d="m0 0s51.934-40.016 78.954-85.599c0 0 15.783 2.943 15.844 2.956 2.754 0.569 1.183 20.15 1.083 22.589-0.882 21.472-4.744 40.906-21.73 55.989-11.485 10.205-26.814 18.408-42.664 18.989-11.843 0.435-22.726-4.286-30.176-13.26-0.451-0.544-0.888-1.099-1.311-1.664" />
          </g>
          <g transform="translate(627.86 1279.6)">
            <path
              d="m0 0c-5.885-14.556-17.961-23.403-26.972-19.76s-11.545 18.397-5.66 32.953 17.961 23.403 26.972 19.76 11.545-18.397 5.66-32.953"
              style={{ fill: '#ffd6b5' }}
            />
          </g>
          <g transform="translate(543.16 1225.1)">
            <path
              d="m0 0s-4.809-19.304-27.061-20.927c-21.213-1.548-29.071 15.096-29.764 16.659-0.034 0.083-0.047 0.12-0.047 0.12s12.173-2.415 28.676-1.211c16.493 1.203 28.196 5.359 28.196 5.359"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(524.7 1202.6)">
            <path
              d="m0 0c7.164 2.63 10.888 6.612 10.888 6.612-0.733-4.789-6.578-8.186-9.595-9.64-0.192-0.09-0.397-0.143-0.596-0.158-0.764-0.055-1.492 0.423-1.711 1.214-0.231 0.818 0.21 1.676 1.014 1.972"
              style={{ fill: '#f44c66' }}
            />
          </g>
          <g transform="translate(526.02 1228.4)">
            <path
              d="m0 0c3.163 0.23 5.785-0.328 5.852-1.244 0.066-0.915-2.448-1.839-5.61-2.07-3.154-0.23-5.775 0.319-5.842 1.235s2.447 1.849 5.6 2.079"
              style={{ fill: '#f44c66' }}
            />
          </g>
          <g transform="translate(545.48 1225.5)">
            <path
              d="m0 0s-4.077-29.34-28.661-31.133c-22.667-1.653-31.483 22.39-32.715 26.154l2.237 0.286c0.694-1.563 8.551-18.207 29.765-16.659 22.252 1.623 27.061 20.927 27.061 20.927l0.776 2.107c0.79-0.294 1.222-0.481 1.222-0.481s2.305 0.406 3.54 2.337c0.418 0.648 1.332 0.743 1.927 0.255 0.293-0.245 0.46-0.584 0.487-0.942 0.026-0.359-0.1-0.72-0.374-0.996-0.717-0.717-2.204-1.537-5.265-1.855m-19.48-25.965c3.016 1.454 8.862 4.851 9.594 9.641 0 0-3.724-3.983-10.887-6.613-0.805-0.295-1.245-1.153-1.015-1.972 0.219-0.79 0.947-1.269 1.712-1.213 0.198 0.014 0.403 0.067 0.596 0.157"
              style={{ fill: '#d71937' }}
            />
          </g>
          <g transform="translate(486.29 1220.9)">
            <path
              d="m0 0s0.012-0.037 0.046-0.12l-2.237-0.286c-0.109 0.314-0.16 0.491-0.16 0.491-3.084-0.13-4.665 0.466-5.488 1.071-0.311 0.233-0.478 0.572-0.504 0.931-0.026 0.349 0.09 0.718 0.344 1.003 0.519 0.569 1.437 0.608 1.944 0.027 1.502-1.731 3.842-1.798 3.842-1.798s16.176 10.102 30.394 4.248c11.135 6.611 25.283 2.233 29.467 0.688l-0.776-2.107s-11.704-4.157-28.197-5.359c-16.502-1.204-28.675 1.211-28.675 1.211m45.581 6.181c-0.067 0.916-2.689 1.475-5.852 1.244-3.153-0.23-5.667-1.163-5.6-2.079s2.688-1.465 5.842-1.235c3.162 0.231 5.676 1.155 5.61 2.07"
              style={{ fill: '#d71937' }}
            />
          </g>
          <g transform="translate(529.26 254.22)">
            <path
              d="m0 0s-30.999-31.92 0-77.718c0 0 33.383 11.796 19.076 64.534z"
              style={{ fill: '#191818' }}
            />
          </g>
          <g transform="translate(554.69 254.22)">
            <path
              d="m0 0s-8.743 37.471 6.359 75.636c4.463 11.279 6.603 23.57 8.672 35.304 5.56 31.542 8.862 65.2 4.629 96.96-2.92 21.904-13.916 52.733 3.39 72.44 0 0 43.717 74.248 17.487 117.27l-89.817-56.207 35.768-341.4z"
              style={{ fill: '#ecbb97' }}
            />
          </g>
          <g transform="translate(557.08 177.19)">
            <path
              d="m0 0s11.127 2.082 13.512 31.226c0 0-0.368 15.701-5.342 36.649-7.531-19.704-18.503-27.628-18.503-27.628-11.253 2.292-19.496 10.611-25.064 18.759 1.787-17.743 9.795-40.62 35.397-59.006"
              style={{ fill: '#363434' }}
            />
          </g>
          <g transform="translate(557.75 269.6)">
            <path
              d="m0 0c-5.089-2.485-15.256-5.523-34.071-5.035-0.266-0.907-3.582-12.608-1.994-28.364 5.568-8.147 13.81-16.466 25.064-18.759 0 0 10.972 7.925 18.503 27.628-1.815 7.647-4.253 15.994-7.502 24.53"
              style={{ fill: '#ffd6b5' }}
            />
          </g>
          <g transform="translate(554.63 277.2)">
            <path
              d="m0 0c-1.09 1.319-15.653 20.029-11.061 73.475 0 0 6.359 49.962-6.358 116.58 0 0-14.308 69.391 3.974 95.066l-72.729-17.695s26.23-29.144 19.871-82.575c-2.453-20.615 0.697-42.09 4.416-62.409 4.553-24.87 10.572-49.622 19.663-73.553 6.854-18.044 15.818-34.772 13.065-53.696 8.153-0.731 19.759-0.464 29.159 4.81"
              style={{ fill: '#ffd6b5' }}
            />
          </g>
          <g transform="translate(557.75 269.6)">
            <path
              d="m0 0c-0.949 2.495-1.958 5.004-3.053 7.518 0 0-0.025 0.028-0.067 0.08-9.401-5.275-21.006-5.541-29.159-4.81-0.37-2.545-0.945-5.127-1.773-7.76 0 0-8e-3 -0.026-0.019-0.063 18.815-0.488 28.982 2.55 34.071 5.035"
              style={{ fill: '#363434' }}
            />
          </g>
          <g transform="translate(534.21 905.83)">
            <path
              d="m0 0s-3.917 16.337-16.837 7.603c1.984-1.357 4.131-1.815 4.131-1.815 4.491-0.314 9.104-3.031 12.706-5.788"
              style={{ fill: '#ecbb97' }}
            />
          </g>
          <g transform="translate(534.21 905.83)">
            <path
              d="m0 0c3.074-2.353 5.407-4.73 6.294-5.675-0.917 1.666-2.883 3.552-6.294 5.675"
              style={{ fill: '#ecbb97' }}
            />
          </g>
          <g transform="translate(582.91 858.55)">
            <path
              d="m0 0c-5.632 6.927-9.548 14.757-10.747 23.181-0.397 2.793-0.713 5.597-0.962 8.406l-30.3 9.59s-0.141 0.158-0.396 0.429c4.439-8.061-15.675-10.999-15.675-10.999-13.855 0-21.059-9.25-21.059-9.25h-27.453c-1.446-8.168-4.804-15.622-10.01-22.15-11.991-12.339-133.51-140.84-164.96-312.26h447.3c-32.385 176.54-160.31 307.56-165.74 313.06"
              style={{ fill: '#a6cac8' }}
            />
          </g>
          <g transform="translate(494.76 864.88)">
            <path
              d="m0 0c-17.98-21.701-34.835-44.324-50.78-67.558"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(463.54 824.85)">
            <path
              d="m0 0c-0.183-1.138 0.363-1.912 1.215-1.787 1.498 0.171 2.265 1.554 2.236 2.12 0.414-0.407 1.198-0.113 1.659 0.223 0.602 0.44 1.131 1.014 0.713 2.228-0.777 1.652-0.501 3.213 0.662 4.352-0.21 0.241-0.45 0.445-0.72 0.614-0.238 0.212-0.5 0.387-0.79 0.521-0.788-1.424-2.202-2.133-4-1.873-1.282 0.047-1.681-0.633-1.93-1.342-0.189-0.542-0.245-1.382 0.266-1.658-0.551-0.139-1.658-1.286-1.382-2.768 0.129-0.85 1.034-1.139 2.071-0.63"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(495.35 865.75)">
            <path
              d="m0 0c0.952 1.449 1.646 3.038 0.27 3.339-0.895 0.184-1.597-1.368-1.697-1.993 0.065 0.426-0.162 1.185-0.779 1.178-0.831 0.04-1.518-1.548-2.148-3.743-0.759-3.693-2.384-5.873-4.102-7.26 1.848 3.093 1.21 6.28-0.353 6.267-1.865 0.065-2.896-2.149-2.887-3.13-0.494 0.798-1.485 0.471-2.076-2e-3 -0.768-0.625-1.452-1.502-0.977-3.676 1.117-3.711 0.295-4.991-0.011-6.238-0.324-1.27-1.271-1.054-0.866-0.05 0.837 3.62-0.184 4.587-0.984 4.979-0.825 0.387-2.197-0.521-2.434-1.713-0.962 1.416-2.037 1.834-3.511 0.769-1.791-1.447-1.348-3.212 0.345-4.792-1.648 0.059-2.339-2.014-0.694-3.412 2.385-2.096 2.079-2.781 2.269-4.046 0.187-1.256-0.555-2.062-0.638-0.984-0.274 2.483-1.889 3.542-2.918 2.871-1.057-0.649-1.402-1.537-1.129-2.998-1.436 0.391-2.764-1.529-0.972-2.746 2.667-2.443 1.75-4.495 1.312-4.982"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(495.5 865.61)">
            <path
              d="m0 0c1.269 1.195 2.716 2.104 3.228 0.79 0.335-0.828-1.062-1.768-1.657-1.964 0.405 0.136 1.188 0.028 1.289-0.572 0.188-0.806-1.235-1.729-3.258-2.743-3.47-1.416-5.267-3.478-6.259-5.434 2.639 2.448 5.866 2.437 6.173 0.911 0.453-1.806-1.467-3.232-2.424-3.42 0.882-0.321 0.777-1.343 0.448-2.007-0.43-0.875-1.116-1.716-3.328-1.72-3.856 0.29-4.917-0.808-6.042-1.399-1.152-0.612-0.717-1.48 0.158-0.852 3.295 1.658 4.483 0.854 5.044 0.173 0.559-0.707 0.014-2.232-1.072-2.732 1.596-0.61 2.255-1.555 1.599-3.208-0.944-2.04-2.751-2.014-4.699-0.759 0.468-1.566-1.323-2.734-3.094-1.494-2.627 1.782-3.21 1.313-4.48 1.177-1.261-0.139-1.85-1.061-0.786-0.865 2.47 0.367 3.901-0.93 3.525-2.083-0.349-1.176-1.111-1.726-2.594-1.836 0.759-1.277-0.702-3.055-2.36-1.658-3.066 1.914-4.793 0.468-5.142-0.087"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(441.77 794.1)">
            <path
              d="m0 0c-15.816-23.321-30.712-47.282-44.234-72.009"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(414.36 751.38)">
            <path
              d="m0 0c-0.08-1.148 0.535-1.867 1.373-1.665 1.479 0.306 2.12 1.75 2.04 2.311 0.449-0.368 1.205-4e-3 1.634 0.371 0.561 0.492 1.037 1.11 0.512 2.279-0.92 1.571-0.787 3.15 0.253 4.399-0.231 0.222-0.489 0.403-0.773 0.547-0.256 0.189-0.534 0.339-0.835 0.446-0.64-1.499-1.983-2.334-3.804-2.236-1.282-0.07-1.62-0.783-1.805-1.511-0.14-0.557-0.12-1.397 0.413-1.625-0.537-0.188-1.539-1.43-1.131-2.878 0.206-0.833 1.135-1.038 2.123-0.438"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(442.27 795.03)">
            <path
              d="m0 0c0.813 1.535 1.33 3.177-0.073 3.331-0.899 0.098-1.446-1.508-1.482-2.136 0.024 0.428-0.281 1.157-0.893 1.091-0.838-0.036-1.342-1.687-1.751-3.931-0.388-3.75-1.819-6.065-3.405-7.599 1.564 3.243 0.629 6.349-0.925 6.191-1.867-0.106-2.69-2.396-2.591-3.369-0.566 0.747-1.523 0.333-2.069-0.19-0.704-0.693-1.3-1.63-0.624-3.752 1.463-3.597 0.761-4.948 0.563-6.213-0.212-1.291-1.176-1.161-0.86-0.127 0.519 3.672-0.599 4.549-1.429 4.865-0.852 0.307-2.138-0.719-2.271-1.924-1.085 1.322-2.193 1.641-3.57 0.452-1.659-1.597-1.063-3.31 0.773-4.735-1.639-0.095-2.142-2.221-0.375-3.465 2.565-1.873 2.323-2.583 2.628-3.827 0.301-1.235-0.364-2.106-0.544-1.039-0.501 2.449-2.205 3.359-3.167 2.596-0.992-0.743-1.254-1.659-0.849-3.091-1.466 0.26-2.632-1.762-0.735-2.813 2.869-2.187 2.136-4.309 1.756-4.841"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(442.44 794.91)">
            <path
              d="m0 0c1.149 1.307 2.5 2.373 3.147 1.123 0.416-0.8-0.889-1.876-1.465-2.134 0.392 0.176 1.185 0.152 1.343-0.438 0.258-0.779-1.056-1.858-2.972-3.069-3.311-1.754-4.92-3.962-5.734-6 2.413 2.675 5.633 2.97 6.079 1.479 0.616-1.754-1.174-3.354-2.112-3.63 0.909-0.238 0.896-1.265 0.627-1.958-0.346-0.914-0.95-1.817-3.149-2.028-3.859-0.072-4.814-1.262-5.889-1.948-1.096-0.711-0.585-1.535 0.233-0.833 3.145 1.942 4.389 1.259 5.014 0.629 0.624-0.656 0.217-2.224-0.824-2.818 1.645-0.462 2.387-1.343 1.877-3.046-0.764-2.115-2.571-2.249-4.619-1.18 0.616-1.523-1.063-2.846-2.938-1.772-2.777 1.536-3.313 1.017-4.565 0.765-1.241-0.253-1.743-1.226-0.702-0.934 2.423 0.591 3.966-0.572 3.698-1.755-0.24-1.203-0.948-1.821-2.413-2.065 0.87-1.203-0.44-3.095-2.217-1.854-3.233 1.633-4.829 0.04-5.114-0.553"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(395.68 718.66)">
            <path
              d="m0 0c-3.407-6.168-6.565-12.467-9.799-18.726-1.579-3.149-3.092-6.33-4.642-9.494l-2.311-4.751-2.198-4.805c-5.996-12.754-11.294-25.824-16.258-39.007"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(373.2 673.16)">
            <path
              d="m0 0c0.059-1.148 0.757-1.784 1.566-1.48 1.434 0.484 1.895 1.991 1.748 2.537 0.491-0.309 1.198 0.142 1.579 0.566 0.497 0.555 0.895 1.223 0.232 2.318-1.103 1.447-1.16 3.031-0.278 4.394-0.256 0.193-0.534 0.341-0.832 0.451-0.278 0.157-0.57 0.274-0.882 0.344-0.457-1.566-1.69-2.555-3.511-2.68-1.266-0.225-1.516-0.975-1.613-1.721-0.072-0.571 0.048-1.402 0.606-1.563-0.511-0.253-1.359-1.609-0.779-2.995 0.305-0.8 1.253-0.888 2.164-0.171"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(396.09 719.63)">
            <path
              d="m0 0c0.643 1.615 0.981 3.307-0.426 3.317-0.902 6e-3 -1.274-1.655-1.242-2.285-0.022 0.429-0.402 1.126-1.002 0.996-0.82-0.126-1.169-1.815-1.358-4.084-0.012-1.882-0.317-3.431-0.799-4.722-0.459-1.303-1.095-2.347-1.793-3.199 1.163 3.417-0.059 6.398-1.595 6.081-1.869-0.288-2.402-2.685-2.202-3.643-0.645 0.686-1.545 0.167-2.027-0.417-0.63-0.764-1.129-1.761-0.235-3.799 1.83-3.419 1.271-4.833 1.205-6.111-0.076-1.303-1.051-1.279-0.844-0.218 0.135 3.7-1.069 4.453-1.93 4.676-0.88 0.215-2.058-0.946-2.065-2.157-1.22 1.195-2.358 1.392-3.61 0.058-1.462-1.788-0.656-3.444 1.319-4.651-1.621-0.28-1.887-2.456 8e-3 -3.492 2.76-1.572 2.598-2.306 3.039-3.507 0.438-1.192-0.126-2.131-0.425-1.093-0.771 2.375-2.566 3.087-3.44 2.219-0.905-0.853-1.065-1.795-0.502-3.17-1.49 0.089-2.427-2.056-0.419-2.878 3.097-1.835 2.658-4.046 2.333-4.614"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(396.26 719.53)">
            <path
              d="m0 0c1 1.423 2.226 2.624 3 1.443 0.496-0.756-0.682-1.962-1.225-2.278 0.37 0.215 1.158 0.273 1.378-0.299 0.346-0.754-0.865-1.95-2.657-3.344-1.565-1.035-2.697-2.143-3.512-3.253-0.792-1.123-1.269-2.248-1.556-3.309 2.066 2.94 5.263 3.549 5.858 2.117 0.771-1.667-0.787-3.465-1.692-3.837 0.926-0.139 1.031-1.165 0.844-1.884-0.248-0.941-0.754-1.898-2.921-2.338-3.835-0.477-4.663-1.761-5.663-2.555-1.017-0.823-0.421-1.585 0.32-0.801 2.931 2.261 4.241 1.713 4.93 1.156 0.69-0.584 0.451-2.178-0.524-2.877 1.689-0.283 2.52-1.076 2.192-2.814-0.505-2.183-2.256-2.524-4.418-1.685 0.782-1.442-0.736-2.937-2.72-2.081-2.933 1.214-3.408 0.639-4.625 0.248-1.207-0.391-1.597-1.413-0.594-1.005 2.344 0.859 4.008-0.123 3.875-1.324-0.103-1.219-0.737-1.91-2.166-2.317 1.001-1.092-0.089-3.112-1.998-2.088-3.408 1.25-4.766-0.54-4.991-1.156"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(359.16 638.2)">
            <path
              d="m0 0c-4.858-13.228-8.885-26.754-12.266-40.428l-2.4-10.291-1.932-10.39c-0.719-3.452-1.116-6.954-1.596-10.444-0.435-3.494-1.045-6.971-1.235-10.492"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(344.96 589.51)">
            <path
              d="m0 0c0.285-1.11 1.097-1.587 1.831-1.128 1.31 0.763 1.461 2.322 1.209 2.826 0.543-0.204 1.146 0.377 1.435 0.865 0.377 0.64 0.633 1.368-0.233 2.31-1.368 1.198-1.737 2.743-1.143 4.251-0.289 0.14-0.59 0.233-0.904 0.283-0.302 0.102-0.612 0.16-0.931 0.169-0.138-1.628-1.151-2.84-2.91-3.323-1.197-0.472-1.295-1.262-1.243-2.015 0.041-0.577 0.323-1.37 0.903-1.415-0.452-0.351-1.016-1.857-0.172-3.096 0.457-0.722 1.407-0.612 2.158 0.273"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(359.42 639.22)">
            <path
              d="m0 0c0.389 1.695 0.466 3.423-0.924 3.224-0.89-0.128-1.006-1.832-0.88-2.452-0.086 0.422-0.567 1.056-1.139 0.837-0.792-0.248-0.882-1.979-0.724-4.255 0.563-3.732-0.301-6.306-1.355-8.232 0.282 1.79 0.038 3.359-0.469 4.42-0.514 1.063-1.301 1.619-2.038 1.34-0.894-0.292-1.401-1.047-1.642-1.843-0.228-0.801-0.168-1.651 9e-3 -2.112-0.746 0.578-1.548-0.085-1.928-0.745-0.496-0.863-0.825-1.939 0.39-3.813 2.367-3.086 2.052-4.572 2.198-5.845 0.142-1.298-0.825-1.44-0.796-0.358-0.481 3.676-1.791 4.226-2.677 4.302-0.904 0.064-1.876-1.287-1.685-2.49-1.401 0.976-2.557 0.978-3.575-0.564-1.205-2.012-0.083-3.534 2.063-4.379-1.541-0.567-1.417-2.78 0.633-3.465 2.994-1.061 2.967-1.814 3.618-2.92 0.645-1.096 0.261-2.122-0.221-1.154-1.188 2.202-3.079 2.587-3.783 1.567-0.737-1.009-0.726-1.972 0.076-3.226-1.482-0.186-2.019-2.483 0.107-2.915 1.697-0.615 2.522-1.483 2.894-2.271 0.389-0.793 0.344-1.513 0.241-1.824"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(359.61 639.15)">
            <path
              d="m0 0c0.771 1.558 1.797 2.928 2.741 1.873 0.604-0.674-0.374-2.04-0.862-2.434 0.332 0.268 1.101 0.441 1.406-0.09 0.455-0.694-0.555-2.054-2.111-3.7-2.765-2.523-3.832-5.049-4.005-7.249 0.763 1.62 1.945 2.695 3.027 3.2 1.074 0.507 2.037 0.449 2.441-0.209 0.519-0.764 0.444-1.639 0.134-2.388-0.297-0.752-0.806-1.384-1.218-1.639 0.933 8e-3 1.208-0.984 1.144-1.719-0.086-0.965-0.423-1.983-2.483-2.764-3.696-1.089-4.3-2.495-5.153-3.442-0.866-0.98-0.151-1.628 0.449-0.734 2.513 2.711 3.894 2.383 4.666 1.949 0.778-0.461 0.81-2.061-0.033-2.905 1.712 1e-3 2.665-0.64 2.636-2.389-0.183-2.199-1.797-2.834-4.079-2.38 1.031-1.279-0.184-2.999-2.288-2.508-3.1 0.677-3.462 0.028-4.586-0.572-1.116-0.6-1.314-1.676-0.402-1.094 2.148 1.264 3.96 0.59 4.049-0.608 0.121-1.212-0.375-1.998-1.707-2.654 1.184-0.889 0.483-3.056-1.582-2.406-1.787 0.296-2.948-0.053-3.679-0.558-0.714-0.509-0.978-1.178-1.027-1.502"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(338.97 546.52)">
            <path
              d="m0 0c0.449-1.051 1.324-1.39 1.98-0.821 1.183 0.958 1.099 2.513 0.774 2.971 0.567-0.116 1.077 0.548 1.289 1.072 0.278 0.687 0.422 1.442-0.577 2.237-1.531 0.974-2.128 2.446-1.766 4.024-0.306 0.096-0.618 0.143-0.936 0.146-0.314 0.057-0.629 0.068-0.946 0.031 0.107-1.633-0.713-2.982-2.381-3.731-1.113-0.651-1.092-1.451-0.928-2.19 0.127-0.565 0.524-1.307 1.104-1.261-0.394-0.417-0.727-2 0.293-3.091 0.561-0.641 1.484-0.379 2.094 0.613"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(555.3 864.88)">
            <path
              d="m0 0c17.98-21.701 34.835-44.324 50.78-67.558"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(586.51 824.85)">
            <path
              d="m0 0c1.037-0.509 1.942-0.219 2.071 0.63 0.275 1.481-0.831 2.629-1.383 2.767 0.511 0.277 0.456 1.117 0.267 1.659-0.249 0.709-0.648 1.389-1.93 1.342-1.799-0.26-3.212 0.449-4 1.873-0.291-0.134-0.553-0.309-0.79-0.521-0.27-0.169-0.51-0.373-0.72-0.614 1.162-1.139 1.438-2.7 0.661-4.352-0.417-1.214 0.112-1.788 0.714-2.228 0.461-0.336 1.245-0.63 1.658-0.223-0.028-0.566 0.738-1.949 2.237-2.119 0.851-0.126 1.398 0.648 1.215 1.786"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(554.55 865.61)">
            <path
              d="m0 0c-1.269 1.195-2.716 2.104-3.228 0.79-0.335-0.827 1.062-1.767 1.657-1.964-0.405 0.136-1.188 0.029-1.289-0.572-0.188-0.806 1.235-1.729 3.259-2.743 3.469-1.416 5.266-3.478 6.258-5.434-2.639 2.448-5.866 2.437-6.173 0.911-0.453-1.806 1.467-3.232 2.425-3.42-0.882-0.321-0.778-1.343-0.449-2.007 0.43-0.876 1.116-1.716 3.328-1.72 3.856 0.289 4.917-0.808 6.043-1.399 1.151-0.612 0.716-1.48-0.159-0.852-3.294 1.658-4.482 0.854-5.044 0.173-0.558-0.707-0.014-2.232 1.072-2.732-1.596-0.61-2.255-1.555-1.598-3.208 0.943-2.04 2.75-2.014 4.698-0.759-0.468-1.566 1.323-2.734 3.094-1.494 2.627 1.782 3.21 1.314 4.48 1.177 1.261-0.139 1.85-1.061 0.786-0.865-2.47 0.367-3.901-0.93-3.525-2.083 0.349-1.176 1.112-1.726 2.594-1.835-0.759-1.278 0.703-3.056 2.36-1.659 3.066 1.914 4.793 0.468 5.142-0.087"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(554.71 865.75)">
            <path
              d="m0 0c-0.953 1.449-1.646 3.038-0.27 3.339 0.895 0.184 1.597-1.367 1.697-1.992-0.065 0.425 0.162 1.184 0.779 1.177 0.831 0.04 1.518-1.548 2.148-3.743 0.758-3.693 2.384-5.873 4.102-7.26-1.848 3.093-1.21 6.28 0.353 6.267 1.865 0.065 2.896-2.149 2.886-3.13 0.495 0.798 1.485 0.471 2.077-2e-3 0.767-0.625 1.451-1.502 0.977-3.676-1.118-3.711-0.295-4.99 0.011-6.238 0.324-1.27 1.271-1.054 0.865-0.05-0.836 3.62 0.184 4.587 0.985 4.979 0.824 0.387 2.197-0.521 2.434-1.713 0.962 1.416 2.036 1.834 3.511 0.769 1.791-1.447 1.348-3.212-0.346-4.792 1.648 0.059 2.34-2.014 0.694-3.412-2.384-2.096-2.078-2.781-2.268-4.046-0.187-1.256 0.555-2.062 0.637-0.984 0.275 2.483 1.89 3.542 2.918 2.871 1.057-0.649 1.403-1.537 1.13-2.998 1.435 0.391 2.763-1.529 0.971-2.745-2.666-2.444-1.749-4.496-1.311-4.983"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(608.29 794.1)">
            <path
              d="m0 0c15.816-23.321 30.712-47.282 44.234-72.009"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(635.69 751.38)">
            <path
              d="m0 0c0.989-0.601 1.918-0.396 2.123 0.437 0.407 1.448-0.594 2.69-1.132 2.878 0.534 0.228 0.554 1.069 0.414 1.626-0.185 0.728-0.522 1.441-1.805 1.51-1.821-0.097-3.164 0.738-3.804 2.237-0.301-0.107-0.578-0.257-0.835-0.446-0.283-0.145-0.542-0.325-0.773-0.547 1.04-1.249 1.172-2.828 0.253-4.4-0.525-1.169-0.049-1.786 0.512-2.278 0.429-0.376 1.185-0.739 1.634-0.372-0.08-0.561 0.56-2.005 2.041-2.31 0.838-0.203 1.453 0.517 1.372 1.665"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(607.62 794.91)">
            <path
              d="m0 0c-1.149 1.307-2.5 2.373-3.147 1.123-0.415-0.8 0.89-1.876 1.465-2.134-0.392 0.176-1.184 0.152-1.343-0.438-0.257-0.779 1.056-1.858 2.972-3.069 3.311-1.754 4.92-3.962 5.734-6-2.413 2.675-5.633 2.97-6.079 1.479-0.616-1.754 1.174-3.354 2.112-3.63-0.909-0.238-0.896-1.265-0.627-1.958 0.346-0.914 0.95-1.817 3.149-2.027 3.859-0.073 4.814-1.263 5.889-1.949 1.096-0.711 0.585-1.535-0.232-0.833-3.145 1.942-4.39 1.259-5.015 0.629-0.624-0.656-0.217-2.224 0.825-2.818-1.646-0.462-2.388-1.343-1.877-3.046 0.763-2.115 2.57-2.249 4.617-1.18-0.615-1.523 1.064-2.846 2.939-1.772 2.777 1.536 3.313 1.017 4.564 0.765 1.243-0.253 1.744-1.226 0.704-0.934-2.423 0.591-3.967-0.572-3.699-1.755 0.24-1.203 0.948-1.821 2.413-2.065-0.87-1.203 0.44-3.095 2.217-1.854 3.233 1.633 4.829 0.04 5.114-0.553"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(607.79 795.03)">
            <path
              d="m0 0c-0.813 1.535-1.33 3.177 0.073 3.331 0.899 0.099 1.446-1.508 1.481-2.136-0.024 0.428 0.282 1.157 0.894 1.091 0.838-0.036 1.341-1.687 1.751-3.931 0.387-3.75 1.819-6.065 3.405-7.599-1.564 3.243-0.629 6.349 0.925 6.191 1.866-0.106 2.689-2.396 2.591-3.369 0.566 0.747 1.523 0.333 2.069-0.19 0.703-0.694 1.3-1.63 0.624-3.752-1.464-3.598-0.761-4.948-0.563-6.213 0.212-1.291 1.176-1.161 0.86-0.127-0.519 3.672 0.599 4.549 1.429 4.865 0.851 0.307 2.137-0.719 2.27-1.924 1.086 1.322 2.194 1.641 3.571 0.452 1.658-1.597 1.063-3.31-0.774-4.735 1.64-0.095 2.143-2.221 0.375-3.465-2.564-1.873-2.322-2.583-2.627-3.827-0.301-1.235 0.363-2.105 0.544-1.039 0.501 2.45 2.205 3.359 3.166 2.596 0.993-0.743 1.255-1.659 0.85-3.091 1.466 0.26 2.632-1.762 0.735-2.812-2.869-2.188-2.136-4.31-1.756-4.842"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(654.38 718.66)">
            <path
              d="m0 0c3.407-6.168 6.565-12.467 9.798-18.726 1.58-3.149 3.093-6.33 4.643-9.494l2.311-4.751 2.198-4.805c5.996-12.754 11.294-25.823 16.258-39.007"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(676.86 673.16)">
            <path
              d="m0 0c0.91-0.716 1.859-0.629 2.164 0.171 0.579 1.385-0.268 2.742-0.78 2.995 0.559 0.161 0.679 0.992 0.607 1.563-0.097 0.746-0.347 1.496-1.614 1.721-1.82 0.124-3.054 1.114-3.51 2.68-0.312-0.07-0.605-0.186-0.882-0.344-0.298-0.109-0.576-0.258-0.833-0.451 0.883-1.363 0.825-2.947-0.277-4.394-0.664-1.095-0.266-1.763 0.232-2.318 0.381-0.424 1.088-0.875 1.579-0.567-0.147-0.545 0.314-2.052 1.748-2.536 0.809-0.303 1.507 0.333 1.566 1.48"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(653.79 719.53)">
            <path
              d="m0 0c-1 1.423-2.226 2.624-3 1.443-0.496-0.755 0.682-1.962 1.225-2.278-0.37 0.215-1.158 0.273-1.378-0.298-0.345-0.755 0.865-1.951 2.657-3.345 1.566-1.035 2.697-2.143 3.512-3.253 0.792-1.123 1.27-2.248 1.557-3.309-2.066 2.94-5.264 3.549-5.858 2.117-0.772-1.667 0.786-3.465 1.691-3.837-0.926-0.139-1.031-1.165-0.844-1.884 0.248-0.941 0.754-1.898 2.921-2.338 3.835-0.477 4.663-1.761 5.663-2.555 1.018-0.823 0.421-1.585-0.32-0.801-2.93 2.261-4.241 1.713-4.93 1.156-0.69-0.584-0.45-2.178 0.524-2.877-1.688-0.283-2.52-1.076-2.192-2.814 0.505-2.183 2.257-2.524 4.418-1.685-0.782-1.442 0.736-2.937 2.72-2.081 2.933 1.214 3.408 0.639 4.624 0.248 1.208-0.391 1.598-1.413 0.595-1.005-2.344 0.859-4.008-0.123-3.875-1.324 0.103-1.219 0.737-1.91 2.167-2.317-1.001-1.092 0.088-3.112 1.997-2.088 3.409 1.25 4.766-0.54 4.991-1.156"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(653.97 719.63)">
            <path
              d="m0 0c-0.643 1.615-0.981 3.307 0.426 3.317 0.902 6e-3 1.273-1.655 1.242-2.285 0.022 0.429 0.402 1.126 1.001 0.996 0.821-0.126 1.17-1.815 1.359-4.084 0.011-1.882 0.317-3.431 0.799-4.722 0.459-1.303 1.095-2.347 1.793-3.199-1.163 3.417 0.059 6.398 1.595 6.081 1.869-0.288 2.401-2.685 2.202-3.643 0.645 0.686 1.544 0.167 2.027-0.417 0.629-0.764 1.128-1.761 0.235-3.799-1.83-3.419-1.271-4.833-1.205-6.111 0.076-1.303 1.05-1.279 0.844-0.218-0.135 3.7 1.069 4.453 1.93 4.677 0.88 0.214 2.057-0.947 2.065-2.158 1.219 1.195 2.358 1.392 3.61 0.058 1.461-1.788 0.656-3.444-1.319-4.652 1.62-0.279 1.887-2.455-9e-3 -3.491-2.759-1.572-2.597-2.306-3.039-3.507-0.437-1.192 0.127-2.131 0.426-1.093 0.771 2.375 2.566 3.087 3.44 2.219 0.905-0.853 1.065-1.795 0.502-3.17 1.489 0.089 2.426-2.056 0.419-2.878-3.097-1.835-2.659-4.046-2.333-4.614"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(690.9 638.2)">
            <path
              d="m0 0c4.858-13.227 8.884-26.754 12.266-40.428l2.4-10.291 1.932-10.39c0.719-3.452 1.116-6.954 1.596-10.444 0.435-3.494 1.045-6.971 1.235-10.492"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(705.09 589.51)">
            <path
              d="m0 0c0.751-0.885 1.7-0.995 2.158-0.273 0.843 1.239 0.28 2.745-0.173 3.096 0.58 0.045 0.863 0.839 0.904 1.415 0.052 0.753-0.046 1.543-1.243 2.015-1.76 0.483-2.772 1.695-2.91 3.324-0.319-0.01-0.629-0.068-0.931-0.17-0.314-0.05-0.616-0.143-0.904-0.283 0.594-1.508 0.224-3.053-1.143-4.251-0.867-0.942-0.611-1.67-0.233-2.31 0.289-0.488 0.892-1.069 1.434-0.865-0.252-0.504-0.101-2.063 1.21-2.826 0.733-0.459 1.546 0.018 1.831 1.128"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(690.45 639.15)">
            <path
              d="m0 0c-0.771 1.558-1.797 2.928-2.74 1.873-0.604-0.674 0.373-2.04 0.861-2.434-0.332 0.268-1.1 0.441-1.406-0.09-0.455-0.694 0.556-2.054 2.112-3.7 2.764-2.523 3.831-5.049 4.005-7.249-0.764 1.62-1.946 2.695-3.027 3.2-1.075 0.507-2.038 0.449-2.442-0.209-0.518-0.764-0.444-1.639-0.134-2.388 0.297-0.752 0.806-1.384 1.218-1.639-0.933 8e-3 -1.208-0.984-1.144-1.719 0.086-0.965 0.423-1.983 2.483-2.764 3.696-1.09 4.3-2.495 5.153-3.442 0.867-0.98 0.151-1.628-0.449-0.733-2.513 2.71-3.894 2.382-4.665 1.948-0.779-0.461-0.811-2.061 0.032-2.905-1.712 1e-3 -2.665-0.64-2.636-2.389 0.183-2.199 1.797-2.834 4.078-2.381-1.029-1.278 0.185-2.998 2.289-2.507 3.1 0.678 3.462 0.028 4.586-0.572 1.116-0.6 1.314-1.676 0.403-1.094-2.149 1.264-3.961 0.59-4.049-0.608-0.122-1.212 0.375-1.998 1.706-2.654-1.184-0.889-0.483-3.056 1.583-2.406 1.786 0.296 2.947-0.053 3.678-0.558 0.714-0.509 0.978-1.178 1.027-1.502"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(690.64 639.22)">
            <path
              d="m0 0c-0.389 1.695-0.466 3.423 0.924 3.224 0.89-0.128 1.007-1.832 0.88-2.452 0.087 0.422 0.567 1.056 1.14 0.837 0.791-0.248 0.881-1.979 0.723-4.255-0.563-3.732 0.302-6.306 1.355-8.232-0.281 1.79-0.038 3.359 0.469 4.42 0.514 1.063 1.301 1.619 2.038 1.34 0.895-0.292 1.402-1.047 1.642-1.843 0.229-0.801 0.168-1.651-9e-3 -2.112 0.746 0.578 1.548-0.085 1.929-0.745 0.494-0.863 0.825-1.939-0.391-3.813-2.367-3.086-2.052-4.572-2.198-5.845-0.141-1.298 0.825-1.44 0.797-0.358 0.48 3.676 1.791 4.226 2.677 4.302 0.904 0.064 1.876-1.287 1.684-2.49 1.401 0.976 2.557 0.978 3.575-0.564 1.205-2.012 0.083-3.534-2.064-4.38 1.542-0.566 1.418-2.779-0.632-3.464-2.994-1.061-2.967-1.814-3.618-2.92-0.645-1.096-0.26-2.122 0.222-1.153 1.187 2.201 3.079 2.586 3.782 1.566 0.738-1.009 0.726-1.972-0.075-3.225 1.481-0.187 2.018-2.484-0.108-2.916-1.697-0.615-2.521-1.483-2.894-2.271-0.389-0.793-0.344-1.513-0.24-1.824"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(711.09 546.52)">
            <path
              d="m0 0c0.611-0.992 1.533-1.254 2.094-0.613 1.02 1.091 0.687 2.674 0.293 3.091 0.58-0.046 0.978 0.696 1.104 1.261 0.165 0.739 0.186 1.539-0.928 2.19-1.668 0.749-2.488 2.098-2.381 3.731-0.317 0.037-0.632 0.026-0.946-0.031-0.318-3e-3 -0.63-0.05-0.936-0.146 0.362-1.578-0.234-3.05-1.766-4.024-0.998-0.795-0.854-1.55-0.577-2.237 0.213-0.524 0.722-1.188 1.289-1.072-0.325-0.458-0.408-2.013 0.774-2.971 0.657-0.569 1.532-0.23 1.98 0.821"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(522.87 859.1)">
            <path d="m0 0v-97.427" style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }} />
          </g>
          <g transform="translate(522.87 800.6)">
            <path
              d="m0 0c0.53-1.181 1.43-1.528 2.039-0.829 1.103 1.184 0.897 2.998 0.536 3.504 0.575-0.094 1.03 0.719 1.201 1.348 0.222 0.825 0.306 1.723-0.753 2.56-1.604 0.995-2.314 2.625-2.078 4.481-0.313 0.078-0.628 0.098-0.945 0.068-0.317 0.03-0.632 0.01-0.945-0.068 0.236-1.856-0.475-3.486-2.079-4.481-1.058-0.837-0.974-1.735-0.752-2.56 0.171-0.629 0.626-1.442 1.2-1.348-0.36-0.506-0.567-2.32 0.536-3.504 0.61-0.699 1.51-0.352 2.04 0.829"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(522.77 860.3)">
            <path
              d="m0 0c-0.203 1.99-0.71 3.885-1.952 3.118-0.796-0.491-0.333-2.369-6e-3 -2.989-0.224 0.422-0.889 0.916-1.355 0.457-0.662-0.576-0.166-2.466 0.748-4.866 1.769-3.821 1.873-6.961 1.412-9.441-0.511 4.108-3.019 6.469-4.221 5.325-1.49-1.292-0.896-3.992-0.272-4.859-0.886 0.354-1.449-0.647-1.611-1.491-0.211-1.106-0.198-2.371 1.528-3.977 3.191-2.525 3.342-4.273 3.867-5.615 0.529-1.378-0.349-1.855-0.651-0.661-1.572 3.863-2.987 3.997-3.854 3.78-0.882-0.239-1.398-2.025-0.851-3.262-1.631 0.591-2.734 0.204-3.237-1.786-0.516-2.553 0.921-3.826 3.229-4.065-1.334-1.103-0.623-3.455 1.536-3.57 3.169-0.23 3.344-1.068 4.266-2.088 0.915-1.014 0.819-2.271 0.096-1.344-1.733 2.072-3.658 1.899-4.064 0.573-0.441-1.328-0.173-2.374 0.935-3.516-1.378-0.642-1.283-3.311 0.882-3.181 3.6-0.383 4.106-2.909 4.05-3.662"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(522.97 860.3)">
            <path
              d="m0 0c0.203 1.99 0.71 3.885 1.953 3.118 0.796-0.491 0.333-2.369 5e-3 -2.989 0.224 0.422 0.889 0.916 1.355 0.457 0.662-0.576 0.166-2.466-0.747-4.866-1.77-3.821-1.874-6.961-1.413-9.441 0.511 4.108 3.019 6.469 4.222 5.325 1.489-1.292 0.896-3.992 0.271-4.859 0.887 0.354 1.449-0.647 1.612-1.491 0.209-1.106 0.198-2.371-1.529-3.977-3.192-2.525-3.341-4.273-3.867-5.615-0.529-1.378 0.349-1.855 0.651-0.661 1.572 3.863 2.987 3.997 3.855 3.78 0.881-0.239 1.397-2.025 0.85-3.262 1.631 0.591 2.734 0.204 3.237-1.786 0.516-2.553-0.921-3.826-3.229-4.065 1.334-1.103 0.623-3.455-1.536-3.57-3.169-0.23-3.344-1.068-4.267-2.088-0.914-1.014-0.818-2.271-0.095-1.344 1.733 2.072 3.659 1.899 4.064 0.573 0.441-1.328 0.173-2.374-0.934-3.516 1.377-0.642 1.282-3.311-0.882-3.181-3.601-0.383-4.107-2.909-4.051-3.662"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(522.87 757.17)">
            <path d="m0 0v-97.427" style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }} />
          </g>
          <g transform="translate(522.87 698.67)">
            <path
              d="m0 0c0.53-1.181 1.43-1.528 2.039-0.829 1.103 1.184 0.897 2.998 0.536 3.504 0.575-0.094 1.03 0.719 1.201 1.348 0.222 0.825 0.306 1.723-0.753 2.56-1.604 0.995-2.314 2.625-2.078 4.481-0.313 0.078-0.628 0.098-0.945 0.068-0.317 0.03-0.632 0.01-0.945-0.068 0.236-1.856-0.475-3.486-2.079-4.481-1.058-0.837-0.974-1.735-0.752-2.56 0.171-0.629 0.626-1.442 1.2-1.348-0.36-0.506-0.567-2.32 0.536-3.504 0.61-0.699 1.51-0.352 2.04 0.829"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(522.77 758.38)">
            <path
              d="m0 0c-0.203 1.99-0.71 3.885-1.952 3.118-0.796-0.491-0.333-2.369-6e-3 -2.989-0.224 0.422-0.889 0.916-1.355 0.457-0.662-0.576-0.166-2.466 0.748-4.866 1.769-3.821 1.873-6.961 1.412-9.441-0.511 4.109-3.019 6.469-4.221 5.325-1.49-1.292-0.896-3.992-0.272-4.859-0.886 0.354-1.449-0.647-1.611-1.491-0.211-1.106-0.198-2.371 1.528-3.977 3.191-2.525 3.342-4.273 3.867-5.615 0.529-1.378-0.349-1.855-0.651-0.661-1.572 3.863-2.987 3.997-3.854 3.78-0.882-0.239-1.398-2.024-0.851-3.262-1.631 0.591-2.734 0.204-3.237-1.786-0.516-2.553 0.921-3.826 3.229-4.065-1.334-1.103-0.623-3.455 1.536-3.57 3.169-0.23 3.344-1.068 4.266-2.088 0.915-1.014 0.819-2.271 0.096-1.344-1.733 2.072-3.658 1.899-4.064 0.573-0.441-1.328-0.173-2.374 0.935-3.516-1.378-0.642-1.283-3.311 0.882-3.181 3.6-0.383 4.106-2.909 4.05-3.662"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(522.97 758.38)">
            <path
              d="m0 0c0.203 1.99 0.71 3.885 1.953 3.118 0.796-0.491 0.333-2.369 5e-3 -2.989 0.224 0.422 0.889 0.916 1.355 0.457 0.662-0.576 0.166-2.466-0.747-4.866-1.77-3.821-1.874-6.961-1.413-9.441 0.511 4.109 3.019 6.469 4.222 5.325 1.489-1.292 0.896-3.992 0.271-4.859 0.887 0.354 1.449-0.647 1.612-1.491 0.209-1.106 0.198-2.371-1.529-3.977-3.192-2.525-3.341-4.273-3.867-5.615-0.529-1.378 0.349-1.855 0.651-0.661 1.572 3.863 2.987 3.997 3.855 3.78 0.881-0.239 1.397-2.024 0.85-3.262 1.631 0.591 2.734 0.204 3.237-1.786 0.516-2.553-0.921-3.826-3.229-4.065 1.334-1.103 0.623-3.455-1.536-3.57-3.169-0.23-3.344-1.068-4.267-2.088-0.914-1.014-0.818-2.271-0.095-1.344 1.733 2.072 3.659 1.899 4.064 0.573 0.441-1.328 0.173-2.374-0.934-3.516 1.377-0.642 1.282-3.311-0.882-3.181-3.601-0.383-4.107-2.909-4.051-3.662"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(522.87 655.25)">
            <path d="m0 0v-97.427" style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }} />
          </g>
          <g transform="translate(522.87 596.75)">
            <path
              d="m0 0c0.53-1.181 1.43-1.528 2.039-0.829 1.103 1.184 0.897 2.998 0.536 3.504 0.575-0.094 1.03 0.719 1.201 1.348 0.222 0.825 0.306 1.723-0.753 2.56-1.604 0.995-2.314 2.625-2.078 4.481-0.313 0.078-0.628 0.098-0.945 0.068-0.317 0.03-0.632 0.01-0.945-0.068 0.236-1.856-0.475-3.486-2.079-4.481-1.058-0.837-0.974-1.735-0.752-2.56 0.171-0.629 0.626-1.442 1.2-1.348-0.36-0.506-0.567-2.32 0.536-3.504 0.61-0.699 1.51-0.352 2.04 0.829"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(522.77 656.45)">
            <path
              d="m0 0c-0.203 1.99-0.71 3.885-1.952 3.118-0.796-0.491-0.333-2.369-6e-3 -2.989-0.224 0.422-0.889 0.916-1.355 0.457-0.662-0.576-0.166-2.466 0.748-4.866 1.769-3.821 1.873-6.961 1.412-9.441-0.511 4.109-3.019 6.469-4.221 5.325-1.49-1.292-0.896-3.992-0.272-4.859-0.886 0.354-1.449-0.647-1.611-1.491-0.211-1.106-0.198-2.371 1.528-3.977 3.191-2.525 3.342-4.273 3.867-5.615 0.529-1.378-0.349-1.855-0.651-0.66-1.572 3.862-2.987 3.996-3.854 3.779-0.882-0.239-1.398-2.024-0.851-3.262-1.631 0.591-2.734 0.204-3.237-1.786-0.516-2.553 0.921-3.826 3.229-4.065-1.334-1.103-0.623-3.455 1.536-3.57 3.169-0.229 3.344-1.068 4.266-2.088 0.915-1.014 0.819-2.271 0.096-1.344-1.733 2.072-3.658 1.899-4.064 0.573-0.441-1.328-0.173-2.374 0.935-3.516-1.378-0.642-1.283-3.311 0.882-3.181 3.6-0.383 4.106-2.909 4.05-3.662"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(522.97 656.45)">
            <path
              d="m0 0c0.203 1.99 0.71 3.885 1.953 3.118 0.796-0.491 0.333-2.369 5e-3 -2.989 0.224 0.422 0.889 0.916 1.355 0.457 0.662-0.576 0.166-2.466-0.747-4.866-1.77-3.821-1.874-6.961-1.413-9.441 0.511 4.109 3.019 6.469 4.222 5.325 1.489-1.292 0.896-3.992 0.271-4.859 0.887 0.354 1.449-0.647 1.612-1.491 0.209-1.106 0.198-2.371-1.529-3.977-3.192-2.525-3.341-4.273-3.867-5.615-0.529-1.378 0.349-1.855 0.651-0.66 1.572 3.862 2.987 3.996 3.855 3.779 0.881-0.239 1.397-2.024 0.85-3.262 1.631 0.591 2.734 0.204 3.237-1.786 0.516-2.553-0.921-3.826-3.229-4.065 1.334-1.103 0.623-3.455-1.536-3.57-3.169-0.229-3.344-1.068-4.267-2.088-0.914-1.014-0.818-2.271-0.095-1.344 1.733 2.072 3.659 1.899 4.064 0.573 0.441-1.328 0.173-2.374-0.934-3.516 1.377-0.642 1.282-3.311-0.882-3.181-3.601-0.383-4.107-2.909-4.051-3.662"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(522.87 546.68)">
            <path
              d="m0 0c0.531-1.181 1.43-1.528 2.04-0.829 1.103 1.184 0.896 2.998 0.536 3.504 0.574-0.093 1.029 0.719 1.2 1.349 0.222 0.824 0.307 1.723-0.752 2.559-1.604 0.995-2.315 2.626-2.079 4.481-0.313 0.078-0.628 0.099-0.945 0.068-0.317 0.031-0.632 0.01-0.945-0.068 0.236-1.855-0.474-3.486-2.078-4.481-1.059-0.836-0.975-1.735-0.753-2.559 0.171-0.63 0.626-1.442 1.2-1.349-0.36-0.506-0.567-2.32 0.536-3.504 0.61-0.699 1.51-0.352 2.04 0.829"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(507.75 859.26)">
            <path
              d="m0 0c-12.154-31.351-23.257-63.105-33.492-95.132"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(486.96 802.38)">
            <path
              d="m0 0c0.104-1.33 0.839-1.962 1.649-1.476 1.442 0.798 1.851 2.64 1.686 3.25 0.511-0.279 1.219 0.364 1.594 0.922 0.491 0.731 0.877 1.579 0.163 2.744-1.175 1.5-1.288 3.329-0.431 5.063-0.269 0.18-0.558 0.305-0.868 0.38-0.289 0.136-0.593 0.22-0.915 0.249-0.412-1.893-1.64-3.251-3.495-3.697-1.285-0.47-1.514-1.378-1.586-2.258-0.055-0.673 0.097-1.618 0.672-1.715-0.517-0.375-1.319-2.089-0.676-3.609 0.341-0.885 1.309-0.836 2.207 0.147"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(508.11 860.46)">
            <path
              d="m0 0c0.56 1.99 0.823 4.007-0.626 3.729-0.932-0.185-1.22-2.17-1.148-2.889-0.048 0.49-0.48 1.211-1.088 0.937-0.834-0.315-1.094-2.329-1.138-4.981 0.221-4.337-0.836-7.408-2.174-9.65 1.022 4.162-0.43 7.345-1.982 6.666-1.875-0.722-2.317-3.562-2.049-4.626-0.699 0.658-1.594-0.117-2.056-0.881-0.605-1.001-1.057-2.241-0.021-4.41 2.075-3.575 1.577-5.319 1.594-6.81 2e-3 -1.523-0.992-1.685-0.847-0.418-0.092 4.305-1.357 4.928-2.252 5.02-0.912 0.074-2.049-1.487-1.977-2.886-1.318 1.142-2.49 1.148-3.678-0.625-1.403-2.32-0.511-4.06 1.571-5.083-1.647-0.62-1.809-3.167 0.179-4.014 2.898-1.305 2.767-2.182 3.275-3.489 0.502-1.299-0.031-2.49-0.385-1.343-0.899 2.607-2.77 3.096-3.62 1.938-0.885-1.148-1.004-2.261-0.356-3.752-1.522-0.163-2.362-2.809-0.277-3.408 3.258-1.581 2.858-4.213 2.544-4.928"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(508.3 860.39)">
            <path
              d="m0 0c0.94 1.848 2.139 3.473 2.998 2.276 0.553-0.759-0.59-2.389-1.126-2.867 0.367 0.324 1.171 0.554 1.432-0.056 0.4-0.797-0.774-2.424-2.518-4.407-3.073-3.045-4.328-6.051-4.801-8.613 1.976 3.799 5.202 5.171 5.899 3.64 0.912-1.774-0.628-4.161-1.527-4.779 0.958 0.028 1.12-1.136 0.965-2.006-0.208-1.139-0.676-2.354-2.87-3.301-3.899-1.325-4.678-2.968-5.645-4.088-0.988-1.155-0.334-1.922 0.374-0.867 2.853 3.202 4.238 2.829 4.97 2.32 0.74-0.539 0.578-2.439-0.372-3.448 1.741 6e-3 2.637-0.751 2.403-2.846-0.421-2.642-2.223-3.376-4.475-2.816 0.863-1.526-0.627-3.56-2.698-2.937-3.06 0.857-3.52 0.102-4.747-0.577-1.217-0.676-1.569-1.932-0.562-1.275 2.359 1.428 4.109 0.603 4.025-0.821-0.052-1.438-0.673-2.361-2.108-3.099 1.074-1.09 0.061-3.651-1.933-2.8-3.524 0.837-4.879-1.46-5.087-2.213"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(472.85 759.69)">
            <path
              d="m0 0c-10.101-32.067-19.276-64.445-26.785-97.222"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(455.83 701.57)">
            <path
              d="m0 0c0.191-1.317 0.966-1.896 1.745-1.356 1.386 0.896 1.678 2.756 1.467 3.355 0.53-0.244 1.189 0.446 1.525 1.029 0.437 0.762 0.762 1.634-0.032 2.748-1.273 1.415-1.529 3.238-0.778 5.022-0.28 0.16-0.577 0.265-0.891 0.321-0.297 0.115-0.606 0.179-0.93 0.186-0.297-1.913-1.411-3.357-3.233-3.928-1.248-0.557-1.413-1.481-1.423-2.365-7e-3 -0.676 0.211-1.609 0.791-1.665-0.485-0.412-1.179-2.176-0.436-3.647 0.398-0.858 1.362-0.742 2.195 0.3"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(473.13 760.91)">
            <path
              d="m0 0c0.43 2.024 0.55 4.047-0.877 3.667-0.914-0.243-1.07-2.237-0.951-2.949-0.083 0.486-0.558 1.174-1.148 0.861-0.813-0.37-0.922-2.394-0.794-5.042 0.515-4.313-0.376-7.437-1.551-9.766 0.747 4.222-0.894 7.298-2.404 6.523-1.823-0.839-2.094-3.696-1.751-4.744-0.741 0.613-1.581-0.218-1.991-1.011-0.535-1.039-0.906-2.305 0.262-4.402 2.295-3.435 1.911-5.207 2.015-6.692 0.104-1.521-0.877-1.746-0.817-0.471-0.36 4.288-1.662 4.829-2.562 4.864-0.914 0.015-1.953-1.613-1.796-3.005-1.389 1.057-2.559 0.987-3.626-0.861-1.246-2.41-0.241-4.091 1.903-4.978-1.603-0.726-1.61-3.278 0.428-3.994 2.976-1.114 2.899-1.998 3.488-3.268 0.578-1.261 0.141-2.49-0.295-1.365-1.071 2.544-2.963 2.909-3.743 1.698-0.8-1.206-0.839-2.328-0.097-3.773-1.507-0.264-2.172-2.962-0.052-3.421 3.356-1.363 3.13-4.014 2.865-4.748"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(473.32 760.85)">
            <path
              d="m0 0c0.817 1.902 1.903 3.615 2.844 2.482 0.603-0.725-0.433-2.432-0.937-2.945 0.343 0.349 1.135 0.633 1.434 0.041 0.45-0.767-0.602-2.48-2.213-4.575-2.859-3.243-3.951-6.313-4.243-8.905 1.723 3.919 4.868 5.494 5.657 4.012 1.024-1.713-0.373-4.193-1.222-4.869 0.953 0.09 1.192-1.063 1.096-1.942-0.131-1.152-0.52-2.394-2.653-3.478-3.81-1.57-4.482-3.259-5.384-4.437-0.907-1.217-0.205-1.94 0.432-0.841 2.649 3.375 4.055 3.092 4.817 2.63 0.773-0.491 0.729-2.396-0.158-3.462 1.735 0.118 2.679-0.58 2.586-2.686-0.244-2.663-1.992-3.512-4.276-3.098 0.961-1.466-0.404-3.587-2.51-3.1-3.109 0.657-3.522-0.125-4.706-0.881-1.178-0.751-1.43-2.033-0.477-1.31 2.257 1.58 4.064 0.868 4.066-0.555 0.051-1.44-0.5-2.402-1.886-3.231 1.145-1.017 0.303-3.637-1.743-2.919-3.572 0.601-4.772-1.781-4.93-2.546"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(445.02 657.93)">
            <path
              d="m0 0c-3.691-16.401-6.902-32.914-9.466-49.53-2.545-16.614-4.44-33.346-5.117-50.148"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(434.12 598.37)">
            <path
              d="m0 0c0.358-1.282 1.201-1.754 1.904-1.121 1.26 1.064 1.311 2.939 1.026 3.506 0.556-0.176 1.122 0.59 1.379 1.206 0.337 0.808 0.546 1.709-0.383 2.717-1.462 1.252-1.877 3.024-1.343 4.882-0.296 0.127-0.604 0.197-0.922 0.215-0.309 0.08-0.623 0.107-0.945 0.076-0.069-1.936-1.059-3.492-2.772-4.286-1.166-0.708-1.212-1.651-1.11-2.533 0.079-0.675 0.413-1.577 0.996-1.559-0.429-0.471-0.894-2.316 0.031-3.682 0.504-0.801 1.446-0.56 2.139 0.579"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(445.19 659.17)">
            <path
              d="m0 0c0.269 2.051 0.22 4.078-1.172 3.581-0.891-0.319-0.882-2.321-0.708-3.019-0.119 0.475-0.652 1.122-1.214 0.76-0.792-0.434-0.718-2.468-0.357-5.099 0.888-4.258 0.294-7.449-0.694-9.871 0.395 4.265-1.529 7.194-2.959 6.284-1.742-1.002-1.76-3.881-1.34-4.891-0.794 0.544-1.557-0.364-1.887-1.197-0.432-1.089-0.681-2.392 0.683-4.377 2.611-3.21 2.403-5.01 2.645-6.479 0.238-1.502-0.722-1.823-0.773-0.549-0.756 4.234-2.115 4.654-3.01 4.6-0.914-0.073-1.787-1.804-1.504-3.177-1.48 0.916-2.641 0.733-3.545-1.22-0.995-2.543 0.184-4.126 2.406-4.79-1.518-0.893-1.259-3.449 0.844-3.948 3.076-0.797 3.092-1.685 3.812-2.887 0.712-1.194 0.385-2.459-0.156-1.389-1.321 2.415-3.249 2.582-3.896 1.289-0.675-1.288-0.629-2.406 0.284-3.77-1.465-0.431-1.815-3.204 0.342-3.42 3.489-0.981 3.572-3.639 3.393-4.399"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(445.39 659.13)">
            <path
              d="m0 0c0.665 1.962 1.604 3.758 2.635 2.708 0.661-0.671-0.23-2.457-0.694-3.009 0.316 0.376 1.081 0.725 1.428 0.161 0.5-0.723-0.388-2.517-1.807-4.74-2.564-3.476-3.365-6.636-3.447-9.236 1.392 4.052 4.367 5.897 5.29 4.492 1.174-1.611 5e-3 -4.195-0.796-4.941 0.941 0.174 1.283-0.947 1.275-1.829-0.018-1.155-0.283-2.421-2.3-3.697-3.639-1.916-4.142-3.664-4.929-4.921-0.799-1.296-0.033-1.945 0.504-0.793 2.327 3.608 3.74 3.459 4.548 3.074 0.815-0.414 0.963-2.307 0.176-3.446 1.721 0.283 2.724-0.318 2.82-2.406 0.034-2.656-1.605-3.668-3.92-3.489 1.11-1.355-0.02-3.591-2.166-3.328-3.16 0.33-3.488-0.491-4.585-1.366-1.088-0.872-1.225-2.169-0.342-1.35 2.089 1.807 3.953 1.288 4.109-0.121 0.196-1.419-0.281-2.425-1.549-3.398 1.253-0.885 0.724-3.567-1.391-3.086-3.614 0.2-4.531-2.307-4.6-3.085"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(430.21 546.72)">
            <path
              d="m0 0c0.512-1.223 1.405-1.572 2.026-0.851 1.122 1.22 0.945 3.073 0.593 3.597 0.572-0.101 1.041 0.727 1.222 1.367 0.235 0.84 0.333 1.751-0.712 2.632-1.567 1.055-2.318 2.766-1.96 4.672-0.308 0.091-0.622 0.125-0.94 0.105-0.316 0.044-0.631 0.035-0.948-0.033 0.113-1.931-0.557-3.6-2.198-4.605-1.071-0.851-1.002-1.8-0.793-2.666 0.16-0.663 0.602-1.519 1.178-1.426-0.368-0.522-0.605-2.425 0.479-3.657 0.598-0.727 1.504-0.357 2.053 0.865"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(538.54 859.26)">
            <path
              d="m0 0c12.154-31.351 23.257-63.105 33.493-95.132"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(559.34 802.38)">
            <path
              d="m0 0c0.898-0.983 1.866-1.032 2.207-0.147 0.642 1.52-0.159 3.234-0.676 3.609 0.575 0.097 0.727 1.042 0.672 1.715-0.072 0.88-0.301 1.788-1.586 2.258-1.856 0.445-3.083 1.804-3.495 3.697-0.322-0.029-0.626-0.113-0.914-0.249-0.311-0.075-0.6-0.2-0.869-0.38 0.856-1.734 0.743-3.563-0.431-5.063-0.714-1.165-0.328-2.013 0.163-2.744 0.376-0.558 1.082-1.201 1.594-0.922-0.165-0.61 0.243-2.452 1.685-3.25 0.811-0.486 1.546 0.146 1.65 1.476"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(537.99 860.39)">
            <path
              d="m0 0c-0.94 1.848-2.139 3.473-2.998 2.276-0.553-0.759 0.59-2.389 1.126-2.867-0.367 0.324-1.171 0.555-1.432-0.056-0.401-0.797 0.774-2.424 2.518-4.407 3.073-3.045 4.328-6.051 4.801-8.613-1.976 3.799-5.202 5.171-5.899 3.64-0.912-1.774 0.628-4.161 1.527-4.779-0.959 0.028-1.121-1.136-0.965-2.006 0.207-1.139 0.676-2.354 2.87-3.301 3.898-1.325 4.678-2.967 5.645-4.088 0.988-1.155 0.334-1.922-0.374-0.867-2.853 3.202-4.238 2.829-4.97 2.32-0.741-0.539-0.578-2.439 0.372-3.448-1.741 6e-3 -2.637-0.751-2.403-2.845 0.421-2.643 2.222-3.377 4.474-2.817-0.862-1.526 0.628-3.56 2.699-2.937 3.059 0.857 3.519 0.102 4.746-0.577 1.218-0.676 1.57-1.932 0.563-1.275-2.359 1.428-4.109 0.603-4.025-0.821 0.052-1.438 0.672-2.361 2.108-3.099-1.074-1.09-0.061-3.651 1.933-2.799 3.524 0.836 4.878-1.461 5.087-2.214"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(538.18 860.46)">
            <path
              d="m0 0c-0.56 1.99-0.823 4.007 0.626 3.729 0.932-0.185 1.22-2.17 1.148-2.889 0.048 0.49 0.48 1.211 1.088 0.937 0.833-0.315 1.094-2.329 1.138-4.981-0.222-4.337 0.836-7.408 2.174-9.65-1.022 4.162 0.43 7.345 1.982 6.666 1.875-0.722 2.317-3.562 2.049-4.626 0.699 0.658 1.593-0.117 2.056-0.881 0.605-1.002 1.057-2.241 0.021-4.41-2.075-3.576-1.577-5.319-1.594-6.81-3e-3 -1.523 0.992-1.685 0.847-0.418 0.092 4.305 1.357 4.928 2.252 5.02 0.912 0.074 2.049-1.487 1.977-2.886 1.318 1.142 2.49 1.148 3.678-0.625 1.403-2.32 0.511-4.06-1.572-5.083 1.648-0.62 1.809-3.167-0.178-4.014-2.898-1.305-2.767-2.182-3.275-3.49-0.503-1.298 0.031-2.489 0.385-1.342 0.898 2.607 2.77 3.096 3.62 1.938 0.885-1.148 1.004-2.261 0.356-3.752 1.521-0.163 2.362-2.809 0.277-3.408-3.258-1.581-2.858-4.213-2.544-4.928"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(573.44 759.69)">
            <path
              d="m0 0c10.101-32.067 19.276-64.445 26.786-97.222"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(590.47 701.57)">
            <path
              d="m0 0c0.833-1.042 1.797-1.158 2.195-0.3 0.743 1.471 0.049 3.235-0.437 3.646 0.58 0.057 0.799 0.99 0.792 1.666-0.011 0.884-0.175 1.808-1.423 2.365-1.823 0.571-2.936 2.015-3.233 3.928-0.324-7e-3 -0.633-0.071-0.93-0.186-0.314-0.056-0.611-0.161-0.891-0.321 0.751-1.784 0.494-3.607-0.778-5.022-0.794-1.114-0.469-1.986-0.032-2.748 0.336-0.583 0.995-1.273 1.524-1.029-0.21-0.599 0.081-2.46 1.468-3.355 0.779-0.54 1.554 0.039 1.745 1.356"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(572.98 760.85)">
            <path
              d="m0 0c-0.817 1.902-1.903 3.615-2.844 2.482-0.603-0.725 0.433-2.432 0.937-2.945-0.343 0.349-1.135 0.633-1.434 0.041-0.451-0.767 0.602-2.48 2.213-4.574 2.859-3.244 3.951-6.314 4.243-8.906-1.723 3.919-4.868 5.494-5.657 4.012-1.024-1.713 0.372-4.193 1.222-4.869-0.954 0.09-1.193-1.063-1.096-1.942 0.13-1.152 0.52-2.394 2.653-3.478 3.809-1.57 4.482-3.259 5.383-4.437 0.908-1.217 0.205-1.94-0.431-0.841-2.648 3.375-4.055 3.092-4.817 2.63-0.774-0.49-0.73-2.396 0.158-3.462-1.735 0.118-2.679-0.581-2.586-2.685 0.244-2.664 1.992-3.513 4.275-3.099-0.96-1.466 0.404-3.587 2.511-3.1 3.109 0.657 3.521-0.125 4.705-0.881 1.179-0.751 1.431-2.033 0.478-1.31-2.258 1.581-4.064 0.868-4.067-0.555-0.05-1.44 0.501-2.402 1.887-3.231-1.145-1.017-0.303-3.637 1.743-2.919 3.571 0.601 4.772-1.781 4.93-2.546"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(573.17 760.91)">
            <path
              d="m0 0c-0.43 2.024-0.55 4.047 0.877 3.668 0.914-0.244 1.07-2.238 0.95-2.95 0.084 0.486 0.559 1.174 1.149 0.861 0.812-0.37 0.922-2.394 0.794-5.042-0.515-4.313 0.376-7.437 1.551-9.766-0.747 4.222 0.894 7.298 2.403 6.523 1.824-0.839 2.095-3.696 1.752-4.744 0.741 0.613 1.581-0.218 1.991-1.011 0.534-1.039 0.906-2.305-0.263-4.402-2.295-3.435-1.91-5.207-2.014-6.692-0.104-1.521 0.877-1.746 0.817-0.471 0.36 4.288 1.662 4.829 2.562 4.864 0.914 0.015 1.953-1.613 1.795-3.005 1.39 1.057 2.56 0.987 3.627-0.861 1.246-2.41 0.241-4.091-1.904-4.978 1.604-0.726 1.611-3.278-0.428-3.994-2.975-1.114-2.899-1.998-3.487-3.268-0.578-1.261-0.141-2.49 0.295-1.365 1.071 2.544 2.963 2.909 3.743 1.698 0.799-1.206 0.839-2.328 0.097-3.773 1.507-0.264 2.172-2.962 0.052-3.421-3.356-1.363-3.131-4.014-2.865-4.748"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(601.27 657.93)">
            <path
              d="m0 0c3.691-16.401 6.902-32.914 9.466-49.53 2.545-16.614 4.44-33.346 5.117-50.148"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(612.18 598.38)">
            <path
              d="m0 0c0.693-1.14 1.635-1.38 2.139-0.58 0.924 1.366 0.46 3.212 0.031 3.682 0.582-0.017 0.917 0.885 0.996 1.56 0.102 0.882 0.056 1.824-1.11 2.532-1.714 0.794-2.703 2.35-2.772 4.287-0.322 0.031-0.636 3e-3 -0.945-0.077-0.318-0.017-0.626-0.087-0.922-0.214 0.533-1.859 0.118-3.631-1.343-4.882-0.929-1.008-0.72-1.909-0.383-2.717 0.257-0.617 0.823-1.382 1.378-1.207-0.285-0.567-0.234-2.442 1.027-3.505 0.703-0.634 1.546-0.162 1.904 1.121"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(600.9 659.13)">
            <path
              d="m0 0c-0.665 1.962-1.604 3.758-2.635 2.708-0.661-0.671 0.23-2.456 0.694-3.009-0.317 0.376-1.081 0.725-1.428 0.161-0.5-0.723 0.388-2.517 1.807-4.74 2.564-3.476 3.364-6.636 3.447-9.236-1.392 4.052-4.367 5.897-5.29 4.492-1.175-1.611-5e-3 -4.195 0.796-4.941-0.941 0.174-1.283-0.947-1.275-1.829 0.017-1.155 0.283-2.421 2.3-3.697 3.638-1.916 4.142-3.664 4.929-4.921 0.799-1.296 0.033-1.945-0.504-0.793-2.327 3.608-3.74 3.459-4.548 3.074-0.816-0.414-0.963-2.307-0.176-3.446-1.721 0.283-2.725-0.318-2.82-2.406-0.034-2.656 1.605-3.668 3.919-3.489-1.11-1.355 0.02-3.591 2.166-3.328 3.161 0.33 3.489-0.491 4.585-1.366 1.089-0.872 1.226-2.169 0.343-1.35-2.09 1.807-3.953 1.288-4.109-0.121-0.196-1.419 0.281-2.425 1.549-3.398-1.253-0.885-0.724-3.567 1.391-3.086 3.614 0.2 4.531-2.307 4.6-3.085"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(601.1 659.17)">
            <path
              d="m0 0c-0.269 2.051-0.22 4.077 1.172 3.58 0.891-0.319 0.882-2.321 0.708-3.019 0.119 0.476 0.652 1.122 1.214 0.76 0.792-0.434 0.718-2.468 0.357-5.099-0.889-4.257-0.294-7.449 0.694-9.87-0.395 4.265 1.528 7.193 2.959 6.284 1.742-1.003 1.76-3.882 1.34-4.892 0.793 0.544 1.557-0.363 1.887-1.196 0.432-1.09 0.68-2.393-0.683-4.377-2.612-3.211-2.403-5.011-2.645-6.48-0.238-1.502 0.721-1.822 0.773-0.548 0.756 4.234 2.115 4.654 3.01 4.6 0.914-0.074 1.787-1.805 1.504-3.177 1.48 0.916 2.641 0.732 3.545-1.22 0.995-2.544-0.184-4.127-2.407-4.791 1.519-0.892 1.26-3.448-0.844-3.947-3.075-0.797-3.092-1.685-3.811-2.888-0.712-1.194-0.385-2.458 0.156-1.388 1.321 2.415 3.249 2.582 3.896 1.288 0.675-1.288 0.629-2.406-0.284-3.77 1.465-0.43 1.815-3.204-0.342-3.419-3.489-0.981-3.573-3.639-3.393-4.399"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(616.08 546.72)">
            <path
              d="m0 0c0.55-1.222 1.455-1.592 2.053-0.865 1.084 1.232 0.848 3.135 0.48 3.657 0.575-0.093 1.018 0.763 1.178 1.426 0.209 0.866 0.278 1.815-0.794 2.666-1.641 1.005-2.31 2.674-2.197 4.605-0.317 0.068-0.633 0.077-0.948 0.033-0.319 0.02-0.632-0.014-0.941-0.105 0.358-1.906-0.393-3.617-1.96-4.672-1.044-0.881-0.946-1.792-0.711-2.632 0.181-0.64 0.649-1.468 1.221-1.367-0.352-0.524-0.529-2.377 0.593-3.597 0.621-0.721 1.515-0.372 2.026 0.851"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(423.78 1123.7)">
            <path
              d="m0 0 7.91-27.238c2.644-9.073 5.174-18.2 6.821-27.515 0.825-4.654 1.431-9.351 1.725-14.068 0.288-4.726-0.19-9.453-0.743-14.142-0.552-4.687-1.119-9.412-0.772-14.136 0.126-2.364 0.592-4.693 1.156-6.984 0.626-2.281 1.499-4.483 2.431-6.653"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(440.02 1057.6)">
            <path
              d="m0 0c0.654-1.319 1.588-1.666 2.12-0.791 0.981 1.491 0.56 3.637 0.117 4.186 0.584-0.046 0.924 0.97 1.005 1.742 0.089 1.005 0.017 2.091-1.167 2.922-1.775 0.933-2.757 2.692-2.933 4.869-0.325 0.031-0.638-3e-3 -0.944-0.096-0.318-0.023-0.623-0.105-0.914-0.25 0.636-2.069 0.217-4.066-1.189-5.421-0.916-1.099-0.689-2.084-0.343-2.982 0.251-0.684 0.81-1.548 1.369-1.377-0.276-0.618-0.28-2.676 0.935-3.933 0.68-0.747 1.542-0.288 1.944 1.131"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(423.29 1125)">
            <path
              d="m0 0c-0.84 2.169-1.942 4.147-2.887 2.943-0.605-0.771 0.449-2.741 0.964-3.344-0.351 0.41-1.151 0.777-1.449 0.134-0.449-0.829 0.641-2.805 2.295-5.234 2.939-3.781 4.057-7.264 4.419-10.166-1.822 4.453-4.997 6.395-5.781 4.78-1.012-1.86 0.435-4.714 1.316-5.51-0.967 0.149-1.183-1.127-1.065-2.116 0.157-1.295 0.579-2.707 2.758-4.022 3.889-1.944 4.603-3.849 5.545-5.203 0.955-1.391 0.266-2.169-0.41-0.919-2.767 3.873-4.171 3.628-4.933 3.143-0.768-0.515-0.679-2.657 0.248-3.882-1.758 0.201-2.691-0.539-2.532-2.887 0.321-2.967 2.097-3.967 4.39-3.604-0.933-1.582 0.457-3.967 2.575-3.533 3.13 0.577 3.551-0.313 4.742-1.22 1.191-0.902 1.452-2.344 0.488-1.482-2.286 1.888-4.095 1.183-4.1-0.38-0.05-1.578 0.501-2.657 1.884-3.661-1.161-1.039-0.389-3.944 1.681-3.336 3.619 0.37 4.726-2.423 4.836-3.298"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(423.48 1125)">
            <path
              d="m0 0c-0.451 2.282-0.578 4.542 0.864 4.031 0.924-0.328 1.088-2.556 0.975-3.341 0.078 0.534 0.557 1.271 1.153 0.888 0.822-0.46 0.959-2.712 0.86-5.65-0.46-4.766 0.459-8.307 1.706-10.952-0.842 4.737 0.801 8.076 2.327 7.13 1.849-1.029 2.155-4.214 1.836-5.358 0.737 0.643 1.602-0.32 2.032-1.218 0.56-1.178 0.959-2.598-0.178-4.873-2.248-3.704-1.817-5.705-1.885-7.352-0.059-1.691 0.94-1.981 0.84-0.558 0.25 4.763 1.564 5.305 2.468 5.307 0.923-0.021 2.002-1.869 1.881-3.412 1.373 1.121 2.557 0.996 3.692-1.105 1.333-2.746 0.363-4.6-1.784-5.498 1.643-0.887 1.698-3.786-0.35-4.479-2.984-1.092-2.897-2.088-3.487-3.479-0.577-1.378-0.128-2.776 0.308-1.546 1.061 2.781 2.967 3.096 3.763 1.672 0.82-1.416 0.87-2.699 0.112-4.279 1.524-0.414 2.156-3.564-3e-3 -3.882-3.401-1.233-3.292-4.196-3.073-5.045"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(444.69 1008.3)">
            <path
              d="m0 0c9.43-16.531 24.746-28.587 34.847-44.639 4.9-8.078 9.358-16.46 12.767-25.283 1.732-4.398 3.154-8.921 4.207-13.528 1.051-4.604 1.799-9.298 1.915-14.027"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(485.19 953.73)">
            <path
              d="m0 0c1.115-0.963 2.108-0.914 2.26 0.097 0.314 1.742-0.868 3.56-1.49 3.891 0.557 0.182 0.482 1.241 0.263 1.977-0.29 0.958-0.734 1.943-2.159 2.254-2.002 0.197-3.516 1.485-4.535 3.436-0.313-0.092-0.59-0.242-0.838-0.444-0.286-0.142-0.538-0.333-0.753-0.576 1.383-1.644 1.695-3.691 0.905-5.469-0.415-1.356 0.146-2.208 0.805-2.917 0.502-0.541 1.357-1.131 1.807-0.759-0.011-0.676 0.76-2.605 2.383-3.29 0.919-0.427 1.532 0.337 1.352 1.8"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(443.91 1009.4)">
            <path
              d="m0 0c-1.229 1.96-2.637 3.791-3.383 2.454-0.461-0.863 0.943-2.748 1.58-3.244-0.443 0.335-1.282 0.59-1.454-0.137-0.155-0.485 0.21-1.15 0.852-1.982 0.66-0.817 1.573-1.816 2.769-2.761 3.923-2.939 6.254-5.669 7.681-8.281-3.376 3.434-7.011 4.4-7.206 2.557-0.139-1.065 0.383-2.241 1.128-3.12 0.74-0.889 1.647-1.563 2.216-1.786-0.959-0.198-0.662-1.543-0.132-2.423 0.689-1.155 1.674-2.384 4.236-2.711 4.375-0.221 5.819-1.629 7.255-2.467 1.458-0.862 1.169-1.854 0.021-1.011-4.163 2.355-5.342 1.568-5.828 0.804-0.481-0.799 0.522-2.718 1.897-3.458-1.69-0.543-2.202-1.643-1.042-3.712 1.597-2.58 3.627-2.715 5.54-1.397-0.153-1.837 2.135-3.384 3.847-2.077 2.571 1.87 3.335 1.25 4.808 0.95 1.455-0.307 2.304-1.5 1.066-1.133-2.854 0.733-4.191-0.695-3.536-2.1 0.608-1.44 1.579-2.162 3.239-2.478-0.636-1.431 1.249-3.688 2.894-2.288 3.114 1.82 5.285-0.296 5.725-1.061"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(444.08 1009.5)">
            <path
              d="m0 0c-0.877 2.166-1.328 4.342 0.177 4.06 0.97-0.161 1.434-2.231 1.477-3.009-0.037 0.524 0.339 1.324 0.965 1.082 0.411-0.138 0.812-0.692 1.128-1.578 0.337-0.876 0.566-2.105 0.947-3.47 0.79-4.616 3.044-7.61 5.137-9.57-2.57 4.067-1.951 7.708-0.251 7.349 1.033-0.143 1.806-0.901 2.425-1.749 0.609-0.85 0.973-1.818 1.024-2.394 0.466 0.86 1.555 0.328 2.293-0.292 0.961-0.822 1.798-1.913 1.679-4.416-0.569-4.262 0.682-5.905 1.31-7.415 0.656-1.553 1.689-1.387 0.993-0.146-1.775 4.402-0.786 5.435 0.029 5.819 0.838 0.369 2.584-0.83 3.107-2.259 0.786 1.581 1.88 1.993 3.792 0.593 2.349-1.871 2.273-3.965 0.726-5.7 1.859-0.084 3.164-2.671 1.616-4.193-2.222-2.275-1.714-3.136-1.639-4.639 0.066-1.499 1.067-2.575 0.936-1.275-0.223 2.987 1.347 4.086 2.685 3.13 1.35-0.952 1.962-2.083 1.949-3.854 1.554 0.247 3.493-2.396 1.651-3.565-2.607-2.552-1.308-5.191-0.78-5.885"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(497.5 897.86)">
            <path
              d="m0 0c0.293-1.449 1.1-2.066 1.838-1.355 1.321 1.196 1.497 3.457 1.231 4.101 0.558-0.19 1.102 0.749 1.343 1.512 0.307 0.992 0.513 2.12-0.483 3.197-0.789 0.648-1.354 1.453-1.687 2.366-0.344 0.912-0.4 1.935-0.369 3.033-0.318 0.08-0.634 0.093-0.949 0.046-0.319 0.025-0.632-0.011-0.941-0.11 0.205-1.06 0.024-2.068-0.33-2.934-0.363-0.867-0.954-1.59-1.764-2.101-1.113-0.867-1.138-1.82-0.991-2.74 0.109-0.698 0.482-1.644 1.061-1.617-0.446-0.519-0.985-2.421-0.119-3.946 0.468-0.895 1.409-0.715 2.16 0.548"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(616.48 1123.7)">
            <path
              d="m0 0-7.91-27.237c-2.644-9.074-5.174-18.201-6.821-27.516-0.825-4.654-1.431-9.35-1.725-14.068-0.289-4.725 0.19-9.452 0.743-14.141 0.551-4.687 1.119-9.412 0.772-14.137-0.126-2.363-0.592-4.692-1.156-6.984-0.626-2.281-1.499-4.483-2.431-6.652"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(600.23 1057.6)">
            <path
              d="m0 0c0.402-1.419 1.263-1.878 1.944-1.131 1.214 1.257 1.21 3.315 0.935 3.933 0.559-0.171 1.118 0.693 1.369 1.377 0.345 0.898 0.572 1.883-0.343 2.982-1.407 1.355-1.826 3.352-1.189 5.421-0.291 0.145-0.596 0.227-0.914 0.25-0.306 0.093-0.62 0.127-0.944 0.096-0.177-2.177-1.159-3.936-2.934-4.869-1.184-0.831-1.256-1.917-1.167-2.922 0.082-0.772 0.421-1.788 1.005-1.742-0.442-0.549-0.864-2.695 0.117-4.186 0.532-0.875 1.467-0.528 2.121 0.791"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(616.77 1125)">
            <path
              d="m0 0c0.451 2.282 0.578 4.542-0.864 4.031-0.923-0.328-1.088-2.557-0.975-3.341-0.078 0.534-0.557 1.271-1.153 0.888-0.822-0.46-0.959-2.712-0.86-5.65 0.46-4.766-0.459-8.307-1.706-10.952 0.843 4.737-0.801 8.075-2.327 7.13-1.849-1.029-2.155-4.214-1.836-5.358-0.737 0.643-1.602-0.32-2.032-1.218-0.56-1.178-0.959-2.598 0.179-4.873 2.246-3.704 1.817-5.705 1.884-7.352 0.059-1.691-0.94-1.981-0.84-0.558-0.25 4.763-1.564 5.305-2.468 5.307-0.923-0.021-2.002-1.869-1.881-3.412-1.373 1.121-2.558 0.996-3.692-1.105-1.333-2.746-0.363-4.6 1.783-5.498-1.642-0.887-1.697-3.786 0.351-4.479 2.984-1.092 2.898-2.088 3.487-3.479 0.577-1.378 0.128-2.776-0.307-1.546-1.062 2.781-2.968 3.096-3.764 1.672-0.82-1.416-0.87-2.699-0.112-4.279-1.524-0.414-2.156-3.564 3e-3 -3.882 3.401-1.233 3.292-4.196 3.073-5.045"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(616.96 1125)">
            <path
              d="m0 0c0.84 2.169 1.942 4.147 2.887 2.943 0.605-0.771-0.449-2.742-0.964-3.344 0.351 0.41 1.15 0.777 1.449 0.133 0.449-0.828-0.641-2.804-2.296-5.233-2.939-3.781-4.057-7.264-4.418-10.166 1.822 4.453 4.997 6.395 5.78 4.78 1.012-1.86-0.434-4.714-1.315-5.51 0.966 0.149 1.182-1.127 1.065-2.116-0.158-1.295-0.579-2.707-2.759-4.022-3.889-1.944-4.602-3.849-5.544-5.203-0.955-1.391-0.267-2.169 0.41-0.919 2.767 3.873 4.171 3.628 4.933 3.142 0.768-0.514 0.679-2.656-0.248-3.881 1.757 0.201 2.69-0.539 2.531-2.888-0.321-2.966-2.096-3.966-4.39-3.603 0.934-1.582-0.456-3.967-2.575-3.533-3.129 0.577-3.55-0.313-4.742-1.219-1.19-0.903-1.451-2.346-0.488-1.483 2.287 1.888 4.095 1.183 4.101-0.38 0.049-1.578-0.501-2.657-1.884-3.661 1.161-1.039 0.389-3.944-1.681-3.336-3.62 0.37-4.727-2.423-4.836-3.298"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(595.56 1008.3)">
            <path
              d="m0 0c-9.43-16.531-24.746-28.587-34.847-44.639-4.9-8.078-9.358-16.46-12.767-25.283-1.732-4.398-3.154-8.921-4.208-13.529-1.05-4.603-1.798-9.297-1.914-14.026"
              style={{ fill: 'none', strokeWidth: '1.828', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(555.07 953.73)">
            <path
              d="m0 0c-0.18-1.463 0.432-2.227 1.351-1.799 1.623 0.685 2.394 2.614 2.383 3.29 0.45-0.372 1.306 0.217 1.808 0.759 0.659 0.709 1.219 1.561 0.804 2.917-0.79 1.778-0.478 3.825 0.905 5.468-0.215 0.244-0.466 0.435-0.752 0.576-0.248 0.202-0.525 0.352-0.838 0.445-1.02-1.951-2.534-3.239-4.536-3.437-1.424-0.31-1.868-1.295-2.158-2.254-0.219-0.736-0.295-1.795 0.262-1.976-0.622-0.332-1.804-2.15-1.489-3.892 0.152-1.01 1.145-1.06 2.26-0.097"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(596.17 1009.5)">
            <path
              d="m0 0c0.876 2.166 1.327 4.342-0.177 4.06-0.97-0.161-1.434-2.231-1.477-3.009 0.037 0.524-0.34 1.324-0.966 1.082-0.411-0.138-0.812-0.692-1.127-1.578-0.338-0.877-0.567-2.105-0.947-3.47-0.791-4.616-3.044-7.611-5.137-9.57 2.569 4.067 1.951 7.708 0.25 7.349-1.033-0.143-1.805-0.901-2.424-1.749-0.609-0.85-0.974-1.818-1.024-2.394-0.467 0.86-1.555 0.328-2.293-0.292-0.962-0.822-1.799-1.913-1.679-4.417 0.568-4.261-0.682-5.904-1.31-7.414-0.657-1.553-1.689-1.387-0.993-0.146 1.775 4.402 0.786 5.435-0.029 5.818-0.838 0.37-2.584-0.829-3.107-2.258-0.787 1.581-1.881 1.994-3.793 0.593-2.348-1.871-2.273-3.965-0.725-5.7-1.86-0.084-3.165-2.671-1.617-4.193 2.223-2.275 1.715-3.136 1.64-4.639-0.066-1.499-1.067-2.575-0.936-1.275 0.223 2.987-1.347 4.086-2.686 3.13-1.349-0.952-1.962-2.083-1.949-3.854-1.553 0.247-3.492-2.396-1.651-3.566 2.608-2.551 1.309-5.19 0.781-5.884"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(596.35 1009.4)">
            <path
              d="m0 0c1.229 1.96 2.638 3.791 3.383 2.454 0.461-0.863-0.943-2.748-1.58-3.244 0.443 0.335 1.283 0.589 1.455-0.137 0.154-0.485-0.211-1.15-0.853-1.982-0.66-0.817-1.573-1.816-2.769-2.761-3.923-2.939-6.254-5.669-7.681-8.281 3.376 3.434 7.011 4.4 7.206 2.557 0.139-1.065-0.383-2.241-1.128-3.12-0.74-0.889-1.647-1.563-2.216-1.786 0.959-0.198 0.662-1.543 0.132-2.423-0.69-1.154-1.674-2.384-4.236-2.711-4.376-0.221-5.819-1.629-7.255-2.467-1.457-0.862-1.169-1.854-0.021-1.011 4.163 2.355 5.342 1.568 5.828 0.804 0.481-0.799-0.522-2.719-1.897-3.458 1.69-0.543 2.202-1.643 1.042-3.712-1.597-2.58-3.627-2.715-5.54-1.397 0.152-1.837-2.136-3.384-3.848-2.077-2.57 1.87-3.334 1.25-4.807 0.951-1.455-0.308-2.304-1.502-1.066-1.134 2.854 0.733 4.191-0.695 3.536-2.1-0.608-1.44-1.579-2.162-3.239-2.478 0.636-1.431-1.249-3.688-2.894-2.289-3.114 1.821-5.285-0.295-5.725-1.06"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(542.75 897.86)">
            <path
              d="m0 0c0.751-1.263 1.692-1.444 2.16-0.548 0.865 1.524 0.326 3.427-0.119 3.946 0.578-0.027 0.952 0.919 1.061 1.617 0.147 0.92 0.122 1.873-0.991 2.74-0.81 0.511-1.401 1.234-1.764 2.101-0.354 0.866-0.535 1.874-0.331 2.934-0.308 0.099-0.621 0.135-0.94 0.11-0.315 0.047-0.632 0.034-0.949-0.046 0.03-1.098-0.026-2.121-0.369-3.033-0.333-0.913-0.898-1.718-1.687-2.366-0.996-1.077-0.79-2.205-0.484-3.197 0.242-0.763 0.785-1.702 1.344-1.512-0.267-0.644-0.091-2.905 1.23-4.101 0.739-0.711 1.546-0.094 1.839 1.355"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(486.05 1047.7)">
            <path
              d="m0 0c-0.85-5.235-1.153-10.554-0.988-15.854 0.172-5.294 0.743-10.601 2.071-15.739 0.643-2.573 1.509-5.084 2.461-7.558 1.008-2.452 2.123-4.86 3.372-7.2 2.503-4.676 5.406-9.125 8.39-13.507 1.491-2.192 3.007-4.368 4.446-6.595 0.75-1.094 1.399-2.252 2.097-3.379 0.704-1.124 1.344-2.285 1.986-3.445 2.563-4.64 4.9-9.412 6.871-14.336 1.976-4.921 3.663-9.958 4.988-15.093 1.309-5.139 2.176-10.393 2.52-15.683"
              style={{ fill: 'none', strokeWidth: '2.742', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(508.34 977.11)">
            <path
              d="m0 0c1.483-0.914 2.853-0.682 3.2 0.598 0.63 2.199-0.939 4.157-1.742 4.448 0.808 0.354 0.778 1.647 0.514 2.51-0.339 1.112-0.936 2.273-2.856 2.261-2.719-0.295-4.804 0.859-5.862 3.059-0.445-0.178-0.852-0.419-1.225-0.718-0.417-0.232-0.794-0.518-1.127-0.865 1.654-1.818 1.981-4.176 0.737-6.613-0.679-1.811-0.012-2.628 0.843-3.308 0.643-0.509 1.777-0.992 2.437-0.441-0.127-0.845 0.789-2.923 2.983-3.393 1.214-0.329 2.212 0.736 2.098 2.462"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(486.17 1049.2)">
            <path
              d="m0 0c0.166 2.603 0.065 5.235-1.993 4.688-1.325-0.37-1.217-3.07-0.864-3.982-0.23 0.623-1.07 1.493-1.882 1.001-1.12-0.595-0.907-3.407 0.103-6.83 2.082-5.466 2.245-9.459 1.661-12.815-0.475 2.718-1.57 4.9-2.784 6.27-1.216 1.363-2.57 1.889-3.515 1.108-2.338-1.765-1.452-5.762-0.455-6.95-1.34 0.477-2.162-1-2.332-2.25-0.232-1.635-0.099-3.503 2.749-5.583 1.3-0.794 2.306-1.507 3.099-2.168 0.824-0.659 1.437-1.265 1.915-1.833 0.934-1.136 1.444-2.114 2.018-2.927 1.168-1.646 0.125-2.602-0.753-1.114-0.861 1.172-1.653 2.064-2.351 2.731-0.674 0.67-1.283 1.11-1.83 1.378-1.094 0.541-1.935 0.388-2.55 0.122-0.623-0.281-1.012-1.128-1.099-2.131-0.094-1.002 0.202-2.151 0.799-2.99-1.29 0.169-2.311 0.035-3.01-0.573-0.706-0.609-1.08-1.692-0.999-3.374 0.151-2.148 1.031-3.549 2.179-4.301 1.167-0.751 2.72-0.814 4.399-0.39-1.321-2.323 1.151-5.32 4.156-4.094 4.477 1.652 5.198 0.72 7.038 0.091 1.849-0.591 2.478-2.123 0.955-1.538-3.583 1.282-6.121-0.095-5.778-2.097 0.275-2.015 1.389-3.138 3.61-3.699-0.669-0.898-0.489-2.146 0.192-2.932 0.689-0.771 1.873-1.058 3.2-0.122 4.852 2.397 7.255 0.075 7.736-0.788"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(486.46 1049.2)">
            <path
              d="m0 0c0.77 2.513 2.122 4.676 3.693 3.278 1.011-0.874-0.169-2.962-0.775-3.652 0.421 0.464 1.523 0.856 2.097 0.173 0.849-0.885-0.313-2.924-1.996-5.736-3.157-4.463-3.333-8.7-2.531-11.813 0.294 2.646 1.463 4.69 2.767 5.829 1.301 1.145 2.72 1.412 3.584 0.699 2.148-1.606 1.255-4.664 0.366-5.741 1.323 0.448 2.182-0.69 2.484-1.641 0.379-1.249 0.446-2.686-1.915-4.771-1.102-0.843-1.925-1.642-2.549-2.381-0.593-0.732-0.985-1.406-1.263-2.023-0.577-1.243-0.585-2.224-0.798-3.126-0.408-1.874 1.079-2.094 1.124-0.572 0.323 1.293 0.689 2.267 1.098 3.013 0.433 0.753 0.88 1.271 1.32 1.632 0.879 0.72 1.731 0.82 2.393 0.807 0.675-0.028 1.285-0.485 1.648-1.106 0.358-0.625 0.55-1.392 0.281-2.136 1.125 0.585 2.117 0.849 2.968 0.764 0.842-0.088 1.557-0.518 2.186-1.338 1.384-2.121-0.202-3.946-3.348-5.42 2.249-0.409 2.242-3.044-0.636-4.543-4.217-2.192-4.018-3.249-4.701-5.021-0.634-1.762 0.244-3.148 0.656-1.583 1.104 3.533 3.849 4.51 5.091 3.429 1.283-1.084 1.543-2.326 0.781-4.367 1.097 0.177 2.021-0.437 2.315-1.294 0.311-0.856 0.014-1.971-1.406-2.764-4.349-3.261-3.172-6.484-2.553-7.252"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(523.45 914.79)">
            <path
              d="m0 0c0.509-1.665 1.729-2.479 2.796-1.72 1.891 1.278 2.136 3.949 1.601 4.7 0.843-0.241 1.63 0.833 1.968 1.721 0.444 1.149 0.701 2.479-0.906 3.7-2.388 1.479-3.375 3.591-3.225 6.07-0.474 0.074-0.947 0.071-1.42 2e-3 -0.477 0.011-0.947-0.046-1.41-0.173 0.553-2.359-0.595-4.509-3.012-5.63-1.562-0.968-1.551-1.975-1.311-2.969 0.189-0.75 0.781-1.791 1.646-1.788-0.152-0.136-0.28-0.374-0.405-0.67-0.135-0.294-0.239-0.652-0.279-1.045-0.081-0.787 0.094-1.72 0.776-2.61 0.743-1.039 2.125-0.96 3.181 0.412"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(563.01 1047.7)">
            <path
              d="m0 0c0.85-5.235 1.153-10.554 0.988-15.854-0.172-5.294-0.743-10.601-2.071-15.739-0.643-2.574-1.509-5.084-2.461-7.558-1.008-2.453-2.123-4.86-3.372-7.2-2.503-4.677-5.406-9.126-8.39-13.507-1.491-2.192-3.007-4.368-4.446-6.595-0.75-1.094-1.399-2.252-2.097-3.379-0.704-1.124-1.344-2.285-1.986-3.445-2.563-4.64-4.9-9.412-6.871-14.336-1.976-4.921-3.663-9.958-4.988-15.093-1.309-5.139-2.176-10.393-2.52-15.683"
              style={{ fill: 'none', strokeWidth: '2.742', stroke: '#fff' }}
            />
          </g>
          <g transform="translate(540.72 977.11)">
            <path
              d="m0 0c-0.114-1.726 0.883-2.791 2.098-2.462 2.192 0.471 3.109 2.549 2.982 3.393 0.66-0.551 1.794-0.068 2.438 0.441 0.854 0.68 1.522 1.498 0.842 3.309-1.244 2.437-0.917 4.794 0.737 6.613-0.333 0.346-0.709 0.632-1.126 0.865-0.373 0.299-0.78 0.539-1.225 0.718-1.059-2.2-3.144-3.354-5.862-3.059-1.921 0.012-2.517-1.15-2.856-2.261-0.264-0.864-0.295-2.156 0.513-2.51-0.803-0.291-2.372-2.249-1.741-4.449 0.347-1.28 1.717-1.512 3.2-0.598"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(562.59 1049.2)">
            <path
              d="m0 0c-0.77 2.512-2.122 4.675-3.692 3.277-1.011-0.874 0.168-2.962 0.774-3.651-0.421 0.463-1.522 0.856-2.097 0.172-0.849-0.884 0.314-2.924 1.996-5.736 3.157-4.462 3.333-8.7 2.531-11.813-0.294 2.647-1.462 4.691-2.766 5.829-1.301 1.146-2.721 1.412-3.585 0.7-2.147-1.606-1.255-4.664-0.366-5.741-1.323 0.447-2.183-0.691-2.484-1.641-0.379-1.25-0.446-2.686 1.915-4.771 1.102-0.843 1.925-1.642 2.548-2.381 0.593-0.733 0.986-1.407 1.264-2.024 0.577-1.243 0.586-2.223 0.798-3.126 0.408-1.873-1.079-2.093-1.124-0.572-0.323 1.294-0.689 2.267-1.098 3.014-0.433 0.752-0.88 1.271-1.32 1.632-0.879 0.72-1.731 0.819-2.392 0.806-0.675-0.027-1.286-0.484-1.649-1.106-0.358-0.625-0.55-1.392-0.281-2.136-1.125 0.585-2.117 0.849-2.968 0.765-0.842-0.088-1.557-0.519-2.186-1.339-1.384-2.121 0.202-3.945 3.347-5.419-2.248-0.409-2.241-3.044 0.637-4.544 4.217-2.191 4.019-3.248 4.701-5.02 0.634-1.763-0.244-3.149-0.656-1.583-1.104 3.533-3.849 4.509-5.091 3.428-1.283-1.084-1.542-2.326-0.78-4.367-1.098 0.177-2.022-0.437-2.316-1.293-0.311-0.857-0.014-1.971 1.406-2.765 4.349-3.261 3.172-6.483 2.554-7.252"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(562.89 1049.2)">
            <path
              d="m0 0c-0.166 2.603-0.064 5.235 1.993 4.688 1.326-0.37 1.217-3.07 0.864-3.982 0.231 0.623 1.07 1.493 1.882 1.001 1.12-0.594 0.907-3.407-0.102-6.83-2.083-5.466-2.246-9.459-1.662-12.815 0.476 2.718 1.571 4.9 2.784 6.27 1.217 1.363 2.57 1.889 3.515 1.108 2.338-1.765 1.452-5.762 0.455-6.95 1.34 0.477 2.161-1 2.332-2.25 0.232-1.635 0.099-3.503-2.749-5.583-1.3-0.794-2.306-1.507-3.099-2.168-0.824-0.659-1.437-1.265-1.915-1.833-0.934-1.136-1.443-2.114-2.018-2.927-1.168-1.646-0.125-2.602 0.753-1.114 0.861 1.171 1.654 2.064 2.351 2.731 0.674 0.67 1.283 1.11 1.83 1.378 1.094 0.541 1.935 0.388 2.551 0.122 0.622-0.281 1.011-1.128 1.098-2.131 0.094-1.002-0.202-2.151-0.799-2.99 1.29 0.169 2.311 0.035 3.009-0.573 0.707-0.609 1.081-1.692 1.001-3.374-0.152-2.148-1.032-3.549-2.18-4.301-1.167-0.751-2.721-0.814-4.4-0.39 1.322-2.323-1.151-5.32-4.155-4.094-4.477 1.652-5.198 0.719-7.038 0.091-1.849-0.591-2.478-2.123-0.955-1.538 3.583 1.282 6.121-0.095 5.778-2.097-0.275-2.015-1.389-3.138-3.61-3.699 0.669-0.899 0.489-2.146-0.192-2.932-0.689-0.771-1.873-1.059-3.2-0.123-4.852 2.398-7.256 0.076-7.735-0.787"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(525.61 914.79)">
            <path
              d="m0 0c1.056-1.372 2.437-1.45 3.181-0.411 0.681 0.89 0.856 1.822 0.776 2.609-0.041 0.394-0.145 0.751-0.28 1.045-0.124 0.296-0.253 0.535-0.405 0.671 0.865-4e-3 1.459 1.037 1.647 1.788 0.24 0.994 0.252 2-1.311 2.968-2.417 1.122-3.565 3.271-3.012 5.63-0.463 0.127-0.933 0.184-1.41 0.174-0.473 0.068-0.946 0.071-1.42-3e-3 0.15-2.479-0.837-4.591-3.224-6.07-1.607-1.22-1.35-2.55-0.907-3.7 0.339-0.888 1.125-1.961 1.967-1.721-0.534-0.751-0.289-3.422 1.602-4.699 1.067-0.76 2.287 0.055 2.796 1.719"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(524.7 1044.7)">
            <path d="m0 0v-104.19" style={{ fill: 'none', strokeWidth: '2.742', stroke: '#fff' }} />
          </g>
          <g transform="translate(524.7 982.09)">
            <path
              d="m0 0c0.796-1.263 2.145-1.634 3.059-0.886 1.654 1.266 1.344 3.206 0.804 3.746 0.862-0.1 1.545 0.769 1.801 1.443 0.333 0.881 0.459 1.842-1.128 2.737-2.407 1.064-3.473 2.808-3.119 4.792-0.469 0.083-0.941 0.105-1.417 0.072-0.476 0.033-0.948 0.011-1.417-0.072 0.353-1.984-0.713-3.728-3.119-4.792-1.587-0.895-1.461-1.856-1.128-2.737 0.256-0.674 0.938-1.543 1.8-1.443-0.54-0.54-0.85-2.48 0.805-3.746 0.914-0.748 2.264-0.377 3.059 0.886"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(524.54 1045.9)">
            <path
              d="m0 0c-0.304 2.128-1.065 4.155-2.929 3.334-1.194-0.525-0.499-2.533-9e-3 -3.196-0.334 0.451-1.333 0.98-2.032 0.488-0.992-0.616-0.249-2.637 1.121-5.203 2.655-4.086 2.81-7.444 2.119-10.096-0.766 4.394-4.528 6.918-6.332 5.694-2.234-1.381-1.343-4.268-0.406-5.196-1.331 0.379-2.175-0.692-2.419-1.594-0.315-1.182-0.296-2.536 2.294-4.252 4.787-2.701 5.012-4.57 5.8-6.005 0.793-1.474-0.525-1.985-0.976-0.706-2.358 4.129-4.482 4.273-5.782 4.041-1.323-0.256-2.097-2.165-1.276-3.488-2.446 0.631-4.101 0.218-4.855-1.91-0.775-2.731 1.381-4.092 4.843-4.347-2.002-1.179-0.934-3.694 2.303-3.818 4.753-0.246 5.016-1.142 6.4-2.233 1.373-1.084 1.228-2.429 0.143-1.438-2.598 2.217-5.487 2.032-6.095 0.613-0.662-1.419-0.26-2.538 1.402-3.759-2.067-0.687-1.924-3.542 1.323-3.403 5.4-0.408 6.158-3.11 6.075-3.915"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(524.85 1045.9)">
            <path
              d="m0 0c0.304 2.128 1.065 4.155 2.929 3.334 1.194-0.525 0.5-2.533 9e-3 -3.196 0.334 0.451 1.333 0.98 2.031 0.488 0.993-0.616 0.25-2.637-1.12-5.203-2.655-4.086-2.81-7.444-2.119-10.096 0.766 4.394 4.528 6.918 6.332 5.694 2.234-1.381 1.343-4.268 0.406-5.196 1.331 0.379 2.175-0.692 2.419-1.594 0.314-1.182 0.296-2.536-2.294-4.252-4.787-2.701-5.011-4.57-5.8-6.005-0.793-1.474 0.524-1.985 0.976-0.706 2.359 4.129 4.481 4.273 5.783 4.041 1.322-0.256 2.096-2.165 1.275-3.488 2.446 0.631 4.1 0.218 4.855-1.91 0.775-2.731-1.382-4.092-4.844-4.347 2.002-1.179 0.934-3.694-2.303-3.818-4.752-0.246-5.015-1.142-6.4-2.233-1.372-1.084-1.227-2.429-0.142-1.438 2.598 2.217 5.487 2.032 6.095 0.613 0.662-1.419 0.26-2.538-1.402-3.759 2.067-0.687 1.923-3.542-1.323-3.403-5.4-0.408-6.159-3.11-6.075-3.915"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(524.69 928.55)">
            <path
              d="m0 0c0.796-1.263 2.145-1.635 3.059-0.887 1.655 1.267 1.344 3.206 0.804 3.747 0.862-0.1 1.545 0.769 1.801 1.443 0.333 0.881 0.46 1.842-1.128 2.736-2.406 1.065-3.472 2.808-3.119 4.793-0.468 0.083-0.941 0.105-1.417 0.072-0.476 0.033-0.948 0.011-1.417-0.072 0.353-1.985-0.712-3.728-3.118-4.793-1.588-0.894-1.462-1.855-1.129-2.736 0.257-0.674 0.939-1.543 1.8-1.443-0.54-0.541-0.85-2.48 0.805-3.747 0.914-0.748 2.264-0.376 3.059 0.887"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(610.19 861.01)">
            <path d="m0 0-96.482 19.603 23.815 161.01 96.483-19.603z" style={{ fill: '#b4171a' }} />
          </g>
          <g transform="translate(578.4 870.28)">
            <path
              d="m0 0-53.114 19.746c-5.435 2.021-8.444 8.822-6.719 15.191l27.2 100.42c1.725 6.368 7.53 9.892 12.966 7.872l53.113-19.747c5.436-2.021 8.444-8.821 6.72-15.19l-27.201-100.42c-1.724-6.369-7.529-9.893-12.965-7.872"
              style={{ fill: '#e6e6e6' }}
            />
          </g>
          <g transform="translate(584.46 945.2)">
            <path
              d="m0 0c0.572 1.637-4.874 5.031-12.164 7.58s-13.664 3.288-14.236 1.651c-0.538-1.539 4.242-4.63 10.873-7.113l-2.53-9.562c-1.665 0.533-3.005 0.744-3.103 0.466-0.108-0.308 1.363-1.104 3.285-1.776s3.568-0.966 3.676-0.657c0.097 0.277-1.082 0.948-2.717 1.568l3.979 9.056c6.734-2.191 12.399-2.752 12.937-1.213"
              style={{ fill: '#ccc' }}
            />
          </g>
          <g transform="translate(559.64 1002.6)">
            <path
              d="m0 0c0-2.02-1.637-3.656-3.656-3.656-2.02 0-3.656 1.636-3.656 3.656 0 2.019 1.636 3.656 3.656 3.656 2.019 0 3.656-1.637 3.656-3.656"
              style={{ fill: '#ccc' }}
            />
          </g>
          <g transform="translate(645,884)">
            <path
              d="m0 0c5 2 8.155 4.65 10 7l-30.176 28.116c-1.597-6.287-5.229-6.839-5.229-6.839l-37.351 0.701 0.908-26.626 21.848-2.352c32-2 40 0 40 0"
              style={{ fill: '#ffd6b5' }}
            />
          </g>
          <g transform="translate(582.24 912.98)">
            <path
              d="m0 0-11.445 0.215-21.583 0.405c-2.217 4.625-9.975 13.874-9.975 13.874-9.421 12.553-19.396 2.643-19.396 2.643-8.701-9.543-5.817-14.395-2.469-16.685 1.984-1.356 4.132-1.814 4.132-1.814 4.49-0.315 9.104-3.031 12.705-5.788 3.411-2.124 5.378-4.009 6.295-5.675 0.255-0.271 0.395-0.429 0.395-0.429l42.249-13.372z"
              style={{ fill: '#ffd6b5' }}
            />
          </g>
          <g transform="translate(513.02 909.81)">
            <path
              d="m0 0c-2.893-2.809-6.145-6.724-9.798-12.071l-25.004 6.175c-0.026-7.421-0.555-14.84-1.601-22.189-0.086-0.606-0.187-1.215-0.295-1.824h27.453c11.224 0 22.094 5.545 31.711 10.813 1.373 0.752 2.755 1.535 3.865 2.638 0.087 0.087 0.169 0.179 0.252 0.271-5.875 8.51-20.141 14.041-26.583 16.187"
              style={{ fill: '#ffd6b5' }}
            />
          </g>
          <g transform="translate(540.57 900.19)">
            <path
              d="m0 0c-1.341 2.425-4.294 4.06-6.358 5.64-3.601 2.757-8.215 5.473-12.705 5.788 0 0-2.148 0.458-4.132 1.814-1.351-0.913-2.804-2.108-4.358-3.617 6.443-2.146 20.708-7.677 26.583-16.187 0.98 1.069 1.684 2.432 1.682 3.874-2e-3 1-0.269 1.887-0.712 2.688"
              style={{ fill: '#f3caaa' }}
            />
          </g>
          <g transform="translate(614.6 1261.6)">
            <path
              d="m0 0c0-3.333-2.702-6.035-6.035-6.035s-6.035 2.702-6.035 6.035 2.702 6.035 6.035 6.035 6.035-2.702 6.035-6.035"
              style={{ fill: '#fff' }}
            />
          </g>
          <g transform="translate(624.21 1098.5)">
            <path
              d="m0 0c1.49 8.068 3.212 16.142 4.638 24.24-0.103 0.07-0.206 0.139-0.309 0.208z"
              style={{ fill: '#a6cac8' }}
            />
          </g>
          <g transform="translate(440.57 1118.1)">
            <path
              d="m0 0c0.208-0.713 0.41-1.427 0.613-2.141 2.296-8.083 4.361-16.088 9.688-22.714 4.843-6.026 10.444-11.441 16.599-16.117 12.294-9.339 26.927-15.814 42.287-17.799 30.037-3.882 58.981 12.443 79.163 33.55 6.885 7.2 7.646 16.464 9.714 25.825 1.222 5.533 2.445 11.066 3.667 16.599-2.222 0.666-4.244 1.183-6.011 1.582h-145.35c-5.217 0-10.414 0.14-15.273-1.924 1.633-5.62 3.267-11.241 4.901-16.861"
              style={{ fill: '#ffd6b5' }}
            />
          </g>
          <g transform="translate(524.11 1062.1)">
            <path
              d="m0 0c1.206 1.937 2.623 3.753 4.217 5.417 0.774 0.86 1.66 1.598 2.52 2.383 0.927 0.686 1.812 1.464 2.821 2.052 1.934 1.33 4.11 2.259 6.331 3.113-2.306-0.532-4.648-1.16-6.815-2.238-1.125-0.461-2.141-1.124-3.213-1.709-1.003-0.683-2.047-1.333-2.986-2.118-1.924-1.513-3.708-3.233-5.297-5.129-0.706-0.86-1.388-1.744-2.029-2.664-0.641 0.92-1.323 1.804-2.028 2.664-1.59 1.896-3.374 3.616-5.298 5.129-0.939 0.785-1.983 1.435-2.986 2.118-1.072 0.585-2.088 1.248-3.213 1.709-2.167 1.078-4.508 1.706-6.815 2.238 2.221-0.854 4.397-1.783 6.332-3.113 1.008-0.588 1.894-1.366 2.82-2.052 0.86-0.785 1.746-1.523 2.52-2.383 1.594-1.664 3.011-3.48 4.218-5.417 0.592-0.975 1.158-1.96 1.655-2.975 0.04-0.08 0.075-0.166 0.115-0.247 1.805-0.044 3.603-0.019 5.394 0.072 0.028 0.057 0.053 0.118 0.081 0.175 0.497 1.015 1.063 2 1.656 2.975"
              style={{ fill: '#e4b69a' }}
            />
          </g>
          <g transform="translate(523.69 1316.1)">
            <path d="m0 0c3.09 3.616 7.101 6.312 11.417 8.353 4.337 2.007 9.112 3.297 14.029 3.5 4.901 0.248 9.936-0.663 14.389-2.72 4.46-2.041 8.287-5.236 10.947-9.133-4.288 2.006-8.344 3.734-12.518 4.708-4.152 1.009-8.337 1.377-12.555 1.181-4.218-0.167-8.46-0.896-12.721-1.945-4.272-1.05-8.539-2.399-12.988-3.944" />
          </g>
          <g transform="translate(557.92 1291.6)">
            <path d="m0 0c1e-3 -8.087-5.325-14.644-11.896-14.645s-11.898 6.554-11.9 14.642c-1e-3 8.087 5.325 14.644 11.896 14.645s11.899-6.554 11.9-14.642" />
          </g>
          <g transform="translate(554.47 1301.5)">
            <path d="m0 0c-0.376 0.075-0.231-0.011-0.277-0.031 8e-3 -0.034 0.039-0.073 0.073-0.11 0.085-0.072 0.204-0.137 0.346-0.194 0.287-0.106 0.643-0.186 1.034-0.209 0.782-0.054 1.683 0.031 2.59 0.259 0.916 0.204 1.841 0.537 2.77 0.919 0.922 0.395 1.834 0.859 2.747 1.357-0.518-0.893-1.069-1.787-1.691-2.658-0.633-0.86-1.317-1.716-2.131-2.509-0.798-0.808-1.73-1.553-2.856-2.188-0.563-0.318-1.189-0.593-1.893-0.809-0.355-0.104-0.734-0.192-1.149-0.248-0.214-0.025-0.433-0.044-0.672-0.051-0.293 0.014-0.395-0.034-1.011 0.113z" />
          </g>
          <g transform="translate(483.27 1291.6)">
            <path d="m0 0c1e-3 -8.087-5.325-14.644-11.896-14.645s-11.899 6.554-11.9 14.642c-1e-3 8.087 5.325 14.644 11.896 14.645s11.899-6.554 11.9-14.642" />
          </g>
          <g transform="translate(465.48 1295.2)">
            <path d="m0 0c-0.616-0.147-0.718-0.098-1.01-0.112-0.24 7e-3 -0.458 0.025-0.673 0.05-0.415 0.057-0.794 0.145-1.149 0.249-0.704 0.215-1.331 0.49-1.893 0.808-1.126 0.635-2.058 1.38-2.857 2.188-0.813 0.794-1.497 1.649-2.13 2.509-0.621 0.871-1.172 1.765-1.69 2.659 0.913-0.499 1.823-0.962 2.746-1.358 0.929-0.382 1.854-0.714 2.77-0.919 0.907-0.227 1.809-0.312 2.59-0.259 0.391 0.024 0.747 0.103 1.034 0.209 0.142 0.058 0.261 0.122 0.346 0.194 0.034 0.038 0.065 0.076 0.073 0.11-0.045 0.021 0.1 0.107-0.277 0.031z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconHelperMan.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconHelperMan.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconHelperMan;
