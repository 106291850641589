import classNames from 'classnames';
import { bool, object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import facebookImage from '../../assets/schemaPictureFacebook.png';
import twitterImage from '../../assets/schemaPictureTwitter.png';
import Guy from './images/Guy.gif';
import Genie from './images/Genie.png';
import EatDrinkBeMerry from './images/EatDrinkBeMerry.png';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  MarqueeStandUp,
  Page,
  SectionCTA,
  SectionHero,
  CardCarousel,
  NamedLink,
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './LandingPage.css';

import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

const imageName = 'scaled-small';
const imageName2x = 'scaled-medium';
const imageName4x = 'scaled-large';
const imageName6x = 'scaled-xlarge';

const imageLandscape = {
  id: new UUID('image-landscape'),
  type: 'image',
  attributes: {
    variants: {
      [imageName]: {
        name: imageName,
        width: 400,
        height: 200,
        url: 'https://via.placeholder.com/400x200',
      },
      [imageName2x]: {
        name: imageName2x,
        width: 800,
        height: 400,
        url: 'https://via.placeholder.com/800x400',
      },
      [imageName4x]: {
        name: imageName4x,
        width: 1600,
        height: 800,
        url: 'https://via.placeholder.com/1600x800',
      },
      [imageName6x]: {
        name: imageName6x,
        width: 2400,
        height: 1200,
        url: 'https://via.placeholder.com/2400x1200',
      },
    },
  },
};

const imageSquare = {
  id: new UUID('image-square'),
  type: 'image',
  attributes: {
    variants: {
      [imageName]: {
        name: imageName,
        width: 400,
        height: 400,
        url: 'https://via.placeholder.com/400x400',
      },
      [imageName2x]: {
        name: imageName2x,
        width: 800,
        height: 800,
        url: 'https://via.placeholder.com/800x800',
      },
      [imageName4x]: {
        name: imageName4x,
        width: 1600,
        height: 1600,
        url: 'https://via.placeholder.com/1600x1600',
      },
      [imageName6x]: {
        name: imageName6x,
        width: 2400,
        height: 2400,
        url: 'https://via.placeholder.com/2400x2400',
      },
    },
  },
};

const imagePortrait = {
  id: new UUID('image-portrait'),
  type: 'image',
  attributes: {
    variants: {
      [imageName]: {
        name: imageName,
        width: 400,
        height: 800,
        url: 'https://via.placeholder.com/400x800',
      },
      [imageName2x]: {
        name: imageName2x,
        width: 800,
        height: 1600,
        url: 'https://via.placeholder.com/800x1600',
      },
      [imageName4x]: {
        name: imageName4x,
        width: 800,
        height: 1600,
        url: 'https://via.placeholder.com/800x1600',
      },
      [imageName6x]: {
        name: imageName6x,
        width: 1200,
        height: 2400,
        url: 'https://via.placeholder.com/1200x2400',
      },
    },
  },
};

const contactUsPage = (
  <div className={css.linkButtonSection}>
    <NamedLink name="ContactPage" className={css.linkButton}>
      <span className={css.linkText}>
        <FormattedMessage id="Footer.toContactPage" />
      </span>
    </NamedLink>
  </div>
);

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled, isAuthenticated, currentUser } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const isSeller =
    isAuthenticated && currentUser && currentUser.attributes.profile.publicData.isSeller;

  const data = [
    {
      imageUrl: 'VCarlos.png',
      desc:
        '"I bought a Valentine\'s Day reservation at Canoe at the last minute and looked like a hero!"',
      customer: { name: 'V. Carlos, ' },
      resturant: { name: 'a Vinings Snatcher!' },
    },
    {
      imageUrl: 'RSanders.png',
      desc:
        '"I took my girlfriend to a full steakhouse in Buckhead and proposed, and now she\'s my fiancé! Thanks TableSnatch!"',
      customer: { name: 'R. Sanders, ' },
      resturant: { name: 'an Atlanta Snatcher!' },
    },
    {
      imageUrl: 'CBroussard.png',
      desc:
        '"For high-end restaurants, I always use TableSnatch last minute when buddies come to town. Very cool."',
      customer: { name: 'C. Broussard, ' },
      resturant: { name: 'a regular Snatcher!' },
    },
    {
      imageUrl: 'LGray.png',
      desc: '"The go-to for top restaurant reservations during big time sporting events."',
      customer: { name: 'L. Gray, ' },
      resturant: { name: 'a Louisville Snatcher!' },
    },
    {
      imageUrl: 'JJun.png',
      desc:
        '"When Mother\'s Day brunch suddenly switched from my house to a restaurant, TableSnatch saved the day."',
      customer: { name: 'J. Jun, ' },
      resturant: { name: 'a relieved Snatcher!' },
    },
    {
      imageUrl: 'ALockerman.png',
      desc:
        '"I always use TableSnatch when I entertain clients unexpectedly. Makes me look like an insider wherever we go."',
      customer: { name: 'A. Lockerman, ' },
      resturant: { name: 'a big-time Snatcher!' },
    },
  ];
  return (
    <Page
      className={classNames(css.root)}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              isAuthenticated={isAuthenticated}
              isSeller={isSeller}
              showErrorMessage={false}
            />
          </div>

          <div className={css.sections}>
            <div className={css.standContainer}>
              {/* <MarqueeStandUp
                upperBoardFirstLine={'Sell Your'}
                upperBoardSecondLine={'Reservation!'}
                lowerBoardFirstLine={'Snatch That'}
                lowerBoardSecondLine={'Reservation!'}
              /> */}
              <div className={css.standHowItWorkLeft}>
                <img src={Guy} className={css.guy} alt={'Guy'} />
              </div>
              <div className={css.standHowItWorkMiddle}>
                <div className={css.title}>How it works</div>
                <div className={css.HowItWorkPoints}>
                  <div className={css.subTitle}>Sell your hard-to-get reservations!</div>
                  <div className={css.description}>
                    If you won't use that great reservation, get paid for it on TableSnatch!
                  </div>
                </div>
                <div className={css.HowItWorkPoints}>
                  <div className={css.subTitle}>TableSnatch will market your restaurant!</div>
                  <div className={css.description}>
                    TableSnatch <i>loves</i> and supports restaurants! We target market your
                    hard-to-get reservation to help <u>you</u> sell it and help <u>them</u> fill it.
                  </div>
                </div>
                <div className={css.HowItWorkPoints}>
                  <div className={css.subTitle}>Buy a hard-to-get reservation!</div>
                  <div className={css.description}>
                    TableSnatch makes selling your reservation a no-brainer - good for you and great
                    for restaurants!
                  </div>
                </div>
              </div>
              <div className={css.standHowItWorkRight}>
                <img src={Genie} className={css.genie} alt={'Genie'} />
              </div>
            </div>
            {/* <div className={css.section}>
              <div className={css.sectionContentCTA}>
                <SectionCTA isAuthenticated={isAuthenticated} />
              </div>
            </div> */}
          </div>

          <div className={css.sections}>
            <div className={css.standContainer}>
              <div className={css.customerFeedback}>
                <CardCarousel rawOnly={true} images={[imageLandscape]} data={data} count={6} />
              </div>
            </div>
          </div>

          <div className={css.sections}>
            <div className={css.standContainer}>
              <div className={css.weHelpRestaurantsBlock}>
                <div className={css.weHelpRestaurants}>
                  <div className={css.weHelpRestaurantstitle}>We help restaurants</div>
                  <div className={css.weHelpRestaurantsPoints}>
                    <div className={css.weHelpRestaurantssubTitle}>
                      We market you in our digital media!
                    </div>
                    <div className={css.weHelpRestaurantssubTitle}>You can sell through us!</div>
                    <div className={css.weHelpRestaurantssubTitle}>
                      We can feature you on our site!
                    </div>
                    <div className={css.weHelpRestaurantssubTitle}>We reduce no-shows!</div>
                    {contactUsPage}
                  </div>
                </div>

                <div className={css.weHelpRestaurantsLeft}>
                  <img
                    src={EatDrinkBeMerry}
                    className={css.eatDrinkBeMerry}
                    alt={'EatDrinkBeMerry'}
                  />
                </div>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserListing, currentUserListingFetched } = state.user;
  const { isAuthenticated } = state.Auth;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    isAuthenticated,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
