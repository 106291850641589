import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import css from './BottomHelpers.css';

class HelperBubbles extends Component {
  constructor(props) {
    super(props);
    this.state = { show: true };
  }

  componentDidMount() {}

  render() {
    const { className,  children } = this.props;
    const isServer = typeof window === 'undefined';

    // Server side doesn't know about cookie consent
    if (isServer || !this.state.show) {
      return null;
    } else {
      const classes = classNames(className, css.helperBubble);

      return <div className={classes}>{children}</div>;
    }
  }
}

const { string } = PropTypes;

HelperBubbles.defaultProps = {
  className: null,
  rootClassName: null,
};

HelperBubbles.propTypes = {
  className: string,
  rootClassName: string,
};

export default HelperBubbles;
