import classNames from 'classnames';
import { bool, string } from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { Button, IconEditField, IconBill, NamedLink } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isSeller } from '../../util/userHelper';
import css from './ProfileSettingsLinks.css';

class ProfileSettingsLinksComponent extends Component {
  render() {
    const { currentUser, handleBecomeSeller } = this.props;

    return (
      <>
        <div className={css.sectionContainer}>
          <div className={css.profilePageSep}></div>
          <div className={classNames(css.sectionContainer)}>
            <NamedLink className={css.sectionTitle} name={'InboxPage'} params={{ tab: 'orders' }}>
              <IconBill className={css.iconEdit} /> Past and Pending Reservations
            </NamedLink>
          </div>
          <div className={classNames(css.sectionContainer)}>
            <NamedLink className={css.sectionTitle} name={'PasswordChangePage'}>
              <IconEditField className={css.iconEdit} /> Password
            </NamedLink>
          </div>
          <div className={classNames(css.sectionContainer)}>
            <NamedLink className={css.sectionTitle} name={'PaymentMethodsPage'}>
              <IconEditField className={css.iconEdit} /> Credit Card Info For Your Transactions!
            </NamedLink>
          </div>
          {isSeller(currentUser) && (
            <div className={classNames(css.sectionContainer)}>
              <NamedLink className={css.sectionTitle} name={'StripePayoutPage'}>
                <IconEditField className={css.iconEdit} /> Banking Info For Deposits To You!
              </NamedLink>
            </div>
          )}
        </div>
        {!isSeller(currentUser) && (
          <>
            <div className={classNames(css.sectionContainer, css.lastSection)}>
              <h3 className={css.sectionTitle}>
                <FormattedMessage id="ProfileSettingsLinks.becomeSeller" />
              </h3>
              <Button
                className={css.becomeSellerButton}
                onClick={e => {
                  e.preventDefault();
                  handleBecomeSeller();
                }}
              >
                Become a Seller
              </Button>
              <p className={css.becomeSellerInfo}>
                <FormattedMessage id="ProfileSettingsLinks.becomeSellerInfo" />
              </p>
            </div>
          </>
        )}
      </>
    );
  }
}

ProfileSettingsLinksComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsLinksComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsLinks = compose(injectIntl)(ProfileSettingsLinksComponent);

ProfileSettingsLinks.displayName = 'ProfileSettingsLinks';

export default ProfileSettingsLinks;
