import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { BottomHelpers, NamedLink, OvalLinkType } from '../../components';
import css from './SectionCTA.css';

const HelperWrapper = props => {
  return <div className={css.helperWrapper}>{props.children}</div>;
};
const SectionCTA = props => {
  const { rootClassName, className, isAuthenticated } = props;

  const classes = classNames(rootClassName || css.root, className);

  const ovalLink = isAuthenticated ? (
    <NamedLink name={'ManageListingsPage'}>Check Your Listings</NamedLink>
  ) : (
    <NamedLink
      name={'SearchPage'}
      to={{
        search:
          '?address=Atlanta%2C%20GA%2C%20USA&bounds=34.22389042%2C-83.97611052%2C33.129873%2C-84.77874155  ',
      }}
    >
      Check Your Listings
    </NamedLink>
  );

  return (
    <div className={classes}>
      <HelperWrapper>
        <BottomHelpers />
      </HelperWrapper>
      {isAuthenticated && (
        <>
            <OvalLinkType link={ovalLink} alt />
        </>
      )}
    </div>
  );
};

SectionCTA.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCTA.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCTA;
