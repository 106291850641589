import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: December 21, 2020</p>

      <p>
        This policy details how data about you is used when you access our websites and services,
        including via our mobile applications (together, "the site") or interact with us. If we
        update it, we will revise the date, place notices on the site if changes are material,
        and/or obtain your consent as required by law.
      </p>

      <h2>1. Protecting your privacy</h2>
      <p>
        <ul>
          <li>
            We take precautions to prevent unauthorized access to or misuse of data about you.
          </li>
          <li>We do not run ads, other than the classifieds posted by our users.</li>
          <li>We do not share your data with third parties for marketing purposes.</li>
          <li>We do not engage in cross-marketing or link-referral programs.</li>
          <li>We do not employ tracking devices for marketing purposes.</li>
          <li>We do not send you unsolicited communications for marketing purposes.</li>
          <li>We do not engage in affiliate marketing (and prohibit it on the site).</li>
          <li>We do provide email proxy & relay services to reduce unwanted email.</li>
          <li>Please review privacy policies of any third party sites linked to from the site.</li>
          <li>
            We do not respond to "Do Not Track" signals (see{' '}
            <a href="https://allaboutdnt.com/" target="_blank" rel="noopener noreferrer">
              allaboutdnt.com
            </a>
            ).
          </li>
        </ul>
      </p>

      <h2>2. Data we collect, use and disclose:</h2>
      <p>
        Below is a list of all the types of data we may collect, where we get it, why we collect it,
        and the categories of third parties to whom we disclosed it. We do not sell your data to
        third parties at this time. Please note that disclosure to "Payment processors" applies when
        you pay for an offer on the site using a credit, debit, or other transactional card or
        electronic payment account.
        {/* <style type="text/css">
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg .tg-fymr{border-color:inherit;font-weight:bold;text-align:left;vertical-align:top}
.tg .tg-0pky{border-color:inherit;text-align:left;vertical-align:top}
.tg .tg-0lax{text-align:left;vertical-align:top}
</style> */}
        <div className={css.tableWrapper}>
          <table className={css.table}>
            <thead>
              <tr>
                <th className={css.th}>Data type</th>
                <th className={css.th}>Where we got it</th>
                <th className={css.th}>Why collected</th>
                <th className={css.th}>Disclosed to</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  First and last name&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User entry&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Facilitating transactions and personalizing your use of the site&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Payment processors&nbsp;&nbsp;{' '}
                </td>
              </tr>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  Email address&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User entry&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Account creation, user-to-user and site-to-user communications and combatting
                  fraud/abuse&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  No one&nbsp;&nbsp;{' '}
                </td>
              </tr>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  Phone number&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User entry&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User-to-user communications, combatting fraud/abuse, personalizing your use of the
                  site&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Payment processors and phone verification service providers&nbsp;&nbsp;{' '}
                </td>
              </tr>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  Mailing or street address&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User entry&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Account and post creation, site communicating with corporate users, facilitating
                  transactions and personalizing your use of the site{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Payment processors&nbsp;&nbsp;{' '}
                </td>
              </tr>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  Credit card data&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User entry&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Facilitating your transactions&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Payment processors&nbsp;&nbsp;{' '}
                </td>
              </tr>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  Geographic location (latitude and longitude)&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User entry, IP/geolocation providers&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Personalizing your use of the site and combatting fraud/abuse&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  No one&nbsp;&nbsp;{' '}
                </td>
              </tr>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  Photos and other data you voluntarily provide, post on or send via CL&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User entry&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Facilitating and personalizing your use of the site&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  No one&nbsp;&nbsp;{' '}
                </td>
              </tr>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  Saved searches, account preferences, favorite/hidden postings&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User entry&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Facilitating and personalizing your use of the site&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  No one&nbsp;&nbsp;{' '}
                </td>
              </tr>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  HTTP browser cookie&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User's browser, CL web server&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Facilitating and personalizing your use of the site and combatting
                  fraud/abuse&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  No one&nbsp;&nbsp;{' '}
                </td>
              </tr>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  Information about your device and browser such as device ID, browser version,
                  operating system, plugins&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User's browser, mobile app&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Facilitating and personalizing your use of the site and combatting
                  fraud/abuse&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  No one&nbsp;&nbsp;{' '}
                </td>
              </tr>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  IP address&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User's browser, mobile app, IP/geolocation providers&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Combatting fraud/abuse&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Service providers that help us combat fraud/abuse&nbsp;&nbsp;{' '}
                </td>
              </tr>
              <tr>
                <td className={css.td}>
                  {' '}
                  <br />
                  Web page views, access times, HTTP headers&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  User's browser, mobile app&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  Combatting fraud/abuse&nbsp;&nbsp;{' '}
                </td>
                <td className={css.td}>
                  {' '}
                  <br />
                  No one&nbsp;&nbsp;{' '}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        We may share some or all of the above listed data in the following circumstances:
        <ul>
          <li>to respond to subpoenas, search warrants, court orders, or other legal process.</li>
          <li>
            to protect the rights, property, or safety of site users, the site, or the general
            public.
          </li>
          <li>at your direction (e.g. if you authorize us to share data with other users).</li>
          <li>in connection with a merger, bankruptcy, or sale/transfer of assets.</li>
        </ul>
      </p>

      <h2>3. Data we store</h2>

      <p>
        <ul>
          <li>
            We retain data as needed to facilitate and personalize your use of the site, combat
            fraud/abuse and/or as required by law.
          </li>
          <li>We make good faith efforts to store data securely but can make no guarantees.</li>
          <li>You may access and update certain data about you via your account login.</li>
        </ul>
      </p>

      <h2>4. California Users:</h2>

      <p>
        To learn more about the California Consumer Privacy Act and how it applies to you, please
        visit the{' '}
        <a href="https://oag.ca.gov/privacy/ccpa" target="_blank" rel="noopener noreferrer">
          California Attorney-General's website.
        </a>
      </p>
      <p>
        <span className={css.bold}>Right to know:</span> You have the right to request that we
        disclose the data we collect, use and disclose, and other information relating to data we
        collect about you.
        <span className={css.bold}>Right to delete:</span> You have the right to request the
        deletion of data that we have collected from you, subject to certain exceptions.
        <span className={css.bold}>Right to non-discrimination:</span> You have the right not to
        receive discriminatory treatment for exercising the rights listed above.
        <p>
          You may submit a request to know or delete via {' '}
          <a href="mailto:TableSnatcher@gmail.com">
            <span lang="en">TableSnatcher@gmail.com</span>
          </a>
        </p>
        <p>
          Only you, or someone you authorize to act on your behalf, may make a request to know or
          delete your data. An authorized agent may make a request on your behalf by providing
          written permission signed by you.
        </p>
        We will need to confirm your identity before processing your request by asking you to log
        into your existing account (if you are a registered user) or by asking you for additional
        information, such as a government issued ID, to confirm your identity against information we
        have already collected.
      </p>

      <h2>6. Contact</h2>

      <p>
        If you have any questions or concerns about the site's privacy policy and practices please
        email{' '}
        <a href="mailto:TableSnatcher@gmail.com">
          <span lang="en">TableSnatcher@gmail.com.</span>
        </a>
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
