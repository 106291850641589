import classNames from 'classnames';
import { array, arrayOf, func, node, number, object, string } from 'prop-types';
import React, { Component } from 'react';
import { FilterPlain, FilterPopup, RangeSlider } from '..';
import {  injectIntl, intlShape } from '../../util/reactIntl';
import css from './SelectRangeFilter.css';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};
class SelectRangeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handles: [null, null],
    };
    this.filter = null;
    this.filterContent = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  selectOption(val) {
    this.setState({ handles: val });
  }
  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      options,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      searchMode,
      intl,
      showAsPopup,
      min,
      max,
      step,
      initialValues,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues =
      !!initialValues && !!initialValues[queryParamName] && initialValues[queryParamName];
    const handles = [
      this.state.handles[0] || parseInt((hasInitialValues && hasInitialValues.split(',')[0]) || 0),
      this.state.handles[1] ||
        parseInt((hasInitialValues && hasInitialValues.split(',')[1]) || 100),
    ];
    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectRangeFilter.labelSelected' },
          { labelText: label, count: initialValues.length }
        )
      : label;

    const labelForPlain = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectRangeFilterPlainForm.labelSelected' },
          { labelText: label, count: initialValues.length }
        )
      : label;

    const contentStyle = this.positionStyleForContent();

    const handleSubmit = values => {
      onSubmit({ [queryParamName]: values?.join(',') || null });
    };

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={handles}
        keepDirtyOnReinitialize
        {...rest}
      >
        <div className={css.rangeLabelsWrapper}>
          Seats{' '}
          <div
            className={css.rangeLabels}
            id={`minCap`}
            name="minCap"
            component="input"
            type="number"
            placeholder={min}
          >
            {handles[0]}
          </div>
          -
          <div
            className={css.rangeLabels}
            id={`maxCap`}
            name="maxPrice"
            component="input"
            type="number"
            placeholder={max}
            min={min}
            max={max}
            step={step}
          >
            {handles[1]}
          </div>
        </div>
        <div className={css.sliderWrapper}>
          <RangeSlider
            name={'capacity'}
            id={`${id}.capacity`}
            className={css.rangeSliderInput}
            min={min}
            max={max}
            step={step}
            handles={handles}
            initialValues={handles}
            onChange={e => this.selectOption(e)}
          />
        </div>
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={e => handleSubmit(null)}
        initialValues={handles}
        {...rest}
      >
        <div className={css.formWrapper}>
          <div className={css.rangeLabelsWrapper}>
            Seats{' '}
            <div
              className={css.rangeLabels}
              id={`minCap`}
              name="minCap"
              component="input"
              type="number"
              placeholder={min}
            >
              {handles[0]}
            </div>
            -
            <div
              className={css.rangeLabels}
              id={`maxCap`}
              name="maxPrice"
              component="input"
              type="number"
              placeholder={max}
              min={min}
              max={max}
              step={step}
            >
              {handles[1]}
            </div>
          </div>
          <div className={css.sliderWrapper} onMouseUp={e => handleSubmit(this.state.handles)}>
            <RangeSlider
              name={'capacity'}
              id={`${id}.capacity`}
              className={css.rangeSliderInput}
              min={min}
              max={max}
              step={step}
              handles={handles}
              initialValues={handles}
              onChange={e => {
                this.selectOption(e);
              }}
              onMouseUp={e => handleSubmit(this.state.handles)}
            />
          </div>
        </div>
      </FilterPlain>
    );
  }
}

SelectRangeFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectRangeFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SelectRangeFilter);
