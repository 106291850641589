import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import EditIcon from './EditIcon';
import css from './ModalMissingInformation.css';

export const ActionBarMaybe = props => {

  return (
    <div className={css.actionBar}>
      <p className={css.mobileOwnListingText}>
        <FormattedMessage id="ModalMissingInformation.mobileMissingStripeAccountText" />
      </p>
      <p className={css.ownListingText}>
        <FormattedMessage id="ModalMissingInformation.missingStripeAccountText" />
      </p>
      <NamedLink className={css.editListingLink} name="StripePayoutPage">
        <EditIcon className={css.editIcon} />
        <p className={css.mobileOwnListingText}>
         Add
        </p>
        <p className={css.ownListingText}>
          Add Payment Details
        </p>
      </NamedLink>
    </div>
  );
};

ActionBarMaybe.propTypes = {
};

ActionBarMaybe.displayName = 'ActionBarMaybe';

export default ActionBarMaybe;
