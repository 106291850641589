import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {NamedLink} from '../../components'
import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: December 29, 2020</p>
      <p>Welcome to TableSnatch!</p>
      <p>
        We are primarily a peer-to-peer marketplace service where <em>bona fide</em> holders of
        reservations can post their reservations for sale, and reservation seekers can buy those
        reservations. Ours is a new and unique marketplace designed to assist the restaurant
        industry in reducing &ldquo;no-shows&rdquo; of holders of reservations. Our marketplace
        recognizes value in reservations &ndash; both as to restaurants and diners alike. We are
        glad you are on our site and welcome you!
      </p>
      <p>
        Below are details of our Terms of Use. By agreeing to these terms, all users are certifying
        the authority and full force and effect of the rules. Any known violation of these terms can
        result in termination of services or obligations on the part of TableSnatch, LLC
        (TableSnatch) or The Reservation Exchange, LLC.
      </p>
      <p>
        <u>
          <strong>Intellectual Property</strong>
        </u>
      </p>
      <p>
        The name TableSnatch, its logo, certain terminology, and its business method are the subject
        of intellectual property, patents pending, and certain registrations which are proprietary
        and the sole property of The Reservation Exchange, LLC.
      </p>
      <p>
        <u>
          <strong>Property Rights</strong>
        </u>
      </p>
      <p>
        TableSnatch and The Reservation Exchange, LLC, are the sole owners of or otherwise licensed
        to use all parts and services of the site including all copy, software, graphics, designs
        and all copyrights, trademarks, service marks, tradenames, logos, patents and patent
        applications and other intellectual property and proprietary rights therein. Some materials
        on the site may belong to third parties. By using the site, you agree not to copy,
        distribute, modify or make derivative works of any such materials. All rights not
        specifically granted by these Terms of Use belong solely to TableSnatch and The Reservation
        Exchange, LLC.
      </p>
      <p>
        <u>
          <strong>Eligibility</strong>
        </u>
      </p>
      <p>
        All users of TableSnatch must be 18 years old or older, and acceptance of these Terms of Use
        is affirmative certification by all such users that he or she is 18 years old or older.
      </p>
      <p>
        <u>
          <strong>Code of Conduct</strong>
        </u>
      </p>
      <p>
        TableSnatch involves active participation of its users. All users must adhere to a standard
        of decorum and behavior demonstrating respect for others and clean language. This code of
        conduct in the use of the site applies to all postings, purchases, reviews or other
        communications, whether written, verbal or by use of symbols, and binds users to commonly
        acceptable parameters of decency and propriety. All users are required and expected to
        conduct themselves in good faith.
      </p>
      <p>
        <u>
          <strong>Content and Ownership of Content</strong>
        </u>
      </p>
      <p>
        All content of TableSnatch and this site is owned solely by The Reservation Exchange, LLC,
        and as defined by these Terms of Use and the{' '}
        <u>
          <NamedLink name="PrivacyPolicyPage">Privacy Policy</NamedLink>
        </u>
        .
      </p>
      <p>
        <u>
          <strong>Grant of Conditional License</strong>
        </u>
      </p>
      <p>
        This site and all data, text, designs, pages, print screens, images, artwork, photographs,
        audio and video clips, and HTML code, source code, or software that reside or are viewable
        or otherwise discoverable on the Site, and all tickets obtained from the site,
        (collectively, the "Content") are owned by us or our licensors. We own a copyright and, in
        many instances, patents and other intellectual property in the site and Content. We may
        change the Content and features of the site at any time.
      </p>
      <p>
        We grant you a limited, conditional, no-cost, non-exclusive, non-transferable,
        non-sub-licensable license to view this site and its Content as permitted by these Terms of
        Use for non-commercial purposes only if, as a condition precedent, you agree that you will
        not:
      </p>
      <p>
        Submit any software or other materials that contain any viruses, worms, Trojan horses,
        defects, date bombs, time bombs or other items of a destructive nature;
      </p>
      <p>
        Manipulate identifiers, including by forging headers, in order to disguise the origin of any
        posting that you submit;
      </p>
      <p>
        Link to any portion of the site other than the URL assigned to the home page of the Site;
      </p>
      <p>"Frame" or "mirror" any part of the site;</p>
      <p>
        Modify, adapt, sub-license, translate, sell, reverse engineer, decompile or disassemble any
        portion of the site, or otherwise attempt to derive any source code or underlying ideas or
        algorithms of any part of the Content;
      </p>
      <p>
        Remove any copyright, trademark or other proprietary rights notices contained on the site;
      </p>
      <p>
        Use any computer program, bot, robot, spider, offline reader, site search/retrieval
        application or other manual or automatic device, tool, or process to retrieve, index, data
        mine, or in any way reproduce or circumvent the security structure, navigational structure,
        or presentation of the Content or the site, including with respect to any CAPTCHA displayed
        on the site. Operators of public search engines may use spiders to copy materials from the
        site for the sole purpose of and solely to the extent necessary for creating publicly
        available searchable indices of the materials, but not caches or archives of such materials.
        We may revoke this exception at any time and require removal of archived materials gathered
        in the past;
      </p>
      <p>
        Use any automated software or computer system to search for, reserve, buy or otherwise
        obtain reservations, promotional codes, vouchers, gift cards or any other items available on
        the site, including sending information from your computer to another computer where such
        software or system is active;
      </p>
      <p>
        Take any action that imposes or may impose (in our sole discretion) an unreasonable or
        disproportionately large load on our infrastructure;
      </p>
      <p>
        Access, reload, or refresh transactional event or offer pages, or make any other request to
        transactional servers, more than once during any three-second interval;
      </p>
      <p>
        Reproduce, modify, display, publicly perform, distribute or create derivative works of the
        site or the Content;
      </p>
      <p>
        Reproduce or scan tickets in a format or medium different from that provided by the site;
      </p>
      <p>
        Decode, decrypt, modify, or reverse engineer any offer or reservation or underlying
        algorithms or barcodes used on or in production of any offer or reservation or the site;
      </p>
      <p>
        Use the site or the Content in an attempt to, or in conjunction with, any device, program or
        service designed to circumvent any technological measure that effectively controls access
        to, or the rights in, the site and/or Content in any way including, without limitation, by
        manual or automatic device or process, for any purpose;
      </p>
      <p>
        Use bot technology to search for, reserve, or purchase reservations through the site; for
        the avoidance of doubt, this specifically prohibits you from using automated ticket
        purchasing software on the site, and prohibits you from circumventing any security measure,
        access control system, or other technological control or measure on the site that is used to
        enforce posted event ticket purchasing limits or to maintain the integrity of posted online
        ticket purchasing order rules.
      </p>
      <p>
        This license is expressly conditioned on your preexisting agreement to comply with, and your
        actual compliance with, each of the provisions described in this Ownership of Content and
        Grant of Conditional License section. This license exists only so long as you strictly
        comply with each of the provisions described in this section. Any use of the site or Content
        by you or anyone acting on your behalf that does not strictly comply with each and every
        provision in this section exceeds the scope of the license granted to you herein,
        constitutes unauthorized reproduction, display, or creation of unauthorized derivative
        versions of the site and Content, and infringes our copyrights, trademarks, patents and
        other rights in the site and Content. You will not acquire any ownership rights by using the
        site or the Content.
      </p>
      <p>
        The registered and unregistered trademarks, logos, and service marks displayed on the site
        are owned by us or our licensors. You may not use our trademarks, logos, and service marks
        in any way without our prior written permission.
      </p>
      <p>
        <u>
          <strong>Account Registration</strong>
        </u>
      </p>
      <p>
        In order to post a reservation for sale on this site or to purchase a reservation for sale,
        you must register for an account by providing your full name, your active and principal
        email address, your mobile telephone number, a password, and credit card or like and
        acceptable payment information sufficient to pay any posting fee or purchase fee, as well as
        to receive purchase revenue from a buyer of your reservation or any applicable refund from
        the site. All this information must be current, accurate, and provided in good faith with
        the intention of participating in the services of this site in accordance with these Terms
        of Use and our{' '}
        <u>
          <NamedLink name="PrivacyPolicyPage">Privacy Policy</NamedLink>
        </u>
        .
      </p>
      <p>
        You shall not operate under a username or other account information which is intended to
        convey or impersonate another user or is offensive, vulgar or obscene.
      </p>
      <p>
        You are solely responsible for the activity which occurs on your account and for keeping
        your account password secure.
      </p>
      <p>
        You may not use another person&rsquo;s account or registration information without express
        permission of that person.
      </p>
      <p>
        You must notify TableSnatch immediately of any change in eligibility to use the site, breach
        of security or unauthorized use of your account.
      </p>
      <p>You should never publish, distribute or post login information for your account.</p>
      <p>
        Please see our{' '}
        <u>
          <NamedLink name="PrivacyPolicyPage">Privacy Policy</NamedLink>
        </u>{' '}
        for more information on how we use and protect your personal information.
      </p>
      <p>
        <u>
          <strong>Posting and Selling a Reservation</strong>
        </u>
      </p>
      <p>
        Subject to the Eligibility requirements and all other requirements for use of the site, you
        may post a reservation for sale on the site by 1) setting up an account per Account
        Registration guidelines and the prompts and fields provided in that process, 2) successfully
        paying the posting fee as proscribed by the prompts and fields provided in that process, and
        3) agreeing to our Terms of Use and{' '}
        <u>
          <NamedLink name="PrivacyPolicyPage">Privacy Policy</NamedLink>
        </u>
        .
      </p>
      <p>
        By posting a reservation for sale, you are verifying that you are the valid and authentic
        holder of a reservation which can be claimed by you at the restaurant or establishment you
        identify in the post for sale. You also verify that you are legally responsible for the
        legitimacy of the reservation, and that any buyer of your reservation can rely on the
        reservation as valid and authentic as presented.
      </p>
      <p>
        Your post for sale will not include your name. The offer for sale will only include the name
        and location of the restaurant, the day and date of the reservation, the time of the
        reservation, the number of seats of the reservation, and the purchase price to a buyer.
      </p>
      <p>
        A transaction fee charged to the buyer will be added to the purchase price presented in the
        offer.
      </p>
      <p>
        Once your reservation is purchased, you will receive notice of the transaction, and the
        details of your reservation will be provided to the buyer for their use at the restaurant.
        For example, details may include the name under which the reservation can be claimed or
        confirmation number, email, text, or other method of identifying the valid and authentic
        holder of the reservation.
      </p>
      <p>
        <u>
          <strong>Buying a Reservation</strong>
        </u>
      </p>
      <p>
        A buyer of any reservation offered on the site must register for an account. The purchase
        price of reservations offered for sale includes a transaction fee which is automatically
        added to the offer price on the site. A buyer selects a reservation for purchase, completes
        the transaction, receives confirmation of the transaction, and is connected to the seller
        through our Snatch &rsquo;n Match! notification where complete details of the reservation
        are delivered to the buyer.
      </p>
      <p>
        When you purchase a reservation, you agree to honor the reservation by arriving on time and
        paying for a meal. When circumstances change and you are unable to honor a reservation, you
        agree to contact the restaurant as soon as possible to cancel the reservation under the name
        in which the reservation is held.
      </p>
      <p>
        Buyers of reservations are expected to arrive in a timely manner and within a reasonable
        amount of time to honor the reservation at the appointed time.
      </p>
      <p>
        Buyers of reservations are solely responsible for honoring reservations and notifying
        restaurants of any cancellations or material changes. TableSnatch is not responsible for any
        reservation purchased, nor is TableSnatch responsible for buyers honoring any reservation
        purchased as would be appropriate in the restaurant industry.
      </p>
      <p>
        As a per-to-peer platform, TableSnatch does not represent or warrant any reservation offered
        for sale. TableSnatch does not represent the restaurants for which sellers on the site offer
        reservations. TableSnatch does not warrant any activity or failure on the part of any
        restaurant in connection with any reservation offered or sold on the site by any seller.
      </p>
      <p>
        <u>
          <strong>Transaction Obligations</strong>
        </u>
      </p>
      <p>
        All transactions are processed through a third-party payment processing service on the site.
        The terms of your payment will be based on and determined by agreements between you and the
        financial institution, credit card issuer, or other provider of your chosen payment method.
        If we, through the third-party payment processor, do not receive payment from you, you agree
        to pay all amounts due on your account on demand.
      </p>
      <p>
        <u>
          <strong>Fees and Collected Revenue</strong>
        </u>
      </p>
      <p>
        TableSnatch charges a posting fee at the time a reservation is posted for sale. That posting
        fee is shown to buyers at the time the post is made, and acknowledgement of understanding
        and agreement to that fee on the part of the seller is required before any posting can be
        made.
      </p>
      <p>
        TableSnatch also adds a transaction fee to the total purchase price of the reservation
        offered for sale. This transaction cost is not a separate transaction and is automatically
        added to the offer price on the site. All buyers are made aware of this fee, and
        acknowledgment of understanding and agreement of this fee is on the part of the buyer is
        required before any purchase of an offered reservation can be completed.
      </p>
      <p>
        All funds, including those collected for posting and purchase (including transaction cost)
        are held in a bank account until 24 hours after the time of the reservation has passed,
        during which time any claim of wrongdoing or fraud can be made to TableSnatch and in
        accordance to the terms of the <u>Claim Protocol</u> provided herein.
      </p>
      <p>
        Once the claim period ends, and no claim is made, the buyer will receive the principal sale
        amount of the reservation in the account provided by seller.
      </p>
      <p>
        In the event any refund for wrongdoing or fraud as determined by TableSnatch is to be made,
        electronic notice of the determination will be provided to the parties, and funds will be
        electronically returned to the aggrieved party within five business days of the
        determination for refund.
      </p>
      <p>
        <u>
          <strong>User Fraud</strong>
        </u>
      </p>
      <p>
        Any person engaging in fraudulent activity on the site is subject to being precluded from
        any further use of the site. Any fraudulent act on the part of a user subjects the user to
        any criminal penalties proscribed by applicable law or civil remedies available to any
        injured or aggrieved party. TableSnatch and its affiliates and ownership expressly deny any
        involvement or participation of any fraudulent act undertaken by any user, and all users
        agree to indemnify and hold harmless TableSnatch and its affiliates and ownership in any
        claim of fraud resulting from any user&rsquo;s activities on the site.
      </p>
      <p>
        User fraud includes, but is not limited to, a seller posting a valid or authentic
        reservation for sale which he or she does not have; a reservation which is not valid,
        authentic, or does not exist inasmuch as the subject restaurant does not take reservations
        on the date and time offered by the seller; or the details f the offered reservation such as
        number of seats for time of the reservation is not accurate and is misleading. User fraud
        also includes any attempt by a user, whether buyer or seller, to make payments in a manner
        which is not legitimate or under the legal control of the person attempting to make the
        payment. Any intentional attempt to provide misleading or material information also
        constitutes fraud.
      </p>
      <p>
        Immaterial errors are not fraudulent. Such errors in the use of the site are those which are
        not material to the offer or use of the reservation for sale; those which do not encumber
        the buyer from use of the reservation; or any error resulting from mistake or unpreventable
        activity on the site.
      </p>
      <p>
        <u>
          <strong>Claim Protocol</strong>
        </u>
      </p>
      <p>
        Any user believing any offer for sale was made in error or by fraud must make a claim within
        24 hours of the time of the reservation purchased to{' '}
        <u>
          <a href="mailto:TablesSnatchReso@gmail.com">TablesSnatchReso@gmail.com</a>
        </u>{' '}
        and include a full description of the claim, the purchase confirmation, and the restaurant
        for which the reservation was purchased. Failure to provide necessary details of your
        purchase, the restaurant in and contact information regarding the restaurant for which the
        reservation was purchased will result in a denial of the claim.
      </p>
      <p>
        <u>
          <strong>Forums and User Content</strong>
        </u>
      </p>
      <p>
        <span lang="en">
          <strong>Comments or materials posted by you.</strong>
        </span>
        <span lang="en">
          &nbsp;Certain pages on the site may allow you to post text comments, photos, videos or
          other content (&ldquo;User Content&rdquo;). Unless otherwise specified, you may only post
          User Content to the site if you are a resident of the United States and are eighteen (18)
          years of age or older. You may only post User Content that you created or which the owner
          of the User Content has given you permission to post. If User Content depicts any person
          other than yourself, you must have permission from that person or, if that person is a
          minor, permission from that person&rsquo;s parent or legal guardian, before you post the
          User Content. You may be required to provide proof of such permission to{' '}
        </span>
        TableSnatch and The Reservation Exchange, LLC
        <span lang="en">
          . You may not post or distribute User Content that is illegal or that violates these{' '}
        </span>
        <span lang="en">Terms of Use</span>
        <span lang="en">
          . By posting or distributing User Content to the site, you represent and warrant that (a)
          you own all the rights to the User Content or are authorized to use and distribute the
          User Content to the site and (b) the User Content does not and will not infringe any
          copyright, right of publicity, or any other third-party right nor violate any law or
          regulation.
          <br />
          <br /> By submitting or posting User Content to the site, you grant{' '}
        </span>
        TableSnatch and The Reservation Exchange, LLC,{' '}
        <span lang="en">
          {' '}
          the irrevocable, perpetual, worldwide right to reproduce, display, perform, distribute,
          adapt, and promote this User Content in any medium. Once you submit or post User Content
          to the site,{' '}
        </span>
        TableSnatch and The Reservation Exchange, LLC,
        <span lang="en">
          {' '}
          do not need to give you any further right to inspect or approve uses of such User Content
          or to compensate you for any such uses.
        </span>{' '}
        TableSnatch and The Reservation Exchange, LLC,
        <span lang="en">
          {' '}
          own all right, title, and interest in any compilation, collective work, or other
          derivative work created by{' '}
        </span>
        TableSnatch and The Reservation Exchange, LLC,{' '}
        <span lang="en">
          using or incorporating User Content posted to the site. You are solely responsible for
          anything you may post on the site and the consequences of posting anything on the site.
        </span>
      </p>
      <p>
        <span lang="en">
          <strong>Comments or materials posted by others.</strong>
        </span>
        <span lang="en">&nbsp;Under no circumstances will </span>TableSnatch and The Reservation
        Exchange, LLC,{' '}
        <span lang="en">
          be liable in any way for any content or materials of any third parties, including, but not
          limited to, for any errors or omissions in any content, or for any loss or damage of any
          kind incurred as a result of the use of any such content. You acknowledge that{' '}
        </span>
        TableSnatch and The Reservation Exchange, LLC,{' '}
        <span lang="en">do not pre-screen content, but that </span>TableSnatch and The Reservation
        Exchange, LLC,{' '}
        <span lang="en">
          and its designees will have the right (but not the obligation) in their sole discretion to
          refuse or remove any content that is available via the site. Without limiting the
          foregoing,{' '}
        </span>
        TableSnatch and The Reservation Exchange, LLC,{' '}
        <span lang="en">
          and its designees will have the right to remove any content, including without limitation
          User Content, that violates these{' '}
        </span>
        <span lang="en">Terms of Use</span>
        <span lang="en"> or is deemed by </span>TableSnatch and The Reservation Exchange, LLC
        <span lang="en">
          , in their sole discretion, to be otherwise objectionable.
          <br />
          <br /> We do not endorse, support, represent or guarantee the completeness, truthfulness,
          accuracy, or reliability of any content or communications posted on the site or endorse
          any opinions expressed therein. You understand that by using the site, you may be exposed
          to content that might be offensive, harmful, inaccurate or otherwise inappropriate, or in
          some cases, postings that have been mislabeled or are otherwise deceptive. Under no
          circumstances will{' '}
        </span>
        TableSnatch and The Reservation Exchange, LLC,{' '}
        <span lang="en">
          be liable in any way for any content, including, but not limited to, any errors or
          omissions in any content, or any loss or damage of any kind incurred as a result of the
          use of any content posted, emailed, transmitted or otherwise made available on the site or
          broadcast elsewhere.
        </span>
      </p>
      <p>
        <strong>Links.</strong> The Site contains links to other websites that may not be owned or
        operated by us. The fact that we may link to those websites does not indicate any approval
        or endorsement of those websites. We have no control over those websites. We are not
        responsible for the content of those websites, or the privacy practices of those websites.
        We strongly encourage you to become familiar with the terms of use and practices of any
        linked website. Your use of other websites is at your own risk, and is subject to the terms
        of those websites. It is up to you to take precautions to ensure that whatever links you
        select or software you download (whether from the Site or other sites) is free of viruses,
        worms, Trojan horses, defects, date bombs, time bombs, and other items of a destructive
        nature.
      </p>
      <p>
        <span lang="en">
          <u>
            <strong>Digital Millennium Copyright Act (DMCA) Notice</strong>
          </u>
        </span>
      </p>
      <p>
        <span lang="en">
          Materials may be made available via the Services by users and other third parties not
          within our control. We are under no obligation to, and do not, scan content posted on the
          site or service for the inclusion of illegal or impermissible content. However, we respect
          the copyright interests of others. It is our policy not to permit materials known by us to
          infringe another party&rsquo;s copyright to remain on the site.
        </span>
      </p>
      <p>
        <span lang="en">
          If you believe any materials on the site or service infringe a copyright, you should
          provide us with written notice that at a minimum contains:
        </span>
      </p>
      <p>
        <span lang="en">
          (i) A physical or electronic signature of a person authorized to act on behalf of the
          owner of an exclusive right that is allegedly infringed;
          <br /> (ii) Identification of the copyrighted work claimed to have been infringed, or, if
          multiple copyrighted works at a single online website are covered by a single
          notification, a representative list of such works at that website;
          <br /> (iii) Identification of the material that is claimed to be infringing or to be the
          subject of infringing activity and that is to be removed or access to which is to be
          disabled, and information reasonably sufficient to permit us to locate the material;
          <br /> (iv) Information reasonably sufficient to permit us to contact the complaining
          party, such as an address, telephone number, and, if available, an electronic mail address
          at which the complaining party may be contacted;
          <br /> (v) A statement that the complaining party has a good faith belief that use of the
          material in the manner complained of is not authorized by the copyright owner, its agent,
          or the law; and
          <br /> (vi) A statement that the information in the notification is accurate, and under
          penalty of perjury, that the complaining party is authorized to act on behalf of the owner
          of an exclusive right that is allegedly infringed.
        </span>
      </p>
      <p>
        <span lang="en">All DMCA notices should be sent to our designated agent at </span>
        <u>
          <a href="mailto:TableSnatch@gmail.com">
            <span lang="en">TableSnatch@gmail.com</span>
          </a>
        </u>
        <span lang="en">.</span>
      </p>
      <p>
        <u>
          <strong>Notices</strong>
        </u>
      </p>
      <p>
        We may make changes to these Terms of Use at any time. Any changes we make will be effective
        immediately when we post a revised version of these Terms of Use on the site. Your use of
        this site is your agreement to its Terms of Use and any changes from prior Terms of Use
        content.
      </p>
      <p>
        <u>
          <strong>Taxes</strong>
        </u>
      </p>
      <p>
        Any user paid revenue from this site is solely responsible for any taxes owed, regardless of
        to whom any such tax is owed, and all users agree to this responsibility and all other
        provisions contained in these Terms of Use.
      </p>
      <p>
        <u>
          <strong>Governing Law</strong>
        </u>
      </p>
      <p>
        The laws of the State of Georgia are those which govern the Terms of Use and to which this
        site and these Terms of Use apply and under which they operate.
      </p>
      <p>
        <u>
          <strong>Waiver of Litigation and Agreement to Arbitrate</strong>
        </u>
      </p>
      <p>
        <span lang="en">
          While we will make reasonable efforts to resolve any disagreements you may have with
          TableSnatch and The Reservation Exchange, LLC, if these efforts fail you agree that all
          claims, disputes or controversies against TableSnatch and The Reservation Exchange, LLC,
          arising out of these Terms of Use, or the purchase of any products or services ("Claims")
          are subject to fixed and binding arbitration, no matter what legal theory they are based
          on or what remedy (damages, or injunctive or declaratory relief) they seek. This includes
          Claims based on contract, tort (including intentional tort), fraud, agency, your or our
          negligence, statutory or regulatory provisions, or any other sources of law; Claims made
          as counterclaims, cross-claims, third- party claims, interpleaders or otherwise; and
          Claims made independently or with other claims. The party filing for arbitration must
          submit Claims to the American Arbitration Association and follow its rules and procedures
          for initiating and pursuing an arbitration. Any arbitration hearing that you attend will
          be held at a place chosen by the American Arbitration Association in the same city as the
          U.S. District Court closest to your then current residential address, or at some other
          place to which you and Company agree in writing, and the arbitrator shall apply Georgia
          law consistent with the Federal Arbitration Act. You shall not be entitled to join or
          consolidate Claims in arbitration by or against other users or to arbitrate any Claim as a
          representative or member of a class or in a private attorney general capacity. You may
          obtain copies of the current rules, and forms and instructions for initiating an
          arbitration by contacting the American Arbitration Association by using the contact
          information noted below.
        </span>
      </p>
      <p>
        <span lang="en">
          American Arbitration Association
          <br /> 800-778-7879 (toll-free)
          <br /> Website:{' '}
        </span>
        <u>
          <a href="http://www.adr.org/">
            <span lang="en">www.adr.org</span>
          </a>
        </u>
      </p>
      <p>
        <span lang="en">
          A single, neutral arbitrator will resolve Claims. The arbitrator will be either a lawyer
          with at least ten (10) years&rsquo; experience or a retired or former judge, selected in
          accordance with the rules of the American Arbitration Association. The arbitration will
          follow the procedures and rules of the American Arbitration Association which are in
          effect on the date the arbitration is filed unless those procedures and rules are
          inconsistent with these Terms and Conditions, in which case these Terms and Conditions
          will prevail. Those procedures and rules may limit the discovery available to you or us.
          The arbitrator will take reasonable steps to protect your customer account information and
          other confidential information if requested to do so by you or us. Each party to the
          arbitration will bear the expense of that party's attorneys, experts, and witnesses, and
          other expenses, regardless of which party prevails, but a party may recover any or all
          expenses from another party if the arbitrator, applying applicable law, so determines. The
          arbitrator's award is final and binding on the parties unless we or you appeal it in
          writing to the arbitration firm within fifteen (15) days of notice of the award. The
          appeal must request a new arbitration before a panel of three neutral arbitrators
          designated by the American Arbitration Association. An award by a panel is final and
          binding on the parties after fifteen (15) days has passed. Any Claim you have must be
          commenced within one (1) year after the date the Claim arises. As noted above, you and
          Company hereby voluntarily and knowingly waive any right either may have to a jury trial.
        </span>
      </p>
      <p>
        <u>
          <strong>Class Action Waiver</strong>
        </u>
      </p>
      <p>
        <span lang="en">
          YOU AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND TableSnatch AND THE RESERVSATION
          EXCHANGE COMPANY, LLC, ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN
          A CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER
          REPRESENTATIVE PROCEEDING OF ANY KIND. CLAIMS AND REMEDIES SOUGHT AS PART OF A CLASS
          ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE ACTION ARE SUBJECT TO
          ARBITRATION ONLY ON AN INDIVIDUAL (NON-CLASS, NON-REPRESENTATIVE) BASIS, AND THE
          ARBITRATOR MAY AWARD RELIEF ONLY ON AN INDIVIDUAL (NON-CLASS, NON-REPRESENTATIVE) BASIS.
        </span>
      </p>
      <p>
        <u>
          <strong>Disclaimer of Warranties</strong>
        </u>
      </p>
      <p>
        <span lang="en">
          WHILE TableSnatch AND THE RESERVATION EXCHANGE, LLC, USE REASONABLE EFFORTS TO INCLUDE
          UP-TO-DATE INFORMATION ON THIS SITE, EXCEPT FOR THE EXPRESS WARRANTIES STATED ON OUR
          WEBSITE, TableSnatch AND THE RESERVATION EXCHANGE, LLC, MAKE NO WARRANTIES OR
          REPRESENTATIONS AS TO THE ACCURACY OR COMPLETENESS OF INFORMATION ON THE SITE. TO THE
          FULLEST EXTENT PERMITTED BY LAW, YOUR USE OF THE SITE IS AT YOUR OWN RISK. THE SITE,
          INCLUDING ALL CONTENT MADE AVAILABLE ON OR ACCESSED THROUGH THE SITE, IS PROVIDED "AS IS"
          AND TableSnatch AND THE RESERVATION EXCHANGE, LLC, MAKE NO REPRESENTATIONS OR WARRANTIES
          OF ANY KIND WHATSOEVER FOR THE CONTENT ON THE SITE. FURTHER, TO THE FULLEST EXTENT
          PERMISSIBLE BY LAW, TableSnatch AND THE RESERVATION EXCHANGE, LLC, DISCLAIM ANY EXPRESS OR
          IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, NON-INFRINGEMENT, TITLE,
          MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. TableSnatch AND THE RESERVATION
          EXCHANGE, LLC, DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE SITE OR ANY MATERIALS OR
          CONTENT CONTAINED THEREIN WILL BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE
          CORRECTED, OR THAT THE SITE OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS. TO THE FULLEST EXTENT PERMITTED BY LAW, TableSnatch AND THE
          RESERVATION EXCHANGE, LLC, SHALL NOT BE LIABLE FOR THE USE OF THE SITE, INCLUDING, WITHOUT
          LIMITATION, THE CONTENT AND ANY ERRORS CONTAINED THEREIN.
        </span>
      </p>
      <p>
        <span lang="en">
          IN NO EVENT WILL TableSnatch OR THE RESERVATION EXCHANGE, LLC, BE LIABLE UNDER ANY THEORY
          OF TORT, CONTRACT, STRICT LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY FOR ANY LOST
          PROFITS, LOST DATA, LOST OPPORTUNITIES, COSTS OF COVER, EXEMPLARY, PUNITIVE, PERSONAL
          INJURY/WRONGFUL DEATH, SPECIAL, INCIDENTAL, INDIRECT OR OTHER CONSEQUENTIAL DAMAGES.
          FURTHER, TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL COMPANY BE LIABLE FOR
          ANY DIRECT DAMAGES EXCEEDING THE GREATER OF THE FEES YOU PAID TO TableSnatch AND THE
          RESERVATION EXCHANGE, LLC, FOR PRODUCTS AND SERVICES.
        </span>
      </p>
      <p>
        <span lang="en">
          IN THIS CONNECTION, NEITHER TableSnatch NOR THE RESERVATION EXCHANGE ARE LIABLE FOR ANY
          ACTIVITY, EVENT, ACT OF GOD, CRIMINAL ACT OR SITUATION GIVING RISE TO ANY INJURY TO ANY
          USER OF ANY KIND WHICH TAKES PLACE AT ANY RESTAURANT AT WHICH A USER IS HONORING A
          RESERVATION PURCHASED ON THIS SITE AS ANY SUCH EVENT IS NOT IN THE ANTICIPATION OR CONTROL
          OF ANY PERSON OR ENTITY ASSOCIATED WITH TableSnatch OR THE RESERVATIONEXCHANGE, LLC, AND
          WHICH EVENT IS ACKNOWLEDGED BY USER TO BE OUT OF THE CONTROL OR SCOPE OF SERVICES PROVIDED
          BY TableSnatch AND THE RESERVATION EXCHANGE, LLC. ALSO IN THIS CONNECTION, ALL USERS
          ACKNOWLEDGE AND AGREE THAT HE OR SHE ENGAGED THE SIGHT FREELY AND VOLUNTARILY FOR PURPOSES
          OF REVIEWING RESTAURANT RESVATIONS FOR SALE BY THIRD PARTIES NOT ASSCOIATED WITH
          TEABLESNATCH OR THE RESERVATIONS EXCHANGE, LLC, AND THAT ALL USERS WHO PURCHASE A
          RESVATION ON THE SITE WERE IN THE MARKET OF OBTAINING A RESERVATION FREELY AND
          VOLUYNTARILY WHICH RESULTED IN THE USER PURCHASING A RESERVATOINO ON THE SITE SOLELY AT
          THE USER&rsquo;S DISCRETION.
        </span>
      </p>
      <p>
        <u>
          <strong>Limitation of Liability</strong>
        </u>
      </p>
      <p>
        IN NO EVENT WILL WE OR OUR ADVERTISERS AND SPONSORS, BE RESPONSIBLE OR LIABLE TO YOU OR
        ANYONE ELSE FOR, AND YOU HEREBY KNOWINGLY AND EXPRESSLY WAIVE ALL RIGHTS TO SEEK, DIRECT,
        INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OF ANY TYPE, AND ANY RIGHTS TO HAVE
        DAMAGES MULTIPLIED OR OTHERWISE INCREASED, ARISING OUT OF OR IN CONNECTION WITH THE SITE,
        THE CONTENT, OR ANY PRODUCT OR SERVICE PURCHASED THROUGH THE SITE, EVEN IF WE HAVE BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF WHETHER THE CLAIM IS BASED
        UPON ANY CONTRACT, TORT, OR OTHER LEGAL OR EQUITABLE THEORY. WITHOUT LIMITING THE FOREGOING,
        YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT WE WILL HAVE NO LIABILITY OR RESPONSIBILITY
        WHATSOEVER FOR (a) ANY FAILURE OF ANOTHER USER OF THE SITE TO CONFORM TO THE CODES OF
        CONDUCT, (b) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, WHETHER ARISING
        IN CONTRACT OR IN TORT, RESULTING FROM YOUR ACCESS TO AND USE OF OUR SITE, (c) ANY
        UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
        AND/OR FINANCIAL INFORMATION STORED THEREIN, (d) ANY BUGS, VIRUSES, WORMS, TROJAN HORSES,
        DEFECTS, DATE BOMBS, TIME BOMBS OR OTHER ITEMS OF A DESTRUCTIVE NATURE WHICH MAY BE
        TRANSMITTED TO OR THROUGH OUR SITE, (e) ANY ERRORS, MISTAKES, INACCURACIES OR OMISSIONS IN
        ANY CONTENT, OR THE FAILURE OF A VENUE TO HONOR A RESERVATION. OUR SOLE AND EXCLUSIVE REMEDY
        FOR DISSATISFACTION WITH THE SITE IS TO STOP USING THE SITE. THE LIMITATIONS IN THIS SECTION
        WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE. THE ALLOCATION OF RISK
        BETWEEN US IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN US. OUR AGGREGATE
        LIABILITY ARISING OUT OF THESE TERMS OR THE USE OF THE SITE WILL NOT EXCEED THE GREATER OF
        ONE HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID US IN THE PAST TWELVE MONTHS. IN NO
        EVENT WILL ATTORNEYS' FEES BE AWARDED OR RECOVERABLE. OUR LIABILITY WILL BE LIMITED UNDER
        THIS PARAGRAPH TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, AND THE PROVISIONS OF THIS
        PARAGRAPH WILL NOT APPLY TO THE EXTENT APPLICABLE LAW PERMITS THE RECOVERY OF DAMAGES,
        ATTORNEYS' FEES OR COSTS OTHERWISE PROHIBITED UNDER THIS PARAGRAPH. THE PROVISIONS OF THIS
        PARAGRAPH THAT (A) PROHIBIT DAMAGES TO BE MULTIPLIED OR OTHERWISE INCREASED, (B) IMPOSE A
        DAMAGES LIMITATION OF THE GREATER OF ONE HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID
        US IN THE PAST TWELVE MONTHS, AND (C) PROHIBIT THE RECOVERY OF ATTORNEYS' FEES AND COSTS, DO
        NOT APPLY IN CERTAIN STATES, INCLUDING WITHOUT LIMITATION NEW JERSEY, TO CLAIMS BROUGHT
        UNDER STATUTES PERMITTING SUCH RECOVERY.
      </p>
      <p>
        <u>
          <strong>Severability</strong>
        </u>
      </p>
      <p>
        <span lang="en">
          If any part of these Terms of Use shall be held or declared to be invalid or unenforceable
          for any reason by any court of competent jurisdiction, such provision shall be ineffective
          but shall not affect any other part of these Terms of Use, and in such event, such
          provision shall be changed and interpreted so as to best accomplish the objectives of such
          unenforceable or invalid provision within the limits of applicable law or applicable court
          decisions.
        </span>
      </p>
      <p>
        <u>
          <strong>Indemnification</strong>
        </u>
      </p>
      <p>
        <span lang="en">
          You agree to indemnify, defend and hold harmless TableSnatch and The Reservation Exchange,
          LLC, their employees, directors, officers, agents, business partners, affiliates,
          contractors, distribution partners and representatives, and any person or entity
          associated with TableSnatch or The Reservation Exchange, LLC - perceived or otherwise -
          from and against any and all claims, demands, liabilities, costs or expenses, including
          attorney&rsquo;s fees and costs, arising from, or related to (i) your use of the Services,
          (ii) any breach by you of any of these{' '}
        </span>
        <span lang="en">Terms of Use</span>
        <span lang="en">, and (iii) any violation of applicable law.</span>
      </p>
      <p>
        <u>
          <strong>Illness Contraction and Warning</strong>
        </u>
      </p>
      <p>
        An inherent risk of exposure to illness, including COVID-19, exists in any place where
        people gather. COVID-19 is an extremely contagious disease that can lead to severe illness
        and death. You assume all risks, hazards, and dangers arising from or relating in any way to
        the risk of contracting COVID-19 or any other communicable disease or illness, or a
        bacteria, virus or other pathogen capable of causing a communicable disease or illness,
        whether occurring before, during, or after the event, gathering or seating in a restaurant
        or other establishment, however caused or contracted, and voluntarily waive all claims and
        potential claims against TableSnatch and The Reservation Exchange, LLC, relating to such
        risks.
      </p>
      <p>
        <u>
          <strong>Violation of Terms and Voided Obligations</strong>
        </u>
      </p>
      <p>
        <span lang="en">
          Any violating of any section proscribed by these Terms of Use may result in a termination
          of services by TableSnatch and The Reservation Exchange, LLC, and a prohibition of future
          use by any user in violation of these terms. Any violation of these terms will also void
          any responsibility or obligation on the part of TableSnatch and The Reservation Exchange,
          LLC, to any such user including, but not limited to any refund for payments made to
          TableSnatch and The Reservation Exchange, LLC, by such user.
        </span>
      </p>
      <p>
        <span lang="en">
          Moreover, the failure of TableSnatch and The Reservation Exchange, LLC, to partially or
          fully exercise any rights or the waiver of TableSnatch and The Reservation Exchange, LLC,
          of any breach of these Terms of Use by you shall not prevent a subsequent exercise of such
          right by TableSnatch and The Reservation Exchange, LLC, or be deemed a waiver by Company
          of any subsequent breach by you of the same or any other term of these Terms and
          Conditions. The rights and remedies of Company under these Terms and Conditions and any
          other applicable agreement between you and Company shall be cumulative, and the exercise
          of any such right or remedy shall not limit Company's right to exercise any other right or
          remedy.
        </span>
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
