import classNames from 'classnames';
import PropTypes, { func, number } from 'prop-types';
import React, { Component, useState, useEffect } from 'react';
import { IconSpinner, ResponsiveImage } from '..';
import { propTypes } from '../../util/types';
import css from './CardCarousel.css';

const Card = props => {
  const { data, className, rootClassName,  noImageMessage, image,index,  ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);
  //const [srcSet,setSrc]=React.useState(null);
  const result = [];
  result.push(data);

//   React.useEffect(()=>{
//     console.log(index+"index");
//     props.onLoad(index);
//     setSrc(require(`../../assets/${data.imageUrl}`));
//   },[index]);
  
    // const srcSet = require(`../../assets/${data.imageUrl}`);
    const srcSet = result
    .map(item => {
      if (!item) {
        // Variant not available (most like just not loaded yet)
        return null;
      }
      //setTimeout(()=>{
        return require(`../../assets/${item.imageUrl}`);
      //},5000);
     
    })
    .filter(v => v != null)
    .join(', ');
  const imgProps = {
    classes,
    srcSet,
    ...rest,
  };
  return (
    <div>
      <section className={classes}>
        <img  {...imgProps}  
        />
        <p className={css.customerFeedbackContent}>{data.desc}</p>
        <p className={css.customerDetail}>
          <span>{data.customer.name}</span>
          <span>{data.resturant.name}</span>
        </p>
      </section>
    </div>
  );
};

Card.defaultProps = {
  rootClassName: null,
  noImageMessage:null,
  className: null,
  image:null,
  index:0,
  data: [],
};

Card.propTypes = {
  rootClassName: PropTypes.string,
  noImageMessage: PropTypes.string,
  className: PropTypes.string,
  count: number,
  data: PropTypes.array,
  image:PropTypes.array
};

export default Card;
