import React from 'react';
import {
  ExternalLink,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
} from '../../components';
import config from '../../config';
import { StaticPage, TopbarContainer } from '../../containers';
import css from './AboutPage.css';
import lounge_man_stars from './images/lounge_man_stars.svg';

const AboutPage = () => {
  const {  siteFacebookPage, siteInstagramPage } = config;

  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Yogatime',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <img src={lounge_man_stars} className={css.heroContentImage} alt={'logo'} />

          {/* <img className={css.coverImage} src={image} alt="My first ice cream." /> */}
          {/* <h1 className={css.pageTitle}>Find new depths in your yoga practice</h1> */}

          <div className={css.contentWrapper}>
            {/* <div className={css.contentSide}>
              <p>Yoga was listed by UNESCO as an intangible cultural heritage.</p>
            </div> */}

            <div className={css.contentMain}>
              <h2>
                Welcome to TableSnatch! We are a peer-to-peer marketplace where you can sell a
                reservation you won’t use or Snatch! that reservation if you need it!
              </h2>

              <p>
                We make exchanging “hard to get” reservations easy! And we help our restaurant
                friends prevent no-shows.
              </p>

              <p>
                We believe reservations with popular restaurants are valuable to both the holder of
                the reservations and the restaurants. Our site allows holders to realize that value
                when they cannot honor them, and the restaurants fill the seats and avoid a no-show.
              </p>

              <p>
                Please look at our <NamedLink name={'TermsOfServicePage'}>Terms of Use</NamedLink>{' '}
                to learn more on how to use our site. Thanks for being a Snatcher!
              </p>

              <p>
                Oh, and if you are a restaurant,{' '}
                <NamedLink name={'ContactPage'}>Contact Us</NamedLink> and find out how we can help
                you even more!
              </p>

              <p>
                You can also checkout our{' '}
                <ExternalLink href={siteFacebookPage}>Facebook</ExternalLink> and{' '}
                <ExternalLink href={siteInstagramPage}>Instagram</ExternalLink>.
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
