import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { PaginationLinks, SearchResultsCard } from '../../components';
import { propTypes } from '../../util/types';
import SortIcon from '../../assets/tableSortIcon.png';
import SortIconSelection from '../../assets/tableSortSelectIcon.png';
import css from './SearchResultsPanel.css';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    handleSortBy,
    isConflictingFilterActive,
    currentSort,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;
  const [dataListing,setListing]= React.useState(listings);
  const [nameSort,setNameSort]= React.useState(0);
  const [sortIcon,setSortIcon]= React.useState({
    date : 0,
    time:0,
    name:0,
    cost:0,
    capacity:0,
    cuisine :0
  });
  const deafultIcon = {
    date : 0,
    time:0,
    name:0,
    cost:0,
    capacity:0,
    cuisine :0
  };

  React.useEffect(()=>{
    setListing(listings);
  },[props.listings])
  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  
  const handleNameSort =(e)=>{
    e.preventDefault();
   
    
    dataListing.sort(function(a, b){
        if(nameSort == 0){
          return b.attributes.title.localeCompare(a.attributes.title);
         // return  a.attributes.title < b.attributes.title;
        }else{
          return -b.attributes.title.localeCompare(a.attributes.title);
         
          // return a.attributes.title > b.attributes.title;
        }
      });
      setListing(dataListing);
  }

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        <table className={css.table}>
          <thead className={css.thead}>
            <tr className={css.tr}>
              <th className={css.th} scope="col">
                Date
                <img
                  src={sortIcon.date == 0 ? SortIcon : SortIconSelection}
                  className={css.SortIcon}
                  alt={'Sort'}
                  onClick={e => {
                    if (!isConflictingFilterActive) {
                      setSortIcon({
                        ...deafultIcon,
                        date:1
                      });
                      if (currentSort === '-pub_reservationStart') {
                        handleSortBy('sort', 'pub_reservationStart');
                      } else {
                        handleSortBy('sort', '-pub_reservationStart');
                      }
                    }
                  }}
                />
              </th>

              <th className={classNames(css.th, css.doubleCell)} scope="col">
                Name
                <img src={sortIcon.name == 0 ? SortIcon : SortIconSelection} className={css.SortIcon} alt={'Sort'} 
                onClick={e => {
                  setSortIcon({
                   ...deafultIcon,
                    name:1 
                  });
                  if (!isConflictingFilterActive) {
                    setNameSort(nameSort == 1 ? 0 :1);
                    handleNameSort(e);
                  }
                }}/>
              </th>
              <th className={css.th} scope="col">
                Time
                <img
                  src={sortIcon.time == 0 ? SortIcon : SortIconSelection}
                  className={css.SortIcon}
                  alt={'Sort'}
                  onClick={e => {
                    setSortIcon({
                      ...deafultIcon,
                      time:1 
                    });
                    if (!isConflictingFilterActive) {
                      if (currentSort === '-pub_reservationStart') {
                        handleSortBy('sort', 'pub_reservationStart');
                      } else {
                        handleSortBy('sort', '-pub_reservationStart');
                      }
                    }
                  }}
                />
              </th>
              <th className={classNames(css.th, css.hideSmall)} scope="col">
                Table Size
                <img
                  src={sortIcon.capacity == 0 ? SortIcon : SortIconSelection}
                  className={css.SortIcon}
                  alt={'Sort'}
                  onClick={e => {
                    if (!isConflictingFilterActive) {
                      setSortIcon({
                        ...deafultIcon,
                        capacity:1 
                      });
                      if (currentSort === '-pub_capacity') {
                        handleSortBy('sort', 'pub_capacity');
                      } else {
                        handleSortBy('sort', '-pub_capacity');
                      }
                    }
                  }}
                />
              </th>
              <th className={css.th} scope="col">
                {' '}
                Cost{' '}
                <img
                  src={sortIcon.cost == 0 ? SortIcon : SortIconSelection}
                  className={css.SortIcon}
                  alt={'Sort'}
                  onClick={e => {
                    setSortIcon({
                      ...deafultIcon,
                      cost:1
                    });
                    if (!isConflictingFilterActive) {
                      if (currentSort === '-price') {
                        handleSortBy('sort', 'price');
                      } else {
                        handleSortBy('sort', '-price');
                      }
                    }
                  }}
                />
              </th>

              <th className={classNames(css.th, css.hideSmall)} scope="col">
                Cuisine
                <img
                  src={sortIcon.cuisine == 0 ? SortIcon : SortIconSelection}
                  className={css.SortIcon}
                  alt={'Sort'}
                  onClick={e => {
                    if (!isConflictingFilterActive) {
                      setSortIcon({
                        ...deafultIcon,
                        cuisine:1 
                      });
                      if (currentSort == '-pub_cuisineOptions') {
                        handleSortBy('sort', 'pub_cuisineOptions');
                      } else {
                        handleSortBy('sort', '-pub_cuisineOptions');
                      }
                    }
                  }}
                />
              </th>
              <th className={classNames(css.th, css.hideSmall, css.buttonBlock)} scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {dataListing.map(l => (
              <SearchResultsCard
                className={css.listingCard}
                css={css}
                key={l.id.uuid}
                listing={l}
                renderSizes={cardRenderSizes}
                setActiveListing={setActiveListing}
                page={'buy-list'}
              />
            ))}
          </tbody>
        </table>
        {/* {!!listings.length && (
          <SearchResultsHeader
            handleSortBy={handleSortBy}
            isConflictingFilterActive={isConflictingFilterActive}
            currentSort={currentSort}
          />
        )} */}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
