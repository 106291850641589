import React from 'react';
import { injectIntl } from '../../util/reactIntl';
import css from './SearchResultsCard.css';
const SearchResultsHeader = props => {
  const { handleSortBy, isConflictingFilterActive, currentSort } = props;
  return (
    <li key={'searchResultsHeader'} className={css.headerWrapper}>
      <div
        className={css.dateHolderSquare}
        onClick={e => {
          if (!isConflictingFilterActive) {
            if (currentSort === '-pub_reservationStart') {
              handleSortBy('sort', 'pub_reservationStart');
            } else {
              handleSortBy('sort', '-pub_reservationStart');
            }
          }
        }}
      >
        <span className={css.headerText}>Date</span>
      </div>
      <div
        className={css.sliderHolder}
        onClick={e => {
          if (!isConflictingFilterActive) {
            if (currentSort === '-pub_reservationStart') {
              handleSortBy('sort', 'pub_reservationStart');
            } else {
              handleSortBy('sort', '-pub_reservationStart');
            }
          }
        }}
      >
        <div className={css.imageHolderSquareHeader}>
          <div className={css.headerText}>Time</div>
        </div>
      </div>
        <div className={css.listingTitleHeader}>Name</div>
        <span
          className={css.headerListingPrice}
          onClick={e => {
            if (!isConflictingFilterActive) {
              if (currentSort === '-price') {
                handleSortBy('sort', 'price');
              } else {
                handleSortBy('sort', '-price');
              }
            }
          }}
        >
          $
        </span>
        <span
          className={css.headerTextSeats}
          onClick={e => {
            if (!isConflictingFilterActive) {
              if (currentSort === '-pub_capacity') {
                handleSortBy('sort', 'pub_capacity');
              } else {
                handleSortBy('sort', '-pub_capacity');
              }
            }
          }}
        >
          Table Size
        </span>
        <span className={css.headerCuisineOption}>Cuisine Type</span>
    </li>
  );
};

export default injectIntl(SearchResultsHeader);
