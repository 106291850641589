import React, { useState, useEffect } from 'react';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
} from '../../components';
import { StaticPage, TopbarContainer } from '..';
import css from './FAQPage.css';
import lounge_man_animated from './images/inside-table-gif3.gif';
import lounge_man_webm from './images/tablesnatch-animated.webm';

const FAQPage = () => {
  const [webmSupported, setWebmSupported] = useState(true);
  useEffect(_ => {
    if (typeof window !== 'undefined') {
      const videoElement = document.querySelector('video');
      videoElement.addEventListener('error', () => {
        setWebmSupported(false);
      });
    }
  }, []);
  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FAQPage',
        description: 'About Yogatime',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          {/* <img src={lounge_man_animated} className={css.heroContentImage} alt={'logo'} /> */}
          {webmSupported ? (
            <video
              src={lounge_man_webm}
              className={css.heroContentImage}
              alt={'logo'}
              type="video/webm"
              autoplay="true"
              muted="true"
              loop="true"
              playsInline="true"
            />
          ) : (
            <img src={lounge_man_animated} className={css.heroContentImage} alt={'logo'} />
          )}

          <div className={css.logoTextWrapper}>
            <div className={css.logoText}>A Peer to Peer Marketplace For</div>
            <div className={css.logoText}>Buying and Selling Reservations</div>
          </div>
          {/* <img className={css.coverImage} src={image} alt="My first ice cream." /> */}
          {/* <h1 className={css.pageTitle}>Find new depths in your yoga practice</h1> */}

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <h1 className={css.pageTitle}>Frequently Asked Questions</h1>
              <p>
                Hi! and Welcome to TableSnatch! TableSnatch loves foodies and the restaurants
                foodies love! Below is a quick what and how, but please review our{' '}
                <NamedLink name={'TermsOfServicePage'}>Terms of Use</NamedLink> for additional
                information. Thanks for coming to TableSnatch!
              </p>
              <h2 className={css.subtitle}>What is TableSnatch?</h2>
              <p>
                We are a peer-to-peer marketplace where holders of hard-to-get restaurant
                reservations can sell those reservations to others who will use them.
              </p>
              <h2 className={css.subtitle}>
                Is TableSnatch just another online restaurant reservation service?
              </h2>
              <p>
                No. TableSnatch does not compete with or replace any online reservation service. It
                fills the gap where people can sell the hard-to-get reservations they won’t use.
                Selling your reservation helps restaurants reduce no-shows and the hassle of
                re-reserving the table.
              </p>
              <p>
                In fact, we invite any online reservation service to contact us and find out how we
                can help them serve the restaurant industry even better. So, if you are an online
                reservation service, <NamedLink name={'ContactPage'}>contact us here</NamedLink>!
                TableSnatch would love to help or partner with you!
              </p>
              <h2 className={css.subtitle}>How does TableSnatch help restaurants?</h2>
              <p>
                TableSnatch is a well-thought platform created to benefit restaurants directly.{' '}
                <NamedLink name={'ContactPage'}>Click here</NamedLink> to contact us and find out
                more!
              </p>
              <h2 className={css.subtitle}>How does the money work?</h2>

              <p>
                Posting a reservation for sale on TableSnatch costs $5.00 plus a small processing
                fee. So, the total cost of posting a reservation for sale on TableSnatch is $5.44.
              </p>

              <p>
                Buying a reservation is one price. The price includes the amount the seller wishes
                to offer (“seller’s offer price”) for the reservation plus the Service Fee - either
                $5.00 or 15%of the offer amount, whichever is greater - plus a processing fee of
                3.6%.
              </p>
              <p>
                When a buyer purchases a reservation, the seller’s offer price (the amount the
                seller enters as the desired price) is paid by direct deposit into the seller’s bank
                account. TableSnatch retains the “tip” and the processing fee.
              </p>

              <h2 className={css.subtitle}>How does TableSnatch protect Snatchers! from fraud?</h2>
              <p>
                All funds from purchases of reservations on TableSnatch are held in escrow for a
                period of 24 hours after the time and date of the purchased reservation. If for any
                reason the reservation purchased is not honored as having never been secured by the
                seller, buyers may make a <a href="mailto:TablesSnatchReso@gmail.com">claim here</a>{' '}
                to report any wrongdoing or fraud on the part of the seller. With the information
                needed and provided by the buyer, TableSnatch will investigate the claim. If it is
                determined that fraud was committed, all funds spent by the buyer will be refunded
                in full.
              </p>
              <p>
                Any seller engaged in fraudulent offers will be prohibited from future sales on
                TableSnatch, and the restaurant in question or online reservation service may be
                notified for their information.
              </p>
              <p>
                Please look at our <NamedLink name={'TermsOfServicePage'}>Terms of Use</NamedLink>{' '}
                to learn more on how to use our site. Thanks for being a Snatcher!
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default FAQPage;
