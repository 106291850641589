import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IconHelperMan, NamedLink } from '..';
import css from './BottomHelpers.css';
import HelperBubbles from './HelperBubbles';

class BottomHelpers extends Component {
  constructor(props) {
    super(props);
    this.state = { show: true };
  }

  render() {
    const { className, rootClassName } = this.props;
    const isServer = typeof window === 'undefined';

    // Server side doesn't know about cookie consent
    if (isServer || !this.state.show) {
      return null;
    } else {
      const classes = classNames(rootClassName || css.bottomHelperRoot, className);
      return (
        <div className={classes}>
          <NamedLink name={'ContactPage'} className={css.helperManLink}>
            <IconHelperMan className={css.helperMan} />
          </NamedLink>

          <HelperBubbles>
            <NamedLink name={'ContactPage'} className={classes}>
              <div className={css.helperContentWrapper}>
                <div className={css.helperTitle}> Hey Restaurants, </div>
                <div className={css.helperSubTitle}> Want to Reduce No-Shows? </div>
                <div className={css.helperLink}> Click Me!</div>
              </div>
            </NamedLink>
          </HelperBubbles>
        </div>
      );
    }
  }
}

const { string } = PropTypes;

BottomHelpers.defaultProps = {
  className: null,
  rootClassName: null,
};

BottomHelpers.propTypes = {
  className: string,
  rootClassName: string,
};

export default BottomHelpers;
