import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import css from './IconSocialMediaInstagram.css';

const IconSocialMediaInstagram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2175 7.278C17.4222 7.278 16.7775 6.63329 16.7775 5.838C16.7775 5.04271 17.4222 4.398 18.2175 4.398C19.0128 4.398 19.6575 5.04271 19.6575 5.838C19.6575 6.63329 19.0128 7.278 18.2175 7.278ZM12 18C8.691 18 6 15.309 6 12C6 8.691 8.691 6 12 6C15.309 6 18 8.691 18 12C18 15.309 15.309 18 12 18ZM12 9C10.3455 9 9 10.3455 9 12C9 13.6545 10.3455 15 12 15C13.6545 15 15 13.6545 15 12C15 10.3455 13.6545 9 12 9ZM18 24H6C2.916 24 0 21.084 0 18V6C0 2.916 2.916 0 6 0H18C21.084 0 24 2.916 24 6V18C24 21.084 21.084 24 18 24ZM6 3C4.5975 3 3 4.5975 3 6V18C3 19.4295 4.5705 21 6 21H18C19.4025 21 21 19.4025 21 18V6C21 4.5975 19.4025 3 18 3H6Z"
        fill="white"
      />
    </svg>
  );
};

IconSocialMediaInstagram.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaInstagram.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaInstagram;
