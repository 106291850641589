import classNames from 'classnames';
import omit from 'lodash/omit';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Button, ModalInMobile } from '../../components';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import EstimatedBreakdownMaybe from '../../forms/BookingTimeForm/EstimatedBreakdownMaybe';
import { FormattedMessage, injectIntl, intlShape, FormattedDate } from '../../util/reactIntl';
import { LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import { getPropByName } from '../../util/userHelper';
import css from './BookingPanel.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const timeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

const dateFormatOptions = {
  weekday: 'long',
  month: 'short',
  day: 'numeric',
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    nextTimeSlot,
    capacity,
    isPastDate,
    reservationStart,
  } = props;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
    const hasListingState = !!listing.attributes.state;
    const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
    const showBookingTimeForm = hasListingState && !isClosed;
    const showClosedListingHelpText = listing.id && isClosed;
    const isBook = !!parse(location.search).book;
    
    const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;
    const price = listing.attributes.price;
    
    const classes = classNames(rootClassName || css.root, className);
    const titleClasses = classNames(titleClassName || css.bookingTitle);
    const startDate = new Date(getPropByName(listing, 'reservationStart'));
    const endDate = new Date(getPropByName(listing, 'reservationEnd')) || null;
    const displayDate = getPropByName(listing, 'displayDate') ;
  // This is the place to collect breakdown estimation data. See the
  // EstimatedBreakdownMaybe component to change the calculations
  // for customized payment processes.
  const bookingData =
    startDate && endDate
      ? {
          unitType,
          startDate,
          endDate,
          timeZone,
        }
      : null;
  if (
    typeof window !== 'undefined' &&
    startDate &&
    endDate &&
    !fetchLineItemsInProgress && 
    !lineItems
  ) {
    onFetchTransactionLineItems({
      bookingData: { startDate, endDate },
      listingId: listing.id,
      isOwnListing,
    });
  }
  const { formattedPrice } = priceData(price, intl);

  const showEstimatedBreakdown =
    bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;
  const bookingInfoMaybe = showEstimatedBreakdown ? (
    <div className={css.priceBreakdownContainer}>
      {/* <h3 className={css.priceBreakdownTitle}>
        <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
      </h3> */}
      <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} displayDate={displayDate}/>
    </div>
  ) : null;

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>
        <div className={css.bookingHeading}>
          <div className={css.desktopPriceContainer}>
            {/* <div className={css.desktopPriceValue} title={priceTitle}> */}
            {/* {formattedPrice} */}
            {/* </div> */}
            <div className={css.desktopPerUnit}>
              {/* <FormattedMessage id={unitTranslationKey} /> */}
            </div>
          </div>
          <div className={css.bookingHeadingContainer}>
            <h2 className={titleClasses}>{title}</h2>

            {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
          </div>
        </div>
        <div className={css.bookingTableSize}>
          Table for <span className={css.bookingTableSizeNumber}>{capacity}</span>
        </div>
        {isClosed ? (
          <h2 className={titleClasses}>This Reservation has already been Snatched! Sorry!</h2>
        ) : isPastDate ? (
          <>
            <FormattedDate value={reservationStart} {...dateFormatOptions} />
            {',  '}
            <FormattedDate value={reservationStart} {...timeFormatOptions} />
            <br />
            <h2 className={titleClasses}>
              You're too late! This Reservation isn't available anymore!
            </h2>
          </>
        ) : (
          !nextTimeSlot &&
          ''
          // <h2 className={titleClasses}>This Reservation isn't available, sorry!</h2>
        )}
        {bookingInfoMaybe}
        {/* {false ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            nextTimeSlot={nextTimeSlot}
          />
        ) : null} */}
        {isOwnListing
          ? null
          :  (
          // : nextTimeSlot && (
              <Button
                rootClassName={css.bookButton}
                onClick={e => {
                  e.preventDefault();
                  onSubmit({
                    unitType,
                    startDate,
                    endDate,
                    timeZone,
                  });
                }}
              >
                <FormattedMessage id="BookingPanel.ctaButtonMessage" />{' '}
                <span className={css.it}>It</span>
              </Button>
            )}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.perUnit}>
          <div className={css.priceContainer2}>
            <div className={css.ownListingText}>{formattedPrice}</div>
          </div>
          <div className={css.priceContainer}>Table for {capacity}</div>
        </div>
        {/* </div> */}
        {isOwnListing ? (
          <div className={css.ownListingText}>
            Your
            <br /> Reservation
          </div>
        ) : showBookingTimeForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />{' '}
            <span className={css.it}>It</span>
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
