import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import css from './IconSocialMediaFacebook.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.0344 24L5 13.5H0.5V9H5V6C5 1.9512 7.50727 0 11.1191 0C12.8492 0 14.3361 0.128805 14.7694 0.186375V4.4176L12.2644 4.41875C10.3001 4.41875 9.91981 5.35215 9.91981 6.72186V9H15.5L14 13.5H9.91979V24H5.0344Z"
        fill="white"
      />
    </svg>
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
