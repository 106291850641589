import { bool } from 'prop-types';
import React from 'react';
import TimeFilterPlain from './TimeFilterPlain';
import TimeFilterPopup from './TimeFilterPopup';

const TimeFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? <TimeFilterPopup {...rest} /> : <TimeFilterPlain {...rest} />;
};

TimeFilter.defaultProps = {
  showAsPopup: false,
};

TimeFilter.propTypes = {
  showAsPopup: bool,
};

export default TimeFilter;
