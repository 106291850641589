import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import css from './OvalLinkType.css';

const OvalLinkType = props => {
  const { rootClassName, className, link, alt } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.OvalLinkTypeOuter}>
        <div className={classNames(css.OvalLinkTypeInner, { [css.ovalAltColor]: alt })}>{link}</div>
      </div>
    </div>
  );
};

OvalLinkType.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

OvalLinkType.propTypes = {
  rootClassName: string,
  className: string,
};

export default OvalLinkType;
