import classNames from 'classnames';
import { array, bool, func, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { Button, FieldDateInput, FieldSelect, Form, IconArrowHead } from '../../components';

import {
  dateIsAfter,
  findNextBoundary,
  get12hrTime,
  getEndHours,
  getMonthStartInTimeZone,
  getSharpHours,
  getStartHours,
  isDayMomentInsideRange,
  isInRange,
  isSameDay,
  localizeAndFormatTime,
  monthIdStringInTimeZone,
  nextMonthFn,
  prevMonthFn,
  resetToStartOfDay,
  timestampToDate,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
} from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { bookingDateRequired } from '../../util/validators';
import css from './EditListingAvailabilityExceptionForm.css';

const TODAY = new Date();
const MAX_AVAILABILITY_EXCEPTIONS_RANGE = 365;

// Date formatting used for placeholder texts:
const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

// React-dates returns wrapped date objects
const extractDateFromFieldDateInput = dateValue =>
  dateValue && dateValue.date ? dateValue.date : null;

// Get first available start time as timestamp or null.
const firstAvailableStartTime = availableStartTimes =>
  availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
    ? availableStartTimes[0].timestamp
    : null;

const endOfAvailabilityExceptionRange = (timeZone, date) => {
  return resetToStartOfDay(date, timeZone, MAX_AVAILABILITY_EXCEPTIONS_RANGE - 1);
};

// Convert exceptions list to inverted array of time-ranges that are available for new exceptions.
const getAvailableTimeRangesForExceptions = (exceptions, timeZone) => {
  const lastBoundary = endOfAvailabilityExceptionRange(timeZone, TODAY);

  // If no exceptions, return full time range as free of exceptions.
  // if (!exceptions || exceptions.length < 1) {
  return [{ start: new Date(), end: lastBoundary }];
};

// Get available start times for new exceptions on given date.
const getAvailableStartTimes = (date, timeRangesOnSelectedDate, intl, timeZone) => {
  if (timeRangesOnSelectedDate.length === 0 || !timeRangesOnSelectedDate[0] || !date) {
    return [];
  }

  // Ensure 00:00 on correct time zone
  const startOfDate = resetToStartOfDay(date, timeZone);
  const nextDay = resetToStartOfDay(startOfDate, timeZone, 1);

  const allHours = timeRangesOnSelectedDate.reduce((availableHours, t) => {
    // time-range: start and end
    const { start, end } = t;

    // If the date is after start, use the date.
    // Otherwise use the start time.
    const startLimit = dateIsAfter(startOfDate, start) ? startOfDate : start;

    // If the end date is after "the next day", use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(end, nextDay) ? nextDay : end;

    const hours = getStartHours(intl, timeZone, startLimit, endLimit).filter(t => {
      return parseInt(t.timeOfDay.split(':')[0]) >= 7 && parseInt(t.timeOfDay.split(':')[0]) <= 22;
    });
    return availableHours.concat(hours);
  }, []);
  return allHours;
};

// Return exception's start time, if it happens after the beginning of last day of the time-range
//
// Selected end date: today, startOfSelectedEndDay: 00:00
// Time range starts: 10:00 (today)
// => 10:00 (today)
//
// Selected end date: today, startOfSelectedEndDay: 00:00
// Time range starts: 11:00 (yesterday)
// => 00:00 (today)
const startTimeOrStartOfSelectedEndDay = (selectedStartTimeAsDate, startOfSelectedEndDay) =>
  dateIsAfter(selectedStartTimeAsDate, startOfSelectedEndDay)
    ? selectedStartTimeAsDate
    : startOfSelectedEndDay;

// Return time-range's end, if it becomes before selected end date changes (next 00:00)
//
// Selected end date: today. dayAfterSelectedEnd: 00:00 (tomorrow)
// Time range ends: 12:00 (today)
// => 12:00 (today)
//
// Selected end date: today. dayAfterSelectedEnd: 00:00 (tomorrow)
// Time range ends: 14:00 (tomorrow)
// => 00:00 (tomorrow)
const timeRangeEndOrNextMidnight = (timeRangeEnd, dayAfterSelectedEnd) =>
  dateIsAfter(dayAfterSelectedEnd, timeRangeEnd) ? timeRangeEnd : dayAfterSelectedEnd;

// Get available end times for new exceptions on selected time range.
const getAvailableEndTimes = (
  selectedStartTime,
  selectedEndDate,
  selectedTimeRange,
  intl,
  timeZone
) => {
  if (!selectedTimeRange || !selectedEndDate || !selectedStartTime) {
    return [];
  }

  const timeRangeEnd = selectedTimeRange.end;
  const selectedStartTimeAsDate = timestampToDate(selectedStartTime);
  const isSingleDayRange = isSameDay(selectedStartTimeAsDate, selectedEndDate, timeZone);

  // Midnight of selectedEndDate
  const startOfSelectedEndDate = resetToStartOfDay(selectedEndDate, timeZone);
  // Next midnight after selectedEndDate
  const dayAfterSelectedEndDate = resetToStartOfDay(selectedEndDate, timeZone, 1);

  const limitStart = startTimeOrStartOfSelectedEndDay(
    selectedStartTimeAsDate,
    startOfSelectedEndDate
  );
  const limitEnd = timeRangeEndOrNextMidnight(timeRangeEnd, dayAfterSelectedEndDate);

  return isSingleDayRange
    ? getEndHours(intl, timeZone, limitStart, limitEnd)
    : getSharpHours(intl, timeZone, limitStart, limitEnd);
};

// Get time-ranges on given date.
const getTimeRanges = (timeRanges, date, timeZone) => {
  return timeRanges && timeRanges[0] && date
    ? timeRanges.filter(t => isInRange(date, t.start, t.end, 'day', timeZone))
    : [];
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
const getAllTimeValues = (
  intl,
  timeZone,
  timeRanges,
  selectedStartDate,
  selectedStartTime,
  selectedEndDate
) => {
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
        selectedStartDate,
        getTimeRanges(timeRanges, selectedStartDate, timeZone),
        intl,
        timeZone
      );
  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
    ? startTimes[0].timestamp
    : null;

  const startTimeAsDate = startTime ? timestampToDate(startTime) : null;

  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  const endDate = selectedEndDate
    ? selectedEndDate
    : startTimeAsDate
    ? new Date(findNextBoundary(timeZone, startTimeAsDate).getTime() - 1)
    : null;

  const selectedTimeRange = timeRanges.find(t => isInRange(startTimeAsDate, t.start, t.end));

  const endTimes = getAvailableEndTimes(startTime, endDate, selectedTimeRange, intl, timeZone);
  const endTime =
    endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp ? endTimes[0].timestamp : null;

  return { startTime, endDate, endTime, selectedTimeRange };
};

// Update current month and call callback function.
const onMonthClick = (currentMonth, setCurrentMonth, timeZone, onMonthChanged) => monthFn => {
  const updatedMonth = monthFn(currentMonth, timeZone);
  setCurrentMonth(updatedMonth);

  if (onMonthChanged) {
    const monthId = monthIdStringInTimeZone(updatedMonth, timeZone);
    onMonthChanged(monthId);
  }
};

// Format form's value for the react-dates input: convert timeOfDay to the local time
const formatFieldDateInput = timeZone => v =>
  v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : { date: v };

// Parse react-dates input's value: convert timeOfDay to the given time zone
const parseFieldDateInput = timeZone => v =>
  v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v;

// React Dates calendar needs isDayBlocked function as props
// We check if the day belongs to one of the available time ranges
const isDayBlocked = (availableTimeRanges, timeZone) =>
  availableTimeRanges
    ? day =>
        !availableTimeRanges.find(timeRange =>
          isDayMomentInsideRange(day, timeRange.start, timeRange.end, timeZone)
        )
    : () => false;

// Helper function, which changes form's state when exceptionStartDate input has been changed
const onExceptionStartDateChange = (value, timeRanges, props) => {
  const { timeZone, intl, form } = props;
  // reinit form
  // form.initialize({
    // exceptionStartDate: null,
    // exceptionStartTime: null,
    // exceptionEndDate: null,
    // exceptionEndTime: null,
  // });

  if (!value || !value.date) {
    form.batch(() => {
      // form.change('exceptionStartTime', null);
      // form.change('exceptionEndDate', { date: null });
      // form.change('exceptionEndTime', null);
    });
    return;
  }

  // This callback function (onExceptionStartDateChange) is called from react-dates component.
  // It gets raw value as a param - browser's local time instead of time in listing's timezone.
  const startDate = value.date;
  const timeRangesOnSelectedDate = getTimeRanges(timeRanges, startDate, timeZone);
  const { startTime, endDate, endTime } = getAllTimeValues(
    intl,
    timeZone,
    timeRangesOnSelectedDate,
    startDate
  );
  form.batch(() => {
    form.change('exceptionStartTime', startTime);
    form.change('exceptionEndDate', { date: endDate });
    form.change('exceptionEndTime', endTime);
  });
};

// Helper function, which changes form's state when exceptionStartTime select has been changed
const onExceptionStartTimeChange = (value, timeRangesOnSelectedDate, props) => {
  const { timeZone, intl, form, values } = props;
  const startDate = values.exceptionStartDate.date;
  const { endDate, endTime } = getAllTimeValues(
    intl,
    timeZone,
    timeRangesOnSelectedDate,
    startDate,
    value
  );

  form.batch(() => {
    form.change('exceptionEndDate', { date: endDate });
    form.change('exceptionEndTime', endTime);
  });
};

/////////////////
// Next & Prev //
/////////////////

// Components for the react-dates calendar
const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfAvailabilityExceptionRange(timeZone, TODAY)) ? null : (
    <IconArrowHead direction="right" size="small" />
  );
};
const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? (
    <IconArrowHead direction="left" size="small" />
  ) : null;
};

//////////////////////////////////////////
// EditListingAvailabilityExceptionForm //
//////////////////////////////////////////
const EditListingAvailabilityExceptionForm = props => {
  const [currentMonth, setCurrentMonth] = useState(getMonthStartInTimeZone(TODAY, props.timeZone));
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          rootClassName,
          formId,
          disabled,
          handleSubmit,
          intl,
          invalid,
          onMonthChanged,
          availabilityExceptions,
          timeZone,
          updateInProgress,
          fetchErrors,
          values,
          initialValues,
        } = formRenderProps;
        const idPrefix = `${formId}` || 'EditListingAvailabilityExceptionForm';
        const {
          exceptionStartDate,
          exceptionStartTime = null,
          exceptionEndDate,
          exceptionEndTime,
        } = (values !== {} && values) || initialValues || {};

        const exceptionStartDay = extractDateFromFieldDateInput(exceptionStartDate);
        const exceptionEndDay = extractDateFromFieldDateInput(exceptionEndDate);

        const startTimeDisabled = !exceptionStartDate;
        const endTimeDisabled = !exceptionStartDate || !exceptionStartTime || !exceptionEndDate;

        // Get all the available time-ranges for creating new AvailabilityExceptions
        const availableTimeRanges = getAvailableTimeRangesForExceptions(
          availabilityExceptions,
          timeZone
        );
        const timeRangesOnSelectedDate = getTimeRanges(
          availableTimeRanges,
          exceptionStartDay,
          timeZone
        );

        const availableStartTimesRaw = getAvailableStartTimes(
          exceptionStartDay,
          timeRangesOnSelectedDate,
          intl,
          timeZone
        );
        const availableStartTimes = availableStartTimesRaw.filter(t => {
          return (
            parseInt(t.timeOfDay.split(':')[0]) >= 7 && parseInt(t.timeOfDay.split(':')[0]) <= 22
          );
        });
        const { startTime, endDate, selectedTimeRange } = getAllTimeValues(
          intl,
          timeZone,
          timeRangesOnSelectedDate,
          exceptionStartDay,
          exceptionStartTime || firstAvailableStartTime(availableStartTimes),
          exceptionEndDay || exceptionStartDay
        );

        const availableEndTimes = getAvailableEndTimes(
          exceptionStartTime || startTime,
          exceptionEndDay || endDate,
          selectedTimeRange,
          intl,
          timeZone
        ).filter(t => {
          return (
            parseInt(t.timeOfDay.split(':')[0]) >= 7 && parseInt(t.timeOfDay.split(':')[0]) <= 22
          );
        });

        // Returns a function that changes the current month
        // Currently, used for hiding next&prev month arrow icons.
        const handleMonthClick = onMonthClick(
          currentMonth,
          setCurrentMonth,
          timeZone,
          onMonthChanged
        );

        const { updateListingError } = fetchErrors || {};
        const placeholderTime = localizeAndFormatTime(
          intl,
          timeZone,
          findNextBoundary(timeZone, TODAY)
        );
        const submitInProgress = updateInProgress;
        const hasData =
          exceptionStartDate && exceptionStartTime && exceptionEndDate && exceptionEndTime;
        const submitDisabled = !hasData || invalid || disabled || submitInProgress;
        const classes = classNames(rootClassName || css.root, className);

        return (
          <Form
            className={classes}
            onSubmit={e => {
              e.preventDefault();

              handleSubmit(e);
            }}
          >
            <div className={css.section}>
              <div className={css.formRow}>
                <div className={css.field}>
                  <FieldDateInput
                    className={css.fieldDateInput}
                    name="exceptionStartDate"
                    id={`${idPrefix}.exceptionStartDate`}
                    label={intl.formatMessage({
                      id: 'EditListingAvailabilityExceptionForm.exceptionStartDateLabel',
                    })}
                    placeholderText={intl.formatDate(TODAY, dateFormattingOptions)}
                    format={formatFieldDateInput(timeZone)}
                    parse={parseFieldDateInput(timeZone)}
                    isDayBlocked={isDayBlocked(availableTimeRanges, timeZone)}
                    onChange={value => {
                      onExceptionStartDateChange(value, availableTimeRanges, formRenderProps);
                    }}
                    onPrevMonthClick={() => handleMonthClick(prevMonthFn)}
                    onNextMonthClick={() => handleMonthClick(nextMonthFn)}
                    navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
                    navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
                    useMobileMargins
                    showErrorMessage={false}
                    validate={bookingDateRequired('Required')}
                    // value={new Date()}
                  />
                </div>
              </div>
              <div className={css.formRow}>
                <div className={css.field}>
                  <FieldSelect
                    name="exceptionStartTime"
                    id={`${idPrefix}.exceptionStartTime`}
                    label={'Time'}
                    className={exceptionStartDate ? css.fieldSelect : css.fieldSelectDisabled}
                    selectClassName={exceptionStartDate ? css.select : css.selectDisabled}
                    disabled={startTimeDisabled}
                    onChange={value =>
                      onExceptionStartTimeChange(value, timeRangesOnSelectedDate, formRenderProps)
                    }
                  >
                    {exceptionStartDay ? (
                      availableStartTimes.map(p => {
                        return (
                          <option key={p.timestamp} value={p.timestamp}>
                            {get12hrTime(p.timeOfDay)}
                          </option>
                        );
                      })
                    ) : (
                      <option>{placeholderTime}</option>
                    )}
                  </FieldSelect>
                </div>
              </div>
              <div className={css.hidden}>
                <FieldSelect
                  name="exceptionEndTime"
                  id={`${idPrefix}.exceptionEndTime`}
                  className={exceptionStartDate ? css.fieldSelect : css.fieldSelectDisabled}
                  selectClassName={exceptionStartDate ? css.select : css.selectDisabled}
                  disabled={endTimeDisabled}
                >
                  {exceptionStartDay && exceptionStartTime && endDate ? (
                    availableEndTimes.map((p, i) => {
                      const isLastIndex = i === availableEndTimes.length - 1;
                      const timeOfDay =
                        p.timeOfDay === '00:00' && isLastIndex ? '24:00' : p.timeOfDay;
                      return (
                        <option key={p.timestamp} value={p.timestamp}>
                          {timeOfDay}
                        </option>
                      );
                    })
                  ) : (
                    <option>{placeholderTime}</option>
                  )}
                </FieldSelect>
              </div>

              <div className={css.formRow}>
                <div className={css.field}></div>
              </div>
            </div>

            <div className={css.submitButton}>
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingAvailabilityExceptionForm.updateFailed" />
                </p>
              ) : null}
              <Button type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                {/* <FormattedMessage id="EditListingAvailabilityExceptionForm.addException" /> */}
                Continue{' '}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingAvailabilityExceptionForm.defaultProps = {
  className: null,
  rootClassName: null,
  fetchErrors: null,
  formId: null,
  availabilityExceptions: [],
};

EditListingAvailabilityExceptionForm.propTypes = {
  className: string,
  rootClassName: string,
  formId: string,
  availabilityExceptions: array,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  timeZone: string.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingAvailabilityExceptionForm);
