import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { timestampToDate } from '../../util/dates';
import React, { useState } from 'react';
import {
  EditListingAvailabilityPanel,
  EditListingDescriptionPanel,
  EditListingFeaturesPanel,
  EditListingLocationPanel,
  EditListingPhotosPanel,
  EditListingPoliciesPanel,
  EditListingPricingPanel,
} from '../../components';
import { CreateReservationCheckoutPage } from '../../containers';
import routeConfiguration from '../../routeConfiguration';
import { ensureListing } from '../../util/data';
import { intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import { getPropByName } from '../../util/userHelper';
import {
  LISTING_PAGE_PARAM_TYPES,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
} from '../../util/urlHelpers';
import css from './EditListingWizard.css';

export const AVAILABILITY = 'availability';
export const DESCRIPTION = 'description';
export const FEATURES = 'features';
export const POLICY = 'policy';
export const LOCATION = 'location';
export const PRICING = 'pricing';
export const PHOTOS = 'photos';
export const RESERVATION_PAYMENT = 'reservation_payment';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  DESCRIPTION,
  // FEATURES,
  // POLICY,
  // LOCATION,
  // PRICING,
  // AVAILABILITY,
  // PHOTOS,
  // RESERVATION_PAYMENT,
];

const pathParamsToNextTab = params => {
  return { ...params, tab: 'description' };
  // return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();
  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.push(to);
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    history,
    images,
    listing,
    handlePublishListing,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    intl,
    addExceptionInProgress,
    fetchExceptionsInProgress,
    availabilityExceptions,
    shouldShowPayoutDetails,
    onSetShowPayoutDetails,
  } = props;
  const [savedReservationDate, setSavedReservationDate] = useState(null);
  const [savedImages, setSavedImages] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPhotosModal, setShowPhotosModal] = useState(false);
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);

  const onSetSavedImages = values => {
    setSavedImages(values.images);
  };
  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;
  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const hasPaid = currentListing?.attributes?.publicData?.hasPaid;
  const saveReservationDateException = (values, listingId) => {
    const { exceptionStartTime, exceptionEndTime, displayDate = null } = values;
     const newValues = {
       seats: 1,
       start: timestampToDate(exceptionStartTime),
       end: timestampToDate(exceptionEndTime),
       startTimeStamp: exceptionStartTime,
       endTimeStamp: exceptionEndTime,
       displayDate: displayDate && displayDate?.toString(),
     };

    setSavedReservationDate(newValues);
    if (listingId) {
      const updateValues = {
        publicData: {
          reservationStart: exceptionStartTime && parseInt(exceptionStartTime),
          reservationEnd: exceptionEndTime && parseInt(exceptionEndTime),
          displayDate: displayDate && displayDate?.toString(),
        },
      };
      onCompleteEditListingWizardTab('description', updateValues, false, {
        start: timestampToDate(exceptionStartTime),
        end: timestampToDate(exceptionEndTime),
      });
    }
  };
  const onCompleteEditListingWizardTab = (
    tab,
    updateValues,
    passThrownErrors = false,
    resDates
  ) => {
    
    // Normalize images for API call
    const { publicData, ...otherValues } = updateValues;
  

    const images = savedImages;
    const imageProperty = typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    let publicDataValue;

    
    
    if (savedReservationDate) {
      // console.log('heree====')
      delete publicData.exceptionStartTime;
      delete publicData.exceptionStartDate;
      delete publicData.exceptionEndDate;
      delete publicData.exceptionEndTime;
      publicDataValue = {
        ...publicData,
        reservationStart: savedReservationDate && parseInt(savedReservationDate.startTimeStamp),
        reservationEnd: savedReservationDate && parseInt(savedReservationDate.endTimeStamp),
        displayDate: savedReservationDate.displayDate,
      };
    } else {
      // const { startTimeStamp, endTimeStamp, ...dates } = newValues || {};
      // const newDates = dates.start ? dates : resDates;
      // const { displayDate, ...newDates2 } = newDates || {};
      // if (newDates2 && !!newDates2.start) {
      //   onAddAvailabilityException(availabilityExceptions, {
      //     ...newDates2,
      //     seats: 1,
      //     listingId: currentListing.id,
      //   });
      // }
      
      const { exceptionStartTime, exceptionEndTime } = updateValues.publicData;
      const newDisplayDate= new Date(parseInt(exceptionStartTime)).toString();
    
      const newValues = {
        seats: 1,
        start: timestampToDate(exceptionStartTime),
        end: timestampToDate(exceptionEndTime),
        startTimeStamp: exceptionStartTime,
        endTimeStamp: exceptionEndTime,
        displayDate: newDisplayDate,
      };
      delete publicData.exceptionStartTime;
      delete publicData.exceptionStartDate;
      delete publicData.exceptionEndDate;
      delete publicData.exceptionEndTime;
      setSavedReservationDate(newValues);
      publicDataValue = {
        ...publicData,
         reservationStart: newValues.startTimeStamp && parseInt(newValues.startTimeStamp),
          reservationEnd: newValues.endTimeStamp && parseInt(newValues.endTimeStamp),
          displayDate:  newDisplayDate,
      };
      
    }
    const updateValuesWithImages = {
      ...otherValues,
      publicData: {
        ...publicDataValue,
      },
      ...imageProperty,
    };
    if (isNewListingFlow) {
      // alert();return false;
      console.log("New FLow");
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };
      return onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          // if (tab !== AVAILABILITY && tab !== marketplaceTabs[marketplaceTabs.length - 2]) {
          //   // Create listing flow: smooth scrolling polyfill to scroll to correct tab
          //   handleCreateFlowTabScrolling(false);

          //   // After successful saving of draft data, user should be redirected to next tab
          //   redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history);
          // } else if (tab === 'description') {
          //  const newValues = {
          //    listingId: currentListing.id,
          //    seats: 1,
          //    start: timestampToDate(exceptionStartTime),
          //    end: timestampToDate(exceptionEndTime),
          //  };
          const { startTimeStamp, endTimeStamp, displayDate,  ...dates } = savedReservationDate || {};
          handlePublishListing(r.data.data.id).then(r => {
            const newDates = dates.start ? dates : resDates;
            if (newDates) {
              onAddAvailabilityException(availabilityExceptions, {
                ...newDates,
                listingId: r.data.data.id,
              });
            }
            redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history);
          });
          // }
        })
        .catch(e => {
          if (passThrownErrors) {
            throw e;
          }
          // No need for extra actions
          // Error is logged in EditListingPage.duck file.
        });
    } else {
      console.log("Update FLow");
      const { startTimeStamp, endTimeStamp, ...dates } = savedReservationDate || {};
      const newDates = dates.start ? dates : resDates;
      const { displayDate, ...newDates2 } = newDates || {};
      if (newDates2 && !!newDates2.start) {
        onAddAvailabilityException(availabilityExceptions, {
          ...newDates2,
          seats: 1,
          listingId: currentListing.id,
        });
      }
      return onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id });
    }
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: hasPaid,
      disabled: fetchInProgress,
    };
  };
  const isPendingApproval = getPropByName(listing, 'state') === 'pendingApproval';
  const AvailabilitySubmitButtonTranslationKey = isNewListingFlow
    ? 'EditListingWizard.saveNewAvailability'
    : 'EditListingWizard.saveEditAvailability';
  const availabilityPanelComp = (
    <EditListingAvailabilityPanel
      {...panelProps(AVAILABILITY)}
      addExceptionInProgress={addExceptionInProgress}
      fetchExceptionsInProgress={fetchExceptionsInProgress}
      availabilityExceptions={availabilityExceptions}
      submitButtonText={intl.formatMessage({ id: AvailabilitySubmitButtonTranslationKey })}
      onAddAvailabilityException={onAddAvailabilityException}
      onDeleteAvailabilityException={onDeleteAvailabilityException}
      onSubmit={values => {
        const listingId = currentListing && currentListing.id && currentListing.id.uuid;
        // We want to return the Promise to the form,
        // so that it doesn't close its modal if an error is thrown.
        saveReservationDateException(values, listingId);
        setShowAvailabilityModal(false);
        // return onCompleteEditListingWizardTab(tab, values, true);
      }}
      onNextTab={() =>
        redirectAfterDraftUpdate(listing.id.uuid, params, tab, marketplaceTabs, history)
      }
    />
  );

  const PhotosSubmitButtonTranslationKey = isPendingApproval
    ? 'EditListingWizard.saveEditPhotos'
    : 'EditListingWizard.saveNewPhotos';
  const photoPanelComp = (
    <EditListingPhotosPanel
      {...panelProps(PHOTOS)}
      submitButtonText={intl.formatMessage({ id: PhotosSubmitButtonTranslationKey })}
      images={images}
      onImageUpload={onImageUpload}
      onRemoveImage={onRemoveImage}
      onSubmit={values => {}}
      savedImages={savedImages}
      setSavedImages={onSetSavedImages}
      onUpdateImageOrder={onUpdateImageOrder}
      setShowPhotosModal={setShowPhotosModal}
      onNextTab={() =>
        redirectAfterDraftUpdate(listing.id.uuid, params, tab, marketplaceTabs, history)
      }
    />
  );

  const createResSubmitButtonTranslationKey = isNewListingFlow
    ? 'EditListingWizard.saveNewPayment'
    : 'EditListingWizard.saveEditPayment';

  const CreateReservationCheckoutPageComp = (
    <CreateReservationCheckoutPage
      {...panelProps(RESERVATION_PAYMENT)}
      submitButtonText={intl.formatMessage({ id: createResSubmitButtonTranslationKey })}
      onSubmit={values => {
        onCompleteEditListingWizardTab(tab, values);
      }}
    />
  );

  switch (tab) {
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingDescriptionPanel
          {...panelProps(DESCRIPTION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          availabilityPanelComp={availabilityPanelComp}
          availabilityExceptions={availabilityExceptions}
          photoPanelComp={photoPanelComp}
          CreateReservationCheckoutPageComp={CreateReservationCheckoutPageComp}
          savedReservationDate={savedReservationDate}
          showPaymentModal={showPaymentModal}
          setShowPaymentModal={setShowPaymentModal}
          showAvailabilityModal={showAvailabilityModal}
          setShowAvailabilityModal={setShowAvailabilityModal}
          shouldShowPayoutDetails={shouldShowPayoutDetails}
          onSetShowPayoutDetails={onSetShowPayoutDetails}
          showPhotosModal={showPhotosModal}
          setShowPhotosModal={setShowPhotosModal}
        />
      );
    }
    case FEATURES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <EditListingFeaturesPanel
          {...panelProps(FEATURES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case POLICY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPolicies'
        : 'EditListingWizard.saveEditPolicies';
      return (
        <EditListingPoliciesPanel
          {...panelProps(POLICY)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LOCATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <EditListingLocationPanel
          {...panelProps(LOCATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case PRICING: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPricing'
        : 'EditListingWizard.saveEditPricing';
      return (
        <EditListingPricingPanel
          {...panelProps(PRICING)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case AVAILABILITY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAvailability'
        : 'EditListingWizard.saveEditAvailability';
      return (
        <EditListingAvailabilityPanel
          {...panelProps(AVAILABILITY)}
          addExceptionInProgress={addExceptionInProgress}
          fetchExceptionsInProgress={fetchExceptionsInProgress}
          availabilityExceptions={availabilityExceptions}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onAddAvailabilityException={onAddAvailabilityException}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
          onSubmit={values => {
            // We want to return the Promise to the form,
            // so that it doesn't close its modal if an error is thrown.
            return onCompleteEditListingWizardTab(tab, values, true);
          }}
          onNextTab={() =>
            redirectAfterDraftUpdate(listing.id.uuid, params, tab, marketplaceTabs, history)
          }
        />
      );
    }
    case PHOTOS: {
      const submitButtonTranslationKey = isPendingApproval
        ? 'EditListingWizard.saveEditPhotos'
        : 'EditListingWizard.saveNewPhotos';

      return (
        <EditListingPhotosPanel
          {...panelProps(PHOTOS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
          onNextTab={() =>
            redirectAfterDraftUpdate(listing.id.uuid, params, tab, marketplaceTabs, history)
          }
        />
      );
    }
    case RESERVATION_PAYMENT: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPayment'
        : 'EditListingWizard.saveEditPayment';

      return (
        <CreateReservationCheckoutPage
          {...panelProps(RESERVATION_PAYMENT)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: [],
};

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
