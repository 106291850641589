/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { TabNav } from '../../components';
import css from './LayoutWrapperSideNav.css';


const LayoutWrapperSideNav = props => {
  const { className, rootClassName, navClassName, children, tabs } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <aside className={classes}>
      {tabs ? (
        <TabNav
          rootClassName={classNames(css.tabs, navClassName)}
          tabRootClassName={css.tab}
          tabs={tabs}
        />
      ) : null}
      {children}
    </aside>
  );
};

LayoutWrapperSideNav.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  tabs: null,
};

const { node, string, array } = PropTypes;

LayoutWrapperSideNav.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  tabs: array,
};

export default LayoutWrapperSideNav;
