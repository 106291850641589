import classNames from 'classnames';
import {  func, string } from 'prop-types';
import React, { Component } from 'react';
import { Modal } from '..';
import { Button } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './ModalConfirmTerms.css';

class ModalConfirmTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationModal: null,
    };
  }

  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      onManageDisableScrolling,
      isConfirmationModalOpen,
      setIsConfirmationModalOpen,
      setConfirmationFlag,
      content
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const closeButtonMessage = (
      <FormattedMessage id="ModalConfirmTerms.closeVerifyEmailReminder" />
    );

    return (
      <Modal
        id="ConfirmationModal"
        containerClassName={containerClassName}
        isOpen={!!isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
      >
        <div className={classes}>
          Please read and agree to the terms of service. You will not be able to post this
          reservation until this is confirmed.
          <div className={css.contentWrapper}>
            {content}
          </div>
          <div className={css.buttonWrapper}>
            <Button
              className={css.confirmationButton}
              onClick={e => {
                e.preventDefault();
                setConfirmationFlag(true);
                setIsConfirmationModalOpen(false);
              }}
            >
              I Agree
            </Button>
            <Button
              className={css.confirmationButton}
              onClick={e => {
                e.preventDefault();
                setConfirmationFlag(false);
                setIsConfirmationModalOpen(false);
              }}
            >
              No, I do not agree
            </Button>
          </div>
        </div>{' '}
      </Modal>
    );
  }
}

ModalConfirmTerms.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

ModalConfirmTerms.propTypes = {
  className: string,
  rootClassName: string,
  containerClassName: string,
  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,
};

ModalConfirmTerms.displayName = 'ModalConfirmTerms';

export default ModalConfirmTerms;
