import unionWith from 'lodash/unionWith';
import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToTz, getExclusiveEndDateWithTz } from '../../util/dates';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));
  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [
            values[0] === '0'
              ? inSubunits(parseInt(values[0]))
              : inSubunits(parseInt(values[0])  + ''),
            values[1] === '0'
              ? inSubunits(parseInt(values[1]))
              : inSubunits(parseInt(values[1])  + '') + 1,
          ].join(','),
        }
      : {};
  };

  const availabilityParams = (datesParam, minDurationParam) => {
    const dateValues = datesParam ? datesParam.split(',') : [];
    const hasDateValues = datesParam && dateValues.length === 2;
    let startDate = hasDateValues ? dateValues[0] : null;
    const endDate = hasDateValues ? dateValues[1] : null;
   
    
    const minDurationMaybe =
      minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
        ? { minDuration: minDurationParam }
        : {};
    
    // Find configs for 'dates-length' filter
    // (type: BookingDateRangeLengthFilter)
    const filterConfigs = config.custom.filters;
    const idOfBookingDateRangeLengthFilter = 'dates-length';
    const dateLengthFilterConfig = filterConfigs.find(
      f => f.id === idOfBookingDateRangeLengthFilter
    );
    // Extract time zone
    const timeZone = dateLengthFilterConfig.config.searchTimeZone;
    return hasDateValues
      ? {
          start: formatDateStringToTz(startDate || new Date(), timeZone),
          end: getExclusiveEndDateWithTz(endDate, timeZone),

          // When we have `time-partial` value in the availability, the
          // API returns listings that don't necessarily have the full
          // start->end range available, but enough that the minDuration
          // (in minutes) can be fulfilled.
          //
          // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
          availability: 'time-partial',
          // minDuration: 60,
          ...minDurationMaybe,
        }
      : {};
  };
  // if(searchParams.filter != undefined && searchParams.filter == false){
  //   console.log("No Filter")
  // }else{
    
    const { perPage, price, dates,startTime,endTime, minDuration, sort, ...rest } = searchParams;
    const priceMaybe = priceSearchParams(price);
    let availabilityMaybe = availabilityParams(dates, minDuration);
    const defaultSort = !!searchParams.keywords ? null : sort ? sort : '-pub_reservationStart';
    var d = new Date();
    var seconds = Math.round(d.getTime() );
    let endTimeQuery = {};
    if(endTime != undefined){
      // endTimeQuery = {pub_reservationEnd: Math.round(endTime)};
    }
    let reservationStart = {pub_reservationStart:seconds+","};
    if(startTime!=undefined){
      reservationStart = {pub_reservationStart:Math.round(startTime)+","};
    }
    if(startTime != undefined && dates != undefined){
      endTimeQuery = {};
      availabilityMaybe={};
      const dateValues = dates ? dates.split(',') : [];
      const hasDateValues = dates && dateValues.length === 2;
      let startDate = hasDateValues ? dateValues[0] : null;
      let endDate = hasDateValues ? dateValues[1] : null;
      let sameDate = startDate == endDate?1:0;
      let hrsFromStartTime = parseInt(new Date(startTime).getHours()) < 10 ? parseInt("0"+new Date(startTime).getHours()) : parseInt(new Date(startTime).getHours()) ;
      let minsFromStartTime = parseInt(new Date(startTime).getMinutes()) < 10 ? parseInt("0"+new Date(startTime).getMinutes()) : parseInt(new Date(startTime).getMinutes());
      let time =  hrsFromStartTime+":"+minsFromStartTime+":00";
      startDate = startDate+ " "+time;
      let reservationDateStart = new Date(startDate).getTime();
      // console.log(reservationDateStart);
      let endDateTime = new Date(endDate).getTime();
      if(sameDate == 1){
        reservationStart = {pub_reservationStart:Math.round(reservationDateStart)+','+Math.round(new Date(endDate+ " "+"23:55:00").getTime())};
        // endTimeQuery={pub_reservationEnd:Math.round(new Date(endDate+ " "+"23:55:00").getTime())}
      }else{
        reservationStart = {pub_reservationStart:Math.round(reservationDateStart)+","+Math.round(endDateTime)};
      }
      
     
    }
    const params = {
      ...rest,
      ...endTimeQuery,
      ...priceMaybe,
      ...availabilityMaybe,
      sort: defaultSort,
      per_page: perPage,
      expand: true,
      ...reservationStart
    };
    return sdk.listings
      .query(params)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(searchListingsSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(searchListingsError(storableError(e)));
        throw e;
      });
  // }
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};
