import React from 'react';
import {  IconPlate } from '../../components';
import css from './ListingPage.css';

const SectionFeaturesMaybe = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }
  const selectedOptions = publicData && publicData.menuPrice ? publicData.menuPrice : ['inexpensive'];
  const selectedConfigOptions = options.find(o => o.key === selectedOptions[0]);
  
  return (
    <div className={css.sectionFeatures}>
      {/* <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2> */}
      <div className={css.sectionFeaturesContent}>
        <div className={css.featureOption}>
          <IconPlate />
          <div className={css.featureText}>{publicData?.cuisineOptions?.[0]}</div>
        </div>
        {/* <div className={css.featureOption}>
          <IconPeople />
          <div className={css.featureText}>
            {publicData?.capacity}
            {publicData?.capacity > 1 ? ' Seats' : ' Seat'}
          </div>
        </div> */}
        <div className={css.featureOption}>
          {/* <IconPeople /> */}
          <div className={css.featureText}>
            <div className={css.menuPriceIcon}>
              {selectedConfigOptions && selectedConfigOptions.label}
            </div>
            {/* {publicData?.capacity > 1 ? ' Seats' : ' Seat'} */}
            <div className={css.priceRangeLabel}>Price Range</div>
          </div>
        </div>
      </div>
      {/* <PropertyGroup
        id="ListingPage.yogaStyles"
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        twoColumns={selectedConfigOptions.length > 5}
      /> */}
    </div>
  );
};

export default SectionFeaturesMaybe;
