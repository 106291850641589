import React from 'react';
import { FormattedDate, FormattedMessage } from '../../util/reactIntl';
import { DATE_TYPE_DATE, propTypes } from '../../util/types';
import { get12hrTime } from '../../util/dates';
import css from './BookingBreakdown.css';

const BookingPeriod = props => {
  const { startDate, endDate, dateType, timeZone, startTime } = props;
  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: 'long',
        }
      : {
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  return (
    <>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSection}>
          {/* <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingStart" />
          </div> */}
          <div className={css.dayInfo}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
          <div className={css.itemLabel}>
            {startTime}{' '}
            {/* <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} /> */}
          </div>
        </div>

        {endDate && (
          <div className={css.bookingPeriodSectionRigth}>
            <div className={css.dayLabel}>
              <FormattedMessage id="BookingBreakdown.bookingEnd" />
            </div>
            <div className={css.dayInfo}>
              <FormattedDate value={endDate} {...timeFormatOptions} {...timeZoneMaybe} />
            </div>
            <div className={css.itemLabel}>
              <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, dateType, timeZone, displayDate } = props;
  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start } = booking.attributes;
  const localStartDate = start;

  const displayDateHours = displayDate && displayDate?.split(' ')?.[4]?.slice(0, 5);
  const displayDateFormatted = displayDateHours && get12hrTime(displayDateHours);

  const newFormattedStartTime =
    start &&
    get12hrTime(
      start
        .toString()
        ?.split(' ')?.[4]
        ?.slice(0, 5)
    );
  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod
          startDate={localStartDate}
          startTime={displayDateFormatted || newFormattedStartTime}
          endDate={null}
          dateType={dateType}
          timeZone={timeZone}
        />
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
