import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import css from './IconEditField.css';

const IconEditField = props => {
  const { rootClassName, className, clickFunc } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div onClick={clickFunc}>
      <svg
        className={classes}
        version="1.1"
        viewBox="0 0 528.9 526.32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-.00060242 -1.2888)">
          <path d="m328.88 89.125 107.59 107.59-272.34 272.34-107.53-107.59zm189.23-25.948-47.981-47.981c-18.543-18.543-48.653-18.543-67.259 0l-45.961 45.961 107.59 107.59 53.611-53.611c14.382-14.383 14.382-37.577 0-51.959zm-517.81 449.51c-1.958 8.812 5.998 16.708 14.811 14.565l119.89-29.069-107.53-107.59z" />
        </g>
      </svg>
    </div>
  );
};

IconEditField.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconEditField.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconEditField;
