import classNames from 'classnames';
import { node, string } from 'prop-types';
import React from 'react';
import css from './MultiOptionButtons.css';


const MultiOptionButtons = props => {
  const { rootClassName, className, options, selectedOption, setOption } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <span className={classes}>
      <div className={css.buttonShadow}>
        {options.map(o => {
          const isSelected = o.key === selectedOption;
          return (
            <div
              className={classNames(css.optionTab, { [css.selectedOptionTab]: isSelected })}
              onClick={e => {
                e.preventDefault();
                setOption(o.key);
              }}
            >
              {o.label}
            </div>
          );
        })}
      </div>
    </span>
  );
};

MultiOptionButtons.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  checkedClassName: null,
  label: null,
};

MultiOptionButtons.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  checkedClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,
};

export default MultiOptionButtons;
