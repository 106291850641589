import { bool, object, string } from 'prop-types';
import React from 'react';
import { FieldTextInput, FieldCheckbox, Button } from '../../components';
// import config from '../../config';
// import getCountryCodes from '../../translations/countryCodes';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
//import * as validators from '../../util/validators';
import css from './StripePaymentAddress.css';


const StripePaymentPromoCode = props => {
  const { className, intl, disabled, form, fieldId, handleCheck, handleCheckboxChecked } = props;
  let promoCodeValue = '';
  const promoCodePlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.promoCodePlaceholder',
  });

  const [havePromoCode, setPromoCode] = React.useState(false);
  const [checkSetPromoCode, setApplyPromoCode] = React.useState(false);

  const handleBlurGetPromo = event => {
    const value = event.target.value;
    setApplyPromoCode(value);
  };

  const handleBlur = event => {
   // const value = event.target.value;
    const value = checkSetPromoCode;
    if(value) {
      setPromoCode(true);
      handleCheck(true, value);
      promoCodeValue = value;
    } else {
      setPromoCode(false);
      handleCheck(false, value);
      promoCodeValue = false;
    }
  };

  if(havePromoCode){
    //current PromoCode state
  }

  const [isChecked, setIsChecked] = React.useState(false);
  //this.handleChecked = this.handleChecked.bind(this); // set this, because you need get methods from CheckBox 

  const handleChecked = event => {
    //const value = event.target.value;
    setIsChecked(!isChecked);
    handleCheckboxChecked(!isChecked, promoCodeValue);
  };
  return (
    <div className={className ? className : css.root}>
            <div className={css.formRow}>
            <p className={css.bottomWrapperText}>
                <FieldCheckbox
                id={`hasPromoCodeCheck`}
                className={css.textInputRow}
                name={`hasPromoCodeCheck`}
                label={
                    <span className={css.termsText}>
                    <FormattedMessage
                        id="SignupForm.havePromoCodeText"
                    />
                    </span>
                }
                value={isChecked}
                checked={isChecked}
                onChange={event => handleChecked(event)}
                />
                {/* {this.state.termsError && (
                <span className={css.error}>You must accept the Terms of Use</span>
                )} */}
            </p>
            </div>
            {isChecked ? (
                
              <div className={css.formRow}>
                    <FieldTextInput
                    id={`${fieldId}.promocode`}
                    name="promocode"
                    disabled={disabled}
                    className={css.field}
                    type="text"
                    autoComplete="billing promocode"
                    label="Promo Code"
                    placeholder={promoCodePlaceholder}
                    onUnmount={() => form.change('promocode', undefined)}
                    //onKeyUp={event => handleBlur(event)}
                    onBlur={event => handleBlurGetPromo(event)}
                    />

                    <Button 
                     className={css.submitButtonPromoCode} 
                     onClick={event => handleBlur(event)}
                     type="button"
                    >
                      Apply
                    </Button>
              </div>
               
            ) : null}
    </div>
  );
};
StripePaymentPromoCode.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
};

StripePaymentPromoCode.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default StripePaymentPromoCode;
