import classNames from 'classnames';
import { bool, func, object, string,array } from 'prop-types';
import React from 'react';
import { ListingLink, Modal } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { LISTING_STATE_DRAFT } from '../../util/types';
import css from './EditListingDescriptionPanel.css';

///////////////////////////////////////////////////
// EditListingAvailabilityExceptionPanel - utils //
///////////////////////////////////////////////////

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

// Create entries from submit values
const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    const dayEntries = dayValues.map(dayValue => {
      const { startTime, endTime } = dayValue;
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
            dayOfWeek,
            seats: 1,
            startTime,
            endTime: endTime === '24:00' ? '00:00' : endTime,
          }
        : null;
    });

    return allEntries.concat(dayEntries.filter(e => !!e));
  }, []);

// Create availabilityPlan from submit values
const createAvailabilityPlan = values => ({
  availabilityPlan: {
    type: 'availability-plan/time',
    timezone: values.timezone,
    entries: createEntriesFromSubmitValues(values),
  },
});

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    panelUpdated,
    updateInProgress,
    errors,
    availabilityPanelComp,
    photoPanelComp,
    CreateReservationCheckoutPageComp,
    onManageDisableScrolling,
    savedReservationDate,
    showPaymentModal,
    setShowPaymentModal,
    showAvailabilityModal,
    setShowAvailabilityModal,
    shouldShowPayoutDetails,
    onSetShowPayoutDetails,
    showPhotosModal,
    setShowPhotosModal,
    availabilityExceptions,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isClosed = currentListing.attributes && currentListing.attributes.state === 'closed';
  const { description, title, publicData, geolocation, price, state } = currentListing.attributes;
  
  const isPending = state === 'pendingApproval';
  const locationFieldsPresent =
    publicData && publicData.location && publicData.location.address && geolocation;
  const location = publicData && publicData.location ? publicData.location : {};
  const { address, building } = location;
  const dateTime = availabilityExceptions.length > 0 ? availabilityExceptions[0] :{};
  const reservationStart = dateTime.attributes && dateTime.attributes.start  ?  new Date(dateTime.attributes.start) : new Date();
  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      // { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
    ],
  };
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            {title}
            {/* <FormattedMessage id="EditListingDescriptionPanel.listingTitle" /> */}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );
  let initialValuesException =
  !!availabilityExceptions.length && !!availabilityExceptions[0]
    ? {
        seats: 1,
        exceptionStartDate: { date: new Date(availabilityExceptions[0].attributes.start) },
        exceptionStartTime: String(availabilityExceptions[0].attributes.start.getTime()),
        exceptionEndDate: { date: new Date(availabilityExceptions[0].attributes.end) },
        exceptionEndTime: String(availabilityExceptions[0].attributes.end.getTime()),
      }
    : null;
    
  if(initialValuesException == null && savedReservationDate){
    initialValuesException = {
      seats:1,
      exceptionStartDate:  { date: new Date(savedReservationDate.start) },
      exceptionStartTime: String(savedReservationDate.start.getTime()),
      exceptionEndDate: { date: new Date(savedReservationDate.end) },
      exceptionEndTime: String(savedReservationDate.end.getTime()),
    }
  }
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        isClosed={isClosed}
        initialValues={{
          title,
          description,
          ...initialValuesException,
          cuisineOptions: publicData.cuisineOptions,
          menuPrice: publicData.menuPrice,
          capacity: publicData.capacity || 1,
          building,
          price,
          reservationUnder: publicData.reservationUnder,
          tableNotes: publicData.tableNotes,
          location: locationFieldsPresent
            ? {
                search: address,
                selectedPlace: { address, origin: geolocation },
              }
            : null,
        }}
        hasPaid={publicData.hasPaid}
        hasBeenSaved={!!(currentListing.id && currentListing.id.uuid)}
        saveActionMsg={
          publicData.hasPaid
            ? 'Submit'
            : !!(currentListing.id && currentListing.id.uuid)
            ? 'Process Payment'
            : 'Submit'
        }
        onSubmit={values => {
          const {
            title,
            description,
            menuPrice,
            price,
            capacity,
            cuisineOptions,
            building = '',
            location,
            reservationUnder,
            tableNotes,
            exceptionEndDate,
            exceptionEndTime,
            exceptionStartDate,
            exceptionStartTime
          } = values;
          const {
            selectedPlace: { address, origin },
          } = location;

          const updateValues = {
            title: title.trim(),
            description,
            geolocation: origin,
            price,
            publicData: {
              reservationUnder,
              menuPrice:
                menuPrice && (typeof menuPrice === 'string' ? [menuPrice] : [...menuPrice]),
              capacity: parseInt(capacity),
              cuisineOptions:
                cuisineOptions &&
                (typeof cuisineOptions === 'string' ? [cuisineOptions] : [...cuisineOptions]),
              location: { address, building },
              tableNotes,
              exceptionEndDate,
              exceptionEndTime,
              exceptionStartDate,
              exceptionStartTime
            },
          };
          onSubmit({ ...createAvailabilityPlan(defaultAvailabilityPlan), ...updateValues });
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        setShowAvailabilityModal={setShowAvailabilityModal}
        setShowPaymentModal={setShowPaymentModal}
        reservationStart={reservationStart}
        savedReservationDate={savedReservationDate}
        setShowPhotosModal={setShowPhotosModal}
        shouldShowPayoutDetails={shouldShowPayoutDetails}
        onSetShowPayoutDetails={onSetShowPayoutDetails}
        isPending={isPending}
        availabilityExceptions={availabilityExceptions}
      />
      <Modal
        id="EditListingWizard.availabilityModal"
        isOpen={showAvailabilityModal}
        onClose={_ => setShowAvailabilityModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {availabilityPanelComp}
      </Modal>{' '}
      <Modal
        id="EditListingWizard.paymentModal"
        isOpen={showPaymentModal}
        onClose={_ => setShowPaymentModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {currentListing.id ? (
          CreateReservationCheckoutPageComp
        ) : (
          <>
            <div className={css.modalTitle}>Please save your reservation prior to payment.</div>
            <div className={css.modalMessage}>
              In order to publish this reservation, you must save the details and accept the terms
              of use.
            </div>
          </>
        )}
      </Modal>{' '}
      <Modal
        id="EditListingWizard.photosModal"
        isOpen={showPhotosModal}
        onClose={_ => setShowPhotosModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {photoPanelComp}
      </Modal>{' '}
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  availabilityExceptions:array
};

export default EditListingDescriptionPanel;
