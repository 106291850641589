import classNames from 'classnames';
import PropTypes, { number } from 'prop-types';
import React, { Component,useEffect } from 'react';
import { IconSpinner, ResponsiveImage } from '../../components';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './CardCarousel.css';
import Card from './Card';
const KEY_CODE_LEFT_ARROW = 37;
const KEY_CODE_RIGHT_ARROW = 39;

class CardCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedImageIndex: 0, selectedImageLoaded: false };
    this.onKeyUp = this.onKeyUp.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
  }
  componentDidMount() {
    window.addEventListener('keyup', this.onKeyUp);
    this.setState(prevState => {
     return { selectedImageLoaded:true}
    });
    
  }
  componentWillUnmount() {
    window.removeEventListener('keyup', this.onKeyUp);
  }
  onKeyUp(e) {
    if (e.keyCode === KEY_CODE_LEFT_ARROW) {
      this.prev();
    } else if (e.keyCode === KEY_CODE_RIGHT_ARROW) {
      this.next();
    }
  }
  prev() {
    const count = this.props.count;
    if (count < 2) {
      return;
    }
    this.setState(prevState => {
      const newIndex = count > 0 ? (count + prevState.selectedImageIndex - 1) % count : 0;
      return { selectedImageIndex: newIndex, selectedImageLoaded: false };
    });
  }
  next() {
    const count = this.props.count;
    if (count < 2) {
      return;
    }
    this.setState(prevState => {
      const newIndex = count > 0 ? (count + prevState.selectedImageIndex + 1) % count : 0;
      return { selectedImageIndex: newIndex, selectedImageLoaded: false };
    });
  }
  render() {
    const { rootClassName, className, images, intl, count, data } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const naturalIndex = this.state.selectedImageIndex + 1;
    const imageIndex =
      count > 0 ? (
        <span className={css.imageIndex}>
          {naturalIndex}/{count}
        </span>
      ) : null;
    const prevButton = count > 1 ? <button className={css.prev} onClick={this.prev} /> : null;
    const nextButton = count > 1 ? <button className={css.next} onClick={this.next} /> : null;

    const imageAltText = intl.formatMessage(
      {
        id: 'CardCarousel.imageAltText',
      },
      {
        index: naturalIndex,
        count: count,
      }
    );

    const markImageLoaded = index => () => {
      this.setState(prevState => {
        if (prevState.selectedImageIndex === index) {
          // Only mark the image loaded if the current index hasn't
          // changed, i.e. user hasn't already changed to another
          // image index.
          return { selectedImageLoaded: true };
        }
        return {};
      });
    };

   

    const currentImageIsLoaded = count === 0 || this.state.selectedImageLoaded;
    const loadingIconClasses = classNames(css.loading, {
      [css.loadingVisible]: !currentImageIsLoaded,
    });
    const imageClasses = classNames(css.image, {
      [css.imageLoading]: !currentImageIsLoaded,
    });
    return (
      <div className={classes}>
        <div className={css.imageWrapper}>
          <IconSpinner className={loadingIconClasses} />
          <Card
            className={imageClasses}
            index={this.state.selectedImageIndex}
            data={data[this.state.selectedImageIndex]}
            onLoad={()=>{
              console.log('here');
              this.setState(prevState => {
                if (prevState.selectedImageIndex === this.state.selectedImageIndex) {
                    return { selectedImageLoaded: true };
                }
                return {};
              });
            }}
            onChange={markImageLoaded(this.state.selectedImageIndex)}
          />
        </div>
        {/* {imageIndex} */}
        {prevButton}
        {nextButton}
      </div>
    );
  }
}

CardCarousel.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string, arrayOf } = PropTypes;

CardCarousel.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image),
  count: number,
  data: PropTypes.array,
  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(CardCarousel);
