import classNames from 'classnames';
import { arrayOf, bool, func, object, string } from 'prop-types';
import React from 'react';
import { IconSpinner, ListingLink } from '../../components';
import { EditListingAvailabilityExceptionForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import css from './EditListingAvailabilityPanel.css';

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = props => {
  const {
    listing,
    availabilityExceptions,
    addExceptionInProgress,
    fetchExceptionsInProgress,
    onDeleteAvailabilityException,
    onSubmit,
    updateInProgress,
    errors,
  } = props;
  // Hooks
  const classes = classNames(css.root);
  const currentListing = ensureOwnListing(listing);
  const { title } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const handleExceptionSubmit = vals => {
    const displayDate = new Date(parseInt(vals.exceptionStartTime));
    onSubmit({ ...vals, displayDate });
  };
  const initialValuesException =
    !!availabilityExceptions.length && !!availabilityExceptions[0]
      ? {
          seats: 1,
          exceptionStartDate: { date: new Date(availabilityExceptions[0].attributes.start) },
          exceptionStartTime: String(availabilityExceptions[0].attributes.start.getTime()),
          exceptionEndDate: { date: new Date(availabilityExceptions[0].attributes.end) },
          exceptionEndTime: String(availabilityExceptions[0].attributes.end.getTime()),
        }
      : null;
  return (
    <main className={classes}>
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingAvailabilityPanel.title"
            values={{
              listingTitle: <ListingLink listing={listing}>{title}</ListingLink>,
            }}
          />
        ) : (
          <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
        )}
      </h1>

      <section className={css.section}>
        {fetchExceptionsInProgress || addExceptionInProgress ? (
          <div className={css.exceptionsLoading}>
            <IconSpinner />
          </div>
        ) : (
          <>
            <EditListingAvailabilityExceptionForm
              formId="EditListingAvailabilityExceptionForm"
              onSubmit={handleExceptionSubmit}
              onRemove={onDeleteAvailabilityException}
              timeZone={defaultTimeZone()}
              availabilityExceptions={availabilityExceptions}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              initialValues={initialValuesException}
            />
          </>
        )}
      </section>

      {errors.showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
        </p>
      ) : null}
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
