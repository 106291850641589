import classNames from 'classnames';
import React, { useState } from 'react';
import { NamedLink, IconClose } from '..';
import { formatMoney } from '../../util/currency';
import { dateIsAfter, get12hrTime } from '../../util/dates';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { getPropByName } from '../../util/userHelper';
import style from './SearchResultsCard.css';
import { LISTING_STATE_CLOSED, LISTING_STATE_PENDING_APPROVAL } from '../../util/types';
// import css from './SearchResultsCard.css';
export const LISTING_PAGE_PARAM_TYPE_DRAFT = 'draft';
export const LISTING_PAGE_PARAM_TYPE_EDIT = 'edit';
export const LISTING_STATE_DRAFT = 'draft';

const SearchResultsCard = props => {
  const {
    listing,
    intl,
    manageCard,
    css = {},
    onCloseListing,
    onOpenListing,
    onDeleteListing,
    page
  } = props;
  const getlistingFormattedPrice = price => {
    const formattedPrice = formatMoney(intl, price);
    return formattedPrice;
  };
  let bookingDisplayDate = listing?.attributes?.publicData?.displayDate || null;
  let bookingStartDate =
    listing?.attributes?.publicData?.reservationStart &&
    new Date(listing?.attributes?.publicData?.reservationStart) ;
    bookingStartDate = bookingStartDate == undefined  ?  new Date(bookingDisplayDate) : bookingStartDate;
  const displayDate = listing?.attributes?.publicData?.displayDate;
  const displayDateHours = displayDate && displayDate?.split(' ')?.[4]?.slice(0, 5);
  const bookingStartMon =
    bookingStartDate && bookingStartDate.toLocaleString('en-US', { month: 'long' });
  const bookingStartMonShort =
    bookingStartDate && bookingStartDate.toLocaleString('en-US', { month: 'short' });
  const bookingStartDay = bookingStartDate && bookingStartDate.getDate();
  const bookingStartYear = bookingStartDate && bookingStartDate.getFullYear();
  const newFormattedStartTime = displayDateHours
    ? get12hrTime(displayDateHours)
    : bookingStartDate && get12hrTime(bookingStartDate.toTimeString().slice(0, 5));
  const [closedListings, setClosedListings] = useState([]);
  const [deletedListings, setDeletedListings] = useState();
  const id = listing.id.uuid;
  const { title = '', state, price } = listing.attributes || {};
  const publicData = listing.attributes && listing.attributes.publicData;
  const { cuisineOptions, capacity, hasPaid } = publicData || {};
  const slug = createSlug(title);
  const isDraft = state === LISTING_STATE_DRAFT;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isManuallyClosed =
    getPropByName(listing, 'manuallyClosed') || closedListings.includes(listing.id.uuid);
  const isPastDate = dateIsAfter(new Date(), new Date(bookingStartDate));
  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const handleOpenCloseClick = () => {
    const { listing } = props;
    const { state } = listing.attributes;
    const isDraft = state === LISTING_STATE_DRAFT;
    const isClosed = state === LISTING_STATE_CLOSED;
    const isPending = state === LISTING_STATE_PENDING_APPROVAL;
    const isManuallyClosed = getPropByName(listing, 'manuallyClosed');
    const isPastDate = dateIsAfter(new Date(), new Date(bookingStartDate));
    if (isPending) {
      setDeletedListings(true);
      onDeleteListing(listing.id);
    } else if (
      !isDraft &&
      isClosed &&
      (isManuallyClosed || closedListings.includes(listing.id.uuid)) &&
      !isPastDate
    ) {
      setClosedListings([...closedListings.filter(l => l !== listing.id.uuid)]);
      onOpenListing(listing.id);
    } else if (!isDraft && !isClosed && !isPastDate) {
      setClosedListings([...closedListings, listing.id.uuid]);
      onCloseListing(listing.id);
    }
  };

  let formatDate = (
      <span className={css.dateHolderDate}>
         {bookingStartMon || 'XXX'} {bookingStartDay || 'XX'}, {bookingStartYear || 'XXXX'}
      </span>
  );
  
  if(page === 'listing'){
    formatDate = (
      <span className={css.dateHolderDate}>
        {bookingStartDay || 'XX'} {bookingStartMonShort || 'XXX'}
      </span>
    );
  }
  const tableBody = page === "listing" ? 
    (
    <tr className={classNames(css.tr, { [style.closedListing]: isClosed || isPastDate })}>
      <td className={css.td} data-label="Date">
        <NamedLink
          name={!manageCard ? 'ListingPage' : 'EditListingPage'}
          params={
            !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
          }
        >
          {formatDate && formatDate}
        </NamedLink>
      </td>
      <td className={css.td} data-label="Time">
        <NamedLink
          name={!manageCard ? 'ListingPage' : 'EditListingPage'}
          params={
            !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
          }
        >
          <div className={css.timeText}>{newFormattedStartTime || 'XX:XX'}</div>{' '}
        </NamedLink>
      </td>
      <td className={classNames(css.td, css.doubleCell)} data-label="Name">
        <NamedLink
          name={!manageCard ? 'ListingPage' : 'EditListingPage'}
          params={
            !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
          }
        >
          <div>
            {isClosed && !isManuallyClosed
              ? 'Sold - ' + title
              : isClosed && isManuallyClosed
              ? 'Closed - ' + title
              : isPastDate
              ? 'Past - ' + title
              : title}
          </div>
        </NamedLink>
      </td>
      <td className={css.td} data-label="$">
        <NamedLink
          name={!manageCard ? 'ListingPage' : 'EditListingPage'}
          params={
            !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
          }
        >
          {!hasPaid && manageCard
            ? 'NOT PAID'
            : (price && getlistingFormattedPrice(price)?.split('.')[0]) || '$$$$$'}
        </NamedLink>
      </td>
      <td className={classNames(css.td, css.hideSmall)} data-label="Table Size">
        <NamedLink
          name={!manageCard ? 'ListingPage' : 'EditListingPage'}
          params={
            !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
          }
        >
          {capacity || '?'}
        </NamedLink>
      </td>
      <td className={classNames(css.td, css.hideSmall, style.capitalize)} data-label="Cusine Type">
        <NamedLink
          name={!manageCard ? 'ListingPage' : 'EditListingPage'}
          params={
            !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
          }
        >
          {cuisineOptions || '?'}
        </NamedLink>
      </td>
      {onCloseListing && onOpenListing && (
        <td className={classNames(css.td, style.capitalize)} data-label="Open Or Close Listing">
          <div className={css.openCloseIcon} onClick={_ => handleOpenCloseClick()}>
            <IconClose />
          </div>
        </td>
      )}
    </tr>
    )
  :
    (
    <tr className={classNames(css.tr, { [style.closedListing]: isClosed || isPastDate })}>
      <td className={css.td} data-label="Date">
        <NamedLink
          name={!manageCard ? 'ListingPage' : 'EditListingPage'}
          params={
            !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
          }
        >
          {formatDate && formatDate}
        </NamedLink>
      </td>

      <td className={classNames(css.td /* , css.doubleCell */)} data-label="Name">
        <NamedLink
          name={!manageCard ? 'ListingPage' : 'EditListingPage'}
          params={
            !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
          }
        >
          <div>
            {isClosed && !isManuallyClosed
              ? 'Sold - ' + title
              : isClosed && isManuallyClosed
              ? 'Closed - ' + title
              : isPastDate
              ? 'Past - ' + title
              : title}
          </div>
        </NamedLink>
      </td>
      <td className={css.td} data-label="Time">
      <NamedLink
        name={!manageCard ? 'ListingPage' : 'EditListingPage'}
        params={
          !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
        }
      >
        <div className={css.timeText}>{displayDateHours != undefined ?  displayDateHours.slice(0,5) : 'XX:XX'}</div>{' '}
      </NamedLink>
    </td>

      <td className={classNames(css.td, css.hideSmall)} data-label="Table Size">
      <NamedLink
        name={!manageCard ? 'ListingPage' : 'EditListingPage'}
        params={
          !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
        }
      >
        {capacity || '?'}
      </NamedLink>
    </td>
      <td className={css.td} data-label="$">
      <NamedLink
        name={!manageCard ? 'ListingPage' : 'EditListingPage'}
        params={
          !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
        }
      >
        {!hasPaid && manageCard
          ? 'NOT PAID'
          : (price && getlistingFormattedPrice(price)?.split('.')[0]) || '$$$$$'}
      </NamedLink>
    </td>
      <td className={classNames(css.td, css.hideSmall, style.capitalize)} data-label="Cusine Type">
      <NamedLink
        name={!manageCard ? 'ListingPage' : 'EditListingPage'}
        params={
          !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
        }
      >
        {cuisineOptions || '?'}
      </NamedLink>
    </td>
      <td className={classNames(css.td, css.hideSmall, style.capitalize)} data-label="Cusine Type">
      <NamedLink
        className={css.snatchReservationButtonLink}
        name={!manageCard ? 'ListingPage' : 'EditListingPage'}
        params={
          !manageCard ? { id, slug } : { id, slug, type: editListingLinkType, tab: 'description' }
        }
      >
        <span className={css.snatchReservationButton}>
          <FormattedMessage id="UserNav.DetailPage" />
        </span>
      </NamedLink>
    </td>
    </tr>
    )

  return deletedListings ? null : (
    
    tableBody
      
      
    
  );
};

export default injectIntl(SearchResultsCard);
