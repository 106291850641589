import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { bool, string } from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import {
  Button,
  FieldTextInput,
  Form,
  IconEditField,
  IconCheckmark,
  IconClose,
} from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isSeller } from '../../util/userHelper';
import * as validators from '../../util/validators';
import css from './ProfileSettingsForm.css';

const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false, sectionFocused: null };
    this.submittedValues = {};
    this.setSectionFocused = this.setSectionFocused.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      // this.setState({ sectionFocused: 'bad' });
    }
  }

  setSectionFocused(val) {
    this.setState({ sectionFocused: val });
  }
  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            handleBecomeSeller,
            intl,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadInProgress,
            values,
          } = fieldRenderProps;
          const { sectionFocused } = this.state;

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

          const submitButton = (
            <button
              className={css.editActionButton}
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={pristineSinceLastSubmit}
              onClick={e => {
                e.preventDefault();

                this.setSectionFocused(null);
                handleSubmit(values);
              }}
            >
              <IconCheckmark />
            </button>
          );
          const cancelButton = (
            <button
              className={css.editActionButton}
              onClick={e => {
                e.preventDefault();
                console.log(e);
                this.setSectionFocused(null);
              }}
            >
              <IconClose />
            </button>
          );
          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer} id={'names'} ref={this.wrapperRef}>
                <h3 className={css.sectionTitle}>
                  <IconEditField
                    className={css.iconEdit}
                    clickFunc={_ => this.setSectionFocused('names')}
                  />{' '}
                  <FormattedMessage id="ProfileSettingsForm.yourName" />
                </h3>
                {sectionFocused === 'names' ? (
                  <>
                    <div className={css.nameContainer}>
                      <FieldTextInput
                        className={css.firstName}
                        type="text"
                        id="firstName"
                        name="firstName"
                        label={firstNameLabel}
                        placeholder={firstNamePlaceholder}
                        validate={firstNameRequired}
                      />
                      <FieldTextInput
                        className={css.lastName}
                        type="text"
                        id="lastName"
                        name="lastName"
                        label={lastNameLabel}
                        placeholder={lastNamePlaceholder}
                        validate={lastNameRequired}
                      />
                    </div>
                    {submitButton}
                    {cancelButton}
                  </>
                ) : (
                  <div>
                    {values.firstName} {values.lastName}
                  </div>
                )}
              </div>
              <div className={classNames(css.sectionContainer)} ref={this.wrapperRef}></div>
              {!isSeller(currentUser) && (
                <>
                  <div className={classNames(css.sectionContainer, css.lastSection)}>
                    <h3 className={css.sectionTitle}>
                      <FormattedMessage id="ProfileSettingsForm.becomeSeller" />
                    </h3>
                    <Button
                      className={css.becomeSellerButton}
                      onClick={e => {
                        e.preventDefault();
                        handleBecomeSeller();
                      }}
                    >
                      Become a Seller
                    </Button>
                    <p className={css.becomeSellerInfo}>
                      <FormattedMessage id="ProfileSettingsForm.becomeSellerInfo" />
                    </p>
                  </div>
                </>
              )}
              {submitError}
              {/* {submitButton} */}
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
