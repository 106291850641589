import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import css from './IconPeople.css';


const IconPeople = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="38.546"
      height="34.213"
      version="1.1"
      viewBox="0 0 42.985 54.188"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-123.91 -121.36)">
        <path
          d="m129.38 175.02c-0.68584-0.68585-0.66764-1.3759 0.14428-5.4687 2.232-11.252 6.4304-17.64 12.688-19.308 1.5717-0.41882 4.5556-0.45388 6.2177-0.0731 5.4491 1.2485 9.7219 6.8124 12.008 15.636 0.81124 3.1314 1.486 6.7351 1.486 7.9364 0 1.8977 0.83403 1.8063-16.491 1.8063-15.171 0-15.535-0.012-16.053-0.52917zm-14.023-4.2333c-0.65289-0.65289-0.65287-0.65498 0.0223-4.1538 1.8826-9.7562 5.468-15.264 10.83-16.638 2.8639-0.73369 6.5547-0.0578 8.5802 1.5714l0.77979 0.62721-1.2151 1.389c-1.4384 1.6443-3.5748 5.6537-4.5686 8.5738-0.59394 1.7453-2.2447 8.305-2.2551 8.9612-2e-3 0.10915-2.6225 0.19844-5.824 0.19844-5.4681 0-5.8529-0.0321-6.35-0.52916zm47.372-2.3151c-1.3975-6.562-3.6601-11.894-6.3815-15.038-1.2263-1.4168-1.1852-1.5331 0.91643-2.5964 2.0173-1.0206 4.4486-1.3991 6.5173-1.0143 6.1129 1.1368 9.8711 6.8 11.881 17.904 0.38852 2.1463 0.36412 2.4512-0.24497 3.0603-0.49685 0.49685-0.88194 0.52916-6.3058 0.52916h-5.7767zm-36.921-21.339c-2.9782-0.94434-5.5713-3.1431-6.8954-5.8467-0.72415-1.4786-0.77725-1.7963-0.77385-4.6302 3e-3 -2.7377 0.0744-3.1944 0.70949-4.556 1.2777-2.7394 3.9144-5.0409 6.7666-5.9064 1.5074-0.45742 4.4078-0.49777 6.0504-0.0842 1.3371 0.33669 1.325 0.21044 0.26349 2.7436-1.2906 3.0798-1.4662 6.3021-0.52582 9.647 0.54291 1.9312 1.142 3.0576 2.7232 5.1207l1.3227 1.7258-1.1162 0.65291c-2.4162 1.4133-6.0981 1.9028-8.5246 1.1334zm16.743-0.0399c-6.6326-1.608-11.096-8.1485-9.9995-14.652 1.2747-7.5576 8.4971-12.458 15.817-10.732 2.4642 0.58099 4.2196 1.5882 6.1739 3.5425 1.9533 1.9533 2.8715 3.5567 3.5102 6.1297 0.56305 2.268 0.54448 3.9528-0.0693 6.2908-1.2419 4.7302-4.9286 8.2663-9.8204 9.4192-2.0749 0.48902-3.6001 0.4895-5.6119 2e-3zm16.197-0.0919c-0.80036-0.2609-1.8962-0.73923-2.4351-1.063l-0.97987-0.58861 1.182-1.4511c3.7458-4.5988 4.5818-10.819 2.1144-15.731-0.37136-0.73936-0.6752-1.445-0.6752-1.5682 0-0.43537 2.8705-0.86129 4.9812-0.7391 7.4484 0.43118 12.121 8.4864 8.9453 15.422-2.2062 4.8189-8.0658 7.3706-13.133 5.7189z"
          strokeWidth=".26458"
        />
      </g>
    </svg>
  );
};

IconPeople.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconPeople.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconPeople;
