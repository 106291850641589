import classNames from 'classnames';
import { string } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { SectionSearchBlock } from '../../components';
import lounge_man_animated from './images/inside-table-gif3.gif';
import lounge_man_webm from './images/tablesnatch-animated.webm';
import css from './SectionHero.css';

const SectionHero = props => {
  const { rootClassName, className, location, history, isAuthenticated, isSeller } = props;
  const classes = classNames(rootClassName || css.root, className);
  /* const [webmSupported, setWebmSupported] = useState(true);
  useEffect(_ => {
    if (typeof window !== 'undefined') {
      const videoElement = document.querySelector('video');
      videoElement.addEventListener('error', () => {
        setWebmSupported(false);
      });
    }
  }, []); */
  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroContentLeft}>
          {/* <div className={css.logoTextWrapper}>
            <div className={css.logoText}>A Peer to Peer Marketplace For</div>
            <div className={css.logoText}>Buying and Selling Reservations</div>
          </div> */}
          <div className={css.sectionSearchBlockTitle}>
            <span className={css.redFancyFont}>Snatch!</span> that reservation
          </div>
          <div className={css.logoTextWrapper}>
            <div className={css.logoText}>Buy or sell reservations</div>
          </div>
        </div>
        <div className={css.heroContentRight}>
          {/* <SectionSearchBlock
            location={location}
            history={history}
            isAuthenticated={isAuthenticated}
            isSeller={isSeller}
          /> */}
          {/* {webmSupported ? (
            <video
              src={lounge_man_webm}
              className={css.heroContentImage}
              alt={'logo'}
              type="video/webm"
              autoplay="true"
              muted="true"
              loop="true"
              playsInline="true"
            />
          ) : ( */}
          <img src={lounge_man_animated} className={css.heroContentImage} alt={'logo'} />
          {/* )} */}
        </div>
      </div>
      <div className={css.heroContent}>
        <SectionSearchBlock
          location={location}
          history={history}
          isAuthenticated={isAuthenticated}
          isSeller={isSeller}
          showErrorMessage={false}
        />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
