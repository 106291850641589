import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Button, FieldSelect, FieldTextInput, Form } from '../../components';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import css from './EditListingFeaturesForm.css';



const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        cuisineOptions,
        values,
      } = formRenderProps;
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {/* <FieldCheckboxGroup className={css.features} id={name} name={name} options={options} /> */}
          {/* <FieldCheckboxGroup
            className={css.features}
            id={'cuisineOptions'}
            label={'Cuisine Options'}
            name={'cuisineOptions'}
            options={cuisineOptions}
          /> */}
          <FieldSelect
            id="cuisineOptions"
            name="cuisineOptions"
            className={css.features}
            label="What kind of cuisine is served?"
            validate={required('Please select a cuisine')}
          >
            <option disabled value="">
              Cuisine...
            </option>
            {cuisineOptions.map(tz => (
              <option key={tz.key} value={tz.key}>
                {tz.label}
              </option>
            ))}
          </FieldSelect>
          {values.cuisineOptions === 'other' && (
            <FieldTextInput
              id="cuisineOptionsOther"
              name="cuisineOptionsOther"
              className={css.features}
              type="text"
              // label={""}
              // placeholder={descriptionPlaceholderMessage}
            />
          )}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
