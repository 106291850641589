import classNames from 'classnames';
import { arrayOf, bool, func, shape, string, array } from 'prop-types';
import React, { useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import {
  Button,
  FieldSelect,
  FieldTextInput,
  Form,
  LocationAutocompleteInputField,
  FieldCurrencyInput,
  IconCalendar,
  IconCamera,
  FieldDateInput,
  IconArrowHead,
} from '../../components';
import config from '../../config';

import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { composeValidators, maxLength, required } from '../../util/validators';
import * as validators from '../../util/validators';
import { autocompletePlaceSelected, autocompleteSearchRequired } from '../../util/validators';
import css from './EditListingDescriptionForm.css';

import {
  dateIsAfter,
  findNextBoundary,
  get12hrTime,
  getEndHours,
  getMonthStartInTimeZone,
  getSharpHours,
  getStartHours,
  isDayMomentInsideRange,
  isInRange,
  isSameDay,
  localizeAndFormatTime,
  monthIdStringInTimeZone,
  nextMonthFn,
  prevMonthFn,
  resetToStartOfDay,
  timestampToDate,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  getDefaultTimeZoneOnBrowser,
} from '../../util/dates';
import { bookingDateRequired } from '../../util/validators';

const { Money } = sdkTypes;
const TABLE_SIZE = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const identity = v => v;

const TITLE_MAX_LENGTH = 60;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';
const TODAY = new Date();
const MAX_AVAILABILITY_EXCEPTIONS_RANGE = 365;

// Date formatting used for placeholder texts:
const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

// React-dates returns wrapped date objects
const extractDateFromFieldDateInput = dateValue =>
  dateValue && dateValue.date ? dateValue.date : null;

// Get first available start time as timestamp or null.
const firstAvailableStartTime = availableStartTimes =>
  availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
    ? availableStartTimes[0].timestamp
    : null;

const endOfAvailabilityExceptionRange = (timeZone, date) => {
  return resetToStartOfDay(date, timeZone, MAX_AVAILABILITY_EXCEPTIONS_RANGE - 1);
};

// Convert exceptions list to inverted array of time-ranges that are available for new exceptions.
const getAvailableTimeRangesForExceptions = (exceptions, timeZone) => {
  const lastBoundary = endOfAvailabilityExceptionRange(timeZone, TODAY);

  // If no exceptions, return full time range as free of exceptions.
  // if (!exceptions || exceptions.length < 1) {
  return [{ start: new Date(), end: lastBoundary }];
};

// Get available start times for new exceptions on given date.
const getAvailableStartTimes = (date, timeRangesOnSelectedDate, intl, timeZone) => {
  if (timeRangesOnSelectedDate.length === 0 || !timeRangesOnSelectedDate[0] || !date) {
    return [];
  }

  // Ensure 00:00 on correct time zone
  const startOfDate = resetToStartOfDay(date, timeZone);
  const nextDay = resetToStartOfDay(startOfDate, timeZone, 1);

  const allHours = timeRangesOnSelectedDate.reduce((availableHours, t) => {
    // time-range: start and end
    const { start, end } = t;

    // If the date is after start, use the date.
    // Otherwise use the start time.
    const startLimit = dateIsAfter(startOfDate, start) ? startOfDate : start;

    // If the end date is after "the next day", use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(end, nextDay) ? nextDay : end;

    const hours = getStartHours(intl, timeZone, startLimit, endLimit).filter(t => {
      return parseInt(t.timeOfDay.split(':')[0]) >= 7 && parseInt(t.timeOfDay.split(':')[0]) <= 22;
    });
    return availableHours.concat(hours);
  }, []);
  return allHours;
};

// Return exception's start time, if it happens after the beginning of last day of the time-range
//
// Selected end date: today, startOfSelectedEndDay: 00:00
// Time range starts: 10:00 (today)
// => 10:00 (today)
//
// Selected end date: today, startOfSelectedEndDay: 00:00
// Time range starts: 11:00 (yesterday)
// => 00:00 (today)
const startTimeOrStartOfSelectedEndDay = (selectedStartTimeAsDate, startOfSelectedEndDay) =>
  dateIsAfter(selectedStartTimeAsDate, startOfSelectedEndDay)
    ? selectedStartTimeAsDate
    : startOfSelectedEndDay;

// Return time-range's end, if it becomes before selected end date changes (next 00:00)
//
// Selected end date: today. dayAfterSelectedEnd: 00:00 (tomorrow)
// Time range ends: 12:00 (today)
// => 12:00 (today)
//
// Selected end date: today. dayAfterSelectedEnd: 00:00 (tomorrow)
// Time range ends: 14:00 (tomorrow)
// => 00:00 (tomorrow)
const timeRangeEndOrNextMidnight = (timeRangeEnd, dayAfterSelectedEnd) =>
  dateIsAfter(dayAfterSelectedEnd, timeRangeEnd) ? timeRangeEnd : dayAfterSelectedEnd;

// Get available end times for new exceptions on selected time range.
const getAvailableEndTimes = (
  selectedStartTime,
  selectedEndDate,
  selectedTimeRange,
  intl,
  timeZone
) => {
  if (!selectedTimeRange || !selectedEndDate || !selectedStartTime) {
    return [];
  }

  const timeRangeEnd = selectedTimeRange.end;
  const selectedStartTimeAsDate = timestampToDate(selectedStartTime);
  const isSingleDayRange = isSameDay(selectedStartTimeAsDate, selectedEndDate, timeZone);

  // Midnight of selectedEndDate
  const startOfSelectedEndDate = resetToStartOfDay(selectedEndDate, timeZone);
  // Next midnight after selectedEndDate
  const dayAfterSelectedEndDate = resetToStartOfDay(selectedEndDate, timeZone, 1);

  const limitStart = startTimeOrStartOfSelectedEndDay(
    selectedStartTimeAsDate,
    startOfSelectedEndDate
  );
  const limitEnd = timeRangeEndOrNextMidnight(timeRangeEnd, dayAfterSelectedEndDate);

  return isSingleDayRange
    ? getEndHours(intl, timeZone, limitStart, limitEnd)
    : getSharpHours(intl, timeZone, limitStart, limitEnd);
};

// Get time-ranges on given date.
const getTimeRanges = (timeRanges, date, timeZone) => {
  return timeRanges && timeRanges[0] && date
    ? timeRanges.filter(t => isInRange(date, t.start, t.end, 'day', timeZone))
    : [];
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
const getAllTimeValues = (
  intl,
  timeZone,
  timeRanges,
  selectedStartDate,
  selectedStartTime,
  selectedEndDate
) => {
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
        selectedStartDate,
        getTimeRanges(timeRanges, selectedStartDate, timeZone),
        intl,
        timeZone
      );
  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
    ? startTimes[0].timestamp
    : null;

  const startTimeAsDate = startTime ? timestampToDate(startTime) : null;

  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  const endDate = selectedEndDate
    ? selectedEndDate
    : startTimeAsDate
    ? new Date(findNextBoundary(timeZone, startTimeAsDate).getTime() - 1)
    : null;

  const selectedTimeRange = timeRanges.find(t => isInRange(startTimeAsDate, t.start, t.end));

  const endTimes = getAvailableEndTimes(startTime, endDate, selectedTimeRange, intl, timeZone);
  const endTime =
    endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp ? endTimes[0].timestamp : null;

  return { startTime, endDate, endTime, selectedTimeRange };
};

// Update current month and call callback function.
const onMonthClick = (currentMonth, setCurrentMonth, timeZone, onMonthChanged) => monthFn => {
  const updatedMonth = monthFn(currentMonth, timeZone);
  setCurrentMonth(updatedMonth);

  if (onMonthChanged) {
    const monthId = monthIdStringInTimeZone(updatedMonth, timeZone);
    onMonthChanged(monthId);
  }
};

// Format form's value for the react-dates input: convert timeOfDay to the local time
const formatFieldDateInput = timeZone => v =>
  v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : { date: v };

// Parse react-dates input's value: convert timeOfDay to the given time zone
const parseFieldDateInput = timeZone => v =>
  v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v;

// React Dates calendar needs isDayBlocked function as props
// We check if the day belongs to one of the available time ranges
const isDayBlocked = (availableTimeRanges, timeZone) =>
  availableTimeRanges
    ? day =>
        !availableTimeRanges.find(timeRange =>
          isDayMomentInsideRange(day, timeRange.start, timeRange.end, timeZone)
        )
    : () => false;

// Helper function, which changes form's state when exceptionStartDate input has been changed
const onExceptionStartDateChange = (value, timeRanges, props) => {
  const { timeZone, intl, form } = props;
  // reinit form
  // form.initialize({
  // exceptionStartDate: null,
  // exceptionStartTime: null,
  // exceptionEndDate: null,
  // exceptionEndTime: null,
  // });

  if (!value || !value.date) {
    form.batch(() => {
      // form.change('exceptionStartTime', null);
      // form.change('exceptionEndDate', { date: null });
      // form.change('exceptionEndTime', null);
    });
    return;
  }

  // This callback function (onExceptionStartDateChange) is called from react-dates component.
  // It gets raw value as a param - browser's local time instead of time in listing's timezone.
  const startDate = value.date;
  const timeRangesOnSelectedDate = getTimeRanges(timeRanges, startDate, timeZone);
  const { startTime, endDate, endTime } = getAllTimeValues(
    intl,
    timeZone,
    timeRangesOnSelectedDate,
    startDate
  );
  form.batch(() => {
    form.change('exceptionStartTime', startTime);
    form.change('exceptionEndDate', { date: endDate });
    form.change('exceptionEndTime', endTime);
  });
};

// Helper function, which changes form's state when exceptionStartTime select has been changed
const onExceptionStartTimeChange = (value, timeRangesOnSelectedDate, props) => {
  const { timeZone, intl, form, values } = props;
  const startDate = values.exceptionStartDate.date;
  const { endDate, endTime } = getAllTimeValues(
    intl,
    timeZone,
    timeRangesOnSelectedDate,
    startDate,
    value
  );

  form.batch(() => {
    form.change('exceptionEndDate', { date: endDate });
    form.change('exceptionEndTime', endTime);
  });
};

/////////////////
// Next & Prev //
/////////////////

// Components for the react-dates calendar
const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfAvailabilityExceptionRange(timeZone, TODAY)) ? null : (
    <IconArrowHead direction="right" size="small" />
  );
};
const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? (
    <IconArrowHead direction="left" size="small" />
  ) : null;
};

const EditListingDescriptionFormComponent = props => {
  const [currentMonth, setCurrentMonth] = React.useState(
    getMonthStartInTimeZone(TODAY, props.timeZone)
  );
  useEffect(
    _ => {
      const { hasPaid, hasBeenSaved } = props;
      // if (typeof window !== 'undefined' && hasBeenSaved && !hasPaid) {
      //   window.scrollTo(0, document.body.scrollHeight);
      // }
    },
    [props]
  );
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          setShowAvailabilityModal,
          setShowPaymentModal,
          hasPaid,
          hasBeenSaved,
          savedReservationDate,
          reservationStart,
          shouldShowPayoutDetails,
          onSetShowPayoutDetails,
          isClosed,
          setShowPhotosModal,
          onMonthChanged,
          availabilityExceptions,
          timeZone,
          initialValues,
        } = formRenderProps;

        const startDate =
          savedReservationDate && savedReservationDate.start
            ? savedReservationDate.start
            : reservationStart;

        const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
        const reservationUnderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.reservationUnder',
        });
        const locationMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.locationTitle',
        });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titlePlaceholder',
        });

        const reservationUnderPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.reservationUnderPlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleRequired',
        });
        const reservationUnderRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.reservationUnderRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionPlaceholder',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const addressPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressPlaceholder',
        });
        const addressRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressNotRecognized',
        });

        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.priceInputPlaceholder',
        });

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const classes = classNames(css.root, className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const isWithinDayOfReservation = false;
        // reservationStart && dateIsAfter(new Date(), fromStart) && !isPending;
        const submitDisabled =
          invalid ||
          disabled ||
          submitInProgress ||
          !!!startDate ||
          !!isClosed ||
          isWithinDayOfReservation;

        const menuPriceOptions = config.menuPrice;
        const cuisineOptions = findOptionsForSelectFilter('cuisines', config.custom.filters);
        const bookingStartMon =
          startDate && startDate.toLocaleString('en-US', { month: 'short' }).toUpperCase();
        const bookingStartDay = startDate && startDate.getDate();
        const displayDateHours =
          startDate &&
          startDate
            .toString()
            ?.split(' ')?.[4]
            ?.slice(0, 5);
        const newFormattedStartTime =
          displayDateHours && get12hrTime(displayDateHours?.toString().slice(0, 5));

        const { exceptionStartDate, exceptionStartTime, exceptionEndDate, exceptionEndTime } =
          (values !== {} && values) || initialValues || {};

        const exceptionStartDay = extractDateFromFieldDateInput(exceptionStartDate);
        const exceptionEndDay = extractDateFromFieldDateInput(exceptionEndDate);

        const startTimeDisabled = !exceptionStartDate;
        const endTimeDisabled = !exceptionStartDate || !exceptionStartTime || !exceptionEndDate;

        // Get all the available time-ranges for creating new AvailabilityExceptions
        const availableTimeRanges = getAvailableTimeRangesForExceptions(
          availabilityExceptions,
          timeZone
        );
        const timeRangesOnSelectedDate = getTimeRanges(
          availableTimeRanges,
          exceptionStartDay,
          timeZone
        );

        const availableStartTimesRaw = getAvailableStartTimes(
          exceptionStartDay,
          timeRangesOnSelectedDate,
          intl,
          timeZone
        );
        const availableStartTimes = availableStartTimesRaw.filter(t => {
          return (
            parseInt(t.timeOfDay.split(':')[0]) >= 7 && parseInt(t.timeOfDay.split(':')[0]) <= 22
          );
        });
        const { startTime, endDate, selectedTimeRange } = getAllTimeValues(
          intl,
          timeZone,
          timeRangesOnSelectedDate,
          exceptionStartDay,
          exceptionStartTime || firstAvailableStartTime(availableStartTimes),
          exceptionEndDay || exceptionStartDay
        );

        const availableEndTimes = getAvailableEndTimes(
          exceptionStartTime || startTime,
          exceptionEndDay || endDate,
          selectedTimeRange,
          intl,
          timeZone
        ).filter(t => {
          return (
            parseInt(t.timeOfDay.split(':')[0]) >= 7 && parseInt(t.timeOfDay.split(':')[0]) <= 22
          );
        });

        // Returns a function that changes the current month
        // Currently, used for hiding next&prev month arrow icons.
        const handleMonthClick = onMonthClick(
          currentMonth,
          setCurrentMonth,
          timeZone,
          onMonthChanged
        );

        const placeholderTime = localizeAndFormatTime(
          intl,
          timeZone,
          findNextBoundary(timeZone, TODAY)
        );
        const hasData =
          exceptionStartDate && exceptionStartTime && exceptionEndDate && exceptionEndTime;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            <div className={css.RestaurantDetailsBlock}>
              <h1 className={css.sectionTitle}>Restaurant Details</h1>
              <FieldTextInput
                id="title"
                name="title"
                className={css.title}
                type="text"
                label={titleMessage}
                placeholder={titlePlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
                autoFocus
                disabled={isClosed || isWithinDayOfReservation}
              />
              <LocationAutocompleteInputField
                className={css.locationAddress}
                inputClassName={css.locationAutocompleteInput}
                iconClassName={css.locationAutocompleteInputIcon}
                predictionsClassName={css.predictionsRoot}
                validClassName={css.validLocation}
                // autoFocus
                name="location"
                label={locationMessage}
                placeholder={addressPlaceholderMessage}
                useDefaultPredictions={false}
                format={identity}
                valueFromForm={values.location}
                validate={composeValidators(
                  autocompleteSearchRequired(addressRequiredMessage),
                  autocompletePlaceSelected(addressNotRecognizedMessage)
                )}
                disabled={isClosed || isWithinDayOfReservation}
              />

              <FieldSelect
                id="cuisineOptions"
                name="cuisineOptions"
                className={css.dropdownItem}
                rootClassName={css.title}
                label="What type of cuisine is served?"
                validate={required('Please select a cuisine')}
                disabled={isClosed || isWithinDayOfReservation}
              >
                <option disabled value="">
                  Choose the cuisine type
                </option>
                {cuisineOptions.map(tz => (
                  <option key={tz.key} value={tz.key}>
                    {tz.label}
                  </option>
                ))}
              </FieldSelect>
              {/*<FieldSelect
                id="menuPrice"
                name="menuPrice"
                className={css.dropdownItem}
                rootClassName={css.title}
                label="How expensive is the menu?"
                validate={required('Please select a price level')}
                disabled={isClosed || isWithinDayOfReservation}
              >
                <option disabled value="">
                  $$$...
                </option>
                {menuPriceOptions.map(tz => (
                  <option key={tz.key} value={tz.key}>
                    {tz.label}
                  </option>
                ))}
                </FieldSelect>*/}
              {/* <FieldTextInput
                id="description"
                name="description"
                className={css.description}
                type="textarea"
                label={'Snatchable! Elements'}
                placeholder={descriptionPlaceholderMessage}
                disabled={isClosed || isWithinDayOfReservation}
              />*/}

              {/* <div className={css.reservationDateSelection}>
                <IconCamera
                  wrapperClass={css.calendarIcon}
                  clickFunc={e => {
                    e.preventDefault();
                    if (!isWithinDayOfReservation && !isClosed) {
                      setShowPhotosModal(true);
                    }
                  }}
                  disabled={isClosed || isWithinDayOfReservation}
                />
                <div
                  className={css.linkCursor}
                  onClick={e => {
                    e.preventDefault();
                    if (!isWithinDayOfReservation && !isClosed) {
                      setShowPhotosModal(true);
                    }
                  }}
                >
                  Restaurant Photos
                </div> 
              </div>*/}
            </div>
            <div className={css.ReservationDetailsBlock}>
              <h1 className={css.sectionTitle}>Reservation Details</h1>
              <FieldTextInput
                id="reservationUnder"
                name="reservationUnder"
                className={css.ReservationNameLabel}
                type="text"
                label={reservationUnderMessage}
                placeholder={reservationUnderPlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(
                  required(reservationUnderRequiredMessage),
                  maxLength60Message
                )}
                disabled={isClosed || isWithinDayOfReservation}
              />
              <div className={css.reservationDateSelection}>
                {/*<IconCalendar
                  wrapperClass={css.calendarIcon}
                  clickFunc={e => {
                    e.preventDefault();
                    console.log(e);
                    if (!isWithinDayOfReservation && !isClosed) {
                      setShowAvailabilityModal(true);
                    }
                  }}
                  disabled={isClosed}
                />
                <div
                  className={css.linkCursor}
                  onClick={e => {
                    e.preventDefault();
                    if (!isWithinDayOfReservation && !isClosed) {
                      setShowAvailabilityModal(true);
                    }
                  }}
                > </div> */}
                <FieldDateInput
                  className={css.fieldDateInput}
                  name="exceptionStartDate"
                  id={`exceptionStartDate`}
                  label={intl.formatMessage({
                    id: 'EditListingAvailabilityExceptionForm.exceptionStartDateLabel',
                  })}
                  placeholderText={'Choose a date'}
                  format={formatFieldDateInput(timeZone)}
                  parse={parseFieldDateInput(timeZone)}
                  isDayBlocked={isDayBlocked(availableTimeRanges, timeZone)}
                  onChange={value => {
                    onExceptionStartDateChange(value, availableTimeRanges, formRenderProps);
                  }}
                  onPrevMonthClick={() => handleMonthClick(prevMonthFn)}
                  onNextMonthClick={() => handleMonthClick(nextMonthFn)}
                  navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
                  navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
                  useMobileMargins
                  showErrorMessage={true}
                  validate={bookingDateRequired('Date is required')}
                  // value={new Date()}
                />
                {/*startDate ? (
                      bookingStartMon + ' ' + bookingStartDay + ', ' + newFormattedStartTime
                      
                  ) : (
                  ''
                  )*/}
              </div>
              <div className={css.reservationDateSelection}>
                <FieldSelect
                  name="exceptionStartTime"
                  id={`exceptionStartTime`}
                  label={'Time'}
                  className={exceptionStartDate ? css.fieldSelect : css.fieldSelectDisabled}
                  selectClassName={exceptionStartDate ? css.select : css.selectDisabled}
                  disabled={startTimeDisabled}
                  onChange={value =>
                    onExceptionStartTimeChange(value, timeRangesOnSelectedDate, formRenderProps)
                  }
                >
                  {exceptionStartDay ? (
                    availableStartTimes.map(p => {
                      return (
                        <option key={p.timestamp} value={p.timestamp}>
                          {get12hrTime(p.timeOfDay)}
                        </option>
                      );
                    })
                  ) : (
                    <option>{'Choose a time'}</option>
                  )}
                </FieldSelect>
                {/*<FieldSelect
                  name="exceptionEndTime"
                  id={`exceptionEndTime`}
                  className={exceptionStartDate ? css.fieldSelect : css.fieldSelectDisabled}
                  selectClassName={exceptionStartDate ? css.select : css.selectDisabled}
                  disabled={endTimeDisabled}
                >
                  {exceptionStartDay && exceptionStartTime && endDate ? (
                    availableEndTimes.map((p, i) => {
                      const isLastIndex = i === availableEndTimes.length - 1;
                      const timeOfDay =
                        p.timeOfDay === '00:00' && isLastIndex ? '24:00' : p.timeOfDay;
                      return (
                        <option key={p.timestamp} value={p.timestamp}>
                          {timeOfDay}
                        </option>
                      );
                    })
                  ) : (
                    <option>{placeholderTime}</option>
                  )}
                  </FieldSelect>*/}
              </div>
              {(isWithinDayOfReservation || isClosed) && (
                <p>** This reservation date can no longer be changed.</p>
              )}

              <FieldSelect
                id="capacity"
                name="capacity"
                className={css.dropdownItem}
                rootClassName={css.title}
                label={'Table Size'}
                validate={required(' Please select table size ')}
                disabled={isClosed || isWithinDayOfReservation}
              >
                <option disabled value="">
                  Choose a table size
                </option>
                {TABLE_SIZE.map(tz => (
                  <option key={tz} value={tz}>
                    {tz}
                  </option>
                ))}
              </FieldSelect>

              <div className={css.priceSection}>
                <FieldCurrencyInput
                  id="price"
                  name="price"
                  className={css.dropdownItem}
                  autoFocus
                  label={'Selling Price'}
                  placeholder={pricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                  validate={priceValidators}
                  disabled={isClosed || isWithinDayOfReservation}
                />
              </div>
              <FieldTextInput
                id="tableNotes"
                name="tableNotes"
                className={css.description}
                type="textarea"
                label={'Notes/Selling Points'}
                placeholder={'e.g. The reservation is for an outside table/This resturant has the best steaks in town '}
                disabled={isClosed || isWithinDayOfReservation}
              />
                {/*<div className={css.helperText}>If fixed price, list price per person</div>*/}

              <div className={css.buttonDisplayWrapper}>
                <Button
                  className={css.submitButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                  onClick={e => {
                    e.preventDefault();
                    if (!hasBeenSaved) {
                      handleSubmit({ ...values, displayDate: startDate });
                      setShowPaymentModal(true);
                    } else if (!hasPaid) {
                      if (shouldShowPayoutDetails) {
                        onSetShowPayoutDetails(true);
                      } else {
                        if (pristine) {
                          setShowPaymentModal(true);
                        } else {
                          handleSubmit({ ...values, displayDate: startDate });
                        }
                      }
                    } else {
                      handleSubmit({ ...values, displayDate: startDate });
                    }
                  }}
                >
                  {isWithinDayOfReservation || isClosed
                    ? 'Listing Closed'
                    : !hasPaid && !pristine
                    ? 'Submit'
                    : saveActionMsg}
                </Button>
                {hasBeenSaved && !hasPaid && (
                  <div className={css.pleasePayMessage}>
                    Please process the payment for listings this reservation, it will not appear to
                    other users until you have completed this step.
                  </div>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};
EditListingDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  timeZone: defaultTimeZone(),
  availabilityExceptions: [],
};

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  cuisineOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  availabilityExceptions: array,
  timeZone: string,
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
