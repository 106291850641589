import { bool, func,  object, oneOfType, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  Logo,
  NamedLink,
  Page,
  ModalConfirmTerms,
} from '../../components';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { retrievePaymentIntent } from '../../ducks/stripe.duck';
import { StripePaymentFormNoMessage } from '../../forms';
import {
  ensureCurrentUser,
  ensureListing,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureUser,
} from '../../util/data';
import { transactionInitiateOrderStripeErrors } from '../../util/errors';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import { getPropByName } from '../../util/userHelper';
import css from './CreateReservationCheckoutPage.css';
import {
  confirmCardPayment,
  initiateOrder,
  approveListingPromoCode,
  getPromoCode,
  sendMessage,
  stripeCustomer,
} from './CreateReservationCheckoutPage.duck';
import TermsOfService from './TermsOfService';
import config from '../../config';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

export class CreateReservationCheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,

      isConfirmationModalOpen: false,
      hasReadTermsConfirmed: false,
      hasPromCodeError: false,
      hasPromCodeSuccess: false,
      discountPercentage: null,
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  /**
   * Load initial data for the page
   */
  loadInitialData() {
    const { fetchStripeCustomer } = this.props;

    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();
    this.setState({ dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      listing,
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onConfirmCardPayment,
      onSavePaymentMethod,
    } = this.props;
    const {
      card,
      stripe,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
      billingDetails,
    } = handlePaymentParams;

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripeId = ensuredStripeCustomer?.attributes?.stripeCustomerId;
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);
    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = async fnParams => {
      const res = await onInitiateOrder(fnParams);
      return { ...res, payment_method: fnParams.paymentMethod };
    };

    // Step 2: pay using Stripe SDK
    const fnConfirmCardPayment = async fnParams => {
      const order = fnParams;
      const hasPaymentIntents = !!order;
      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }
      const confirmationResult = await onConfirmCardPayment({
        client_secret: order.client_secret,
        payment_method:
          order.payment_method === 'replaceCard'
            ? { card, billing_details: billingDetails }
            : order.payment_method,
        stripe,
      });
      return confirmationResult;
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnConfirmCardPayment,
      fnSavePaymentMethod
    );
    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.confirmCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId, stripeId: stripeId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { paymentMethod: selectedPaymentMethod, setupPaymentMethodForSaving: true }
        : { paymentMethod: selectedPaymentMethod };

    const orderParams = {
      listingId: listing.id.uuid,
      promocode: handlePaymentParams.promocode,
      // card,

      ...optionalPaymentParams,
    };

    return handlePaymentIntentCreation(orderParams);
  }

  handleSkipPaymentIntent(handlePaymentParams) {
    const {
      listing,
      currentUser,
      stripeCustomerFetched,
      //onInitiateOrder,
      onApproveListingPromoCode,
      onGetPromoCode,
      //onConfirmCardPayment,
      //onSavePaymentMethod,
    } = this.props;
    const {
      //card,
      //stripe,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
      //billingDetails,
      promocode,
    } = handlePaymentParams;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    //let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripeId = ensuredStripeCustomer?.attributes?.stripeCustomerId;
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: get Promo Codes
    const fnRequestPromoCodes = async fnParams => {
      const res = await onGetPromoCode(fnParams);
      if(res.promoCodeEActive) {
        // Step 2: approve listing if promocode match
        const res = await onApproveListingPromoCode(fnParams);
        return { ...res };
      }
      return { ...res };
      //return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
    };

    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentssCreation = composeAsync(
      fnRequestPromoCodes
    );

    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId, stripeId: stripeId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { paymentMethod: selectedPaymentMethod, setupPaymentMethodForSaving: true }
        : { paymentMethod: selectedPaymentMethod };

    const orderParams = {
      listingId: listing.id.uuid,
      promocode: promocode,
      // card,

      ...optionalPaymentParams,
    };

    return handlePaymentIntentssCreation(orderParams);
  }

  handleGetPromoCodes(handlePaymentParams) {
    const {
      listing,
      //currentUser,
      //stripeCustomerFetched,
      //onInitiateOrder,
      //onApproveListingPromoCode,
      onGetPromoCode,
      //onConfirmCardPayment,
      //onSavePaymentMethod,
    } = this.props;
    const getPromoCodes = handlePaymentParams.getPromoCodes;
    const promoCodeValue = handlePaymentParams.promoCodeValue;
  
    // Step 1: get Promo Codes
    const fnRequestPromoCodes = async fnParams => {
      const res = await onGetPromoCode(fnParams);
      // if(res.promoCodeEActive) {
      //   // Step 2: approve listing if promocode match
      //   const res = await onApproveListingPromoCode(fnParams);
      //   return { ...res };
      // }
      return { ...res };
    };

    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handleGetPromoCodes = composeAsync(
      fnRequestPromoCodes
    );

    const orderParams = {
      listingId: listing.id.uuid,
      getPromoCodes: getPromoCodes,
      promocode: promoCodeValue,
    };

    return handleGetPromoCodes(orderParams);
  }

  handleSubmit(values) {

    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { currentUser } = this.props;
    const { card, paymentMethod, formValues } = values;

    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: this.state.pageData,
      stripe: this.stripe,
      card,
      billingDetails,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      promocode: formValues.promocode,
    };
    //Check for Promo Code and Validate PromoCode for expiration date from get-promo-code API Check
    const fnPromoCode = formValues.promocode;
    const handleGetPromoCode = formValues.getPromoCode;
    const promoCodeValue = formValues.promoCodeValue;
    const postalValue = formValues.postal;
    if(fnPromoCode && !postalValue) {
        this.handleSkipPaymentIntent(requestPaymentParams)
        .then(res => {
          if (res.status === 200) {
            this.setState({ submitting: false });
            this.props.onSubmit({
            // privateData: { paymentIntent: res.paymentIntent },
              publicData: {
                hasPaid: true,
                hasReadTermsConfirmed: this.state.hasReadTermsConfirmed,
                hasAgreedToTermsOn: new Date().toDateString(),
              },
            });
          } else {
            this.setState({ hasPromCodeError: true });
            this.props.onSubmit({
              //privateData: { paymentIntent: res },
              publicData: {
                //hasPromCodeError: this.state.hasReadTermsConfirmed,
                hasReadTermsConfirmed: this.state.hasReadTermsConfirmed,
                hasAgreedToTermsOn: new Date().toDateString(),
              },
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ submitting: false });
        });

    } else if(handleGetPromoCode) {
          this.handleGetPromoCodes({'getPromoCodes':handleGetPromoCode,'promoCodeValue':promoCodeValue})
          .then(res => {
            if (res.promoCodeEActive) {
              this.setState({ submitting: false });
              this.setState({ hasPromCodeSuccess: true });
              this.setState({ hasPromCodeError: false });
              this.setState({ discountPercentage: res.discountPercentage });
              localStorage.setItem('discountPerVariable', res.discountPercentage);
              
            } else {
              if(promoCodeValue !== 'handlelistingfee') {
                this.setState({ hasPromCodeError: true });
              } else {
                this.setState({ hasPromCodeError: false });
              }

              this.setState({ discountPercentage: null });
              this.setState({ hasPromCodeSuccess: false });
              this.setState({ submitting: false });
              localStorage.setItem('discountPerVariable', null);
            }
          })
          .catch(err => {
            console.error(err);
            this.setState({ submitting: false });
          });
    } else {
        this.handlePaymentIntent(requestPaymentParams)
        .then(res => {
          if (res?.paymentIntent?.status === 'succeeded') {
            this.setState({ submitting: false });
            this.props.onSubmit({
              privateData: { paymentIntent: res.paymentIntent },
              publicData: {
                hasPaid: true,
                hasReadTermsConfirmed: this.state.hasReadTermsConfirmed,
                hasAgreedToTermsOn: new Date().toDateString(),
              },
            });
          } else {
            this.props.onSubmit({
              privateData: { paymentIntent: res },
              publicData: {
                hasReadTermsConfirmed: this.state.hasReadTermsConfirmed,
                hasAgreedToTermsOn: new Date().toDateString(),
              },
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ submitting: false });
        });
    }

  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      initiateOrderError,
      confirmPaymentError,
      intl,
      listing,
      currentUser,
      confirmCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      submitButtonText,
    } = this.props;
    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);
    const hasPaid =
      getPropByName(currentListing, 'hasPaid') === true ||
      getPropByName(currentListing, 'hasPaid')?.[0] === true ||
      getPropByName(currentListing, 'hasConfirmedPaid') === true ||
      getPropByName(currentListing, 'hasConfirmedPaid')?.[0] === true;

    const hasPromCodeError = this.state.hasPromCodeError;
    const hasPromCodeSuccess = this.state.hasPromCodeSuccess;
    let discountPercentage = this.state.discountPercentage;
    let finalListingFee = parseFloat(config.listingPostFee) / 100;
    let calc_listing_fee = parseFloat(config.listingPostFee) / 100;

    if(hasPromCodeSuccess) {
      let total_disc_percen = ( parseFloat(calc_listing_fee) * parseFloat(discountPercentage) ) / 100;
      finalListingFee = parseFloat(calc_listing_fee) - parseFloat(total_disc_percen);
    }
    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage(
      { id: 'CreateReservationCheckoutPage.title' },
      { listingTitle }
    );

    const pageProps = { title, scrollingDisabled };
    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'CreateReservationCheckoutPage.goToLandingPage' })}
            format="mobile"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CreateReservationCheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasRequiredData = hasListingAndAuthor;
    // const shouldRedirect = !isLoading && !canShowPage;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !initiateOrderError &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !hasPaid 
      //&& !hasPromCodeError
    );


    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CreateReservationCheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CreateReservationCheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CreateReservationCheckoutPage.initiateOrderError"
            values={{ listingLink }}
          />
        </p>
      );
    }

    const showInitialMessageInput = true;

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // confirmCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const initalValuesForStripePayment = { name: userName };

    const setIsConfirmationModalOpen = val => {
      this.setState({ isConfirmationModalOpen: val });
    };

    const setHasReadTermsConfirmed = val => {
      this.setState({ hasReadTermsConfirmed: val });
    };
    return (
      <>
        <div className={css.contentContainer}>
          <div className={css.bookListingContainer}>
            <div className={css.heading}>
              <h1 className={css.title}>
                {/* {hasPaid
                  ? `Your reservation at ${listingTitle} has been posted.`
                  : hasPromCodeError
                  ? `Invalid Promo Code`
                  : 'Listing Payment'} */}
                  {hasPaid
                  ? `Your reservation at ${listingTitle} has been posted.`
                  : 'Listing Payment'}
              </h1>
            </div>

            {showPaymentForm && (
              <div className={css.priceBreakdownContainer}>
                <div className={css.lineItemTotal}>
                  <div className={css.totalLabel}>Reservation Fee:</div>
                  <div className={css.totalPrice}>${finalListingFee}</div>
                </div>
              </div>
            )}
            <section className={css.paymentContainer}>
              {initiateOrderErrorMessage}
              {listingNotFoundErrorMessage}
              {retrievePaymentIntentError ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CreateReservationCheckoutPage.retrievingStripePaymentIntentFailed"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
              {!stripeCustomerFetched ? (
                <h2>Please confirm your stripe account in your Snatcher! Page</h2>
              ) : showPaymentForm ? (
                <StripePaymentFormNoMessage
                  className={css.paymentForm}
                  onSubmit={this.handleSubmit}
                  inProgress={this.state.submitting}
                  formId="CreateReservationCheckoutPagePaymentForm"
                  paymentInfo={intl.formatMessage({
                    id: 'CreateReservationCheckoutPage.paymentInfo',
                  })}
                  authorDisplayName={currentAuthor.attributes.profile.displayName}
                  showInitialMessageInput={showInitialMessageInput}
                  initialValues={initalValuesForStripePayment}
                  initiateOrderError={initiateOrderError}
                  hasPromCodeError={hasPromCodeError}
                  hasPromCodeSuccess={hasPromCodeSuccess}
                  discountPercentage={discountPercentage}
                  confirmCardPaymentError={confirmCardPaymentError}
                  confirmPaymentError={confirmPaymentError}
                  hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                  loadingData={!stripeCustomerFetched}
                  defaultPaymentMethod={
                    hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
                  }
                  paymentIntent={paymentIntent}
                  onStripeInitialized={this.onStripeInitialized}
                  submitButtonText={submitButtonText}
                  setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                  hasReadTermsConfirmed={this.state.hasReadTermsConfirmed}
                />
              ) : null}
              {hasPaid && (
                <>
                  Check your postings periodically. <br />
                  <br />
                  We will send you an email when your reservation is sold.
                </>
              )}
            </section>
          </div>
        </div>
        <ModalConfirmTerms
          isConfirmationModalOpen={this.state.isConfirmationModalOpen}
          setIsConfirmationModalOpen={setIsConfirmationModalOpen}
          confirmationFlag={this.state.hasReadTermsConfirmed}
          setConfirmationFlag={setHasReadTermsConfirmed}
          onManageDisableScrolling={e => null}
          content={<TermsOfService />}
          // onManageDisableScrolling={onManageDisableScrolling}
        />
      </>
      // </Page>
    );
  }
}

CreateReservationCheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

CreateReservationCheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }),
  onInitiateOrder: func.isRequired,
  onApproveListingPromoCode: func.isRequired,
  onGetPromoCode: func.isRequired,
  onConfirmCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  confirmCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    stripeCustomerId,
  } = state.CreateReservationCheckoutPage;
  const { currentUser } = state.user;
  const { confirmCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    stripeCustomerId,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onApproveListingPromoCode: params => dispatch(approveListingPromoCode(params)),
  onGetPromoCode: params => dispatch(getPromoCode(params)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onConfirmCardPayment: params => dispatch(confirmCardPayment(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const CreateReservationCheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CreateReservationCheckoutPageComponent);

CreateReservationCheckoutPage.displayName = 'CreateReservationCheckoutPage';

export default CreateReservationCheckoutPage;
