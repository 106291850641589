import classNames from 'classnames';
import { func, string } from 'prop-types';
import React from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import {
  Button,
  Form,
  FieldDateInput,
  LocationAutocompleteInput,
  IconArrowHead,
  ValidationError,
} from '../../components';
import { injectIntl, intlShape } from '../../util/reactIntl';
import css from './LocationSearchForm.css';
import { bookingDateRequired, locationRequired } from '../../util/validators';

import {
  dateIsAfter,
  findNextBoundary,
  get12hrTime,
  getEndHours,
  getMonthStartInTimeZone,
  getSharpHours,
  getStartHours,
  isDayMomentInsideRange,
  isInRange,
  isSameDay,
  localizeAndFormatTime,
  monthIdStringInTimeZone,
  nextMonthFn,
  prevMonthFn,
  resetToStartOfDay,
  timestampToDate,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
} from '../../util/dates';

const TODAY = new Date();
const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };
const MAX_AVAILABILITY_EXCEPTIONS_RANGE = 365;

// Format form's value for the react-dates input: convert timeOfDay to the local time
const formatFieldDateInput = timeZone => v =>
  v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : { date: v };
// Helper function, which changes form's state when exceptionStartTime select has been changed
const onExceptionStartTimeChange = (value, timeRangesOnSelectedDate, props) => {
  const { timeZone, intl, form, values } = props;
  const startDate = values.exceptionStartDate.date;
  const { endDate, endTime } = getAllTimeValues(
    intl,
    timeZone,
    timeRangesOnSelectedDate,
    startDate,
    value
  );

  form.batch(() => {
    form.change('exceptionEndDate', { date: endDate });
    form.change('exceptionEndTime', endTime);
  });
};

/////////////////
// Next & Prev //
/////////////////

// Components for the react-dates calendar
const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfAvailabilityExceptionRange(timeZone, TODAY)) ? null : (
    <IconArrowHead direction="right" size="small" />
  );
};
const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? (
    <IconArrowHead direction="left" size="small" />
  ) : null;
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
const getAllTimeValues = (
  intl,
  timeZone,
  timeRanges,
  selectedStartDate,
  selectedStartTime,
  selectedEndDate
) => {
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
        selectedStartDate,
        getTimeRanges(timeRanges, selectedStartDate, timeZone),
        intl,
        timeZone
      );
  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
    ? startTimes[0].timestamp
    : null;

  const startTimeAsDate = startTime ? timestampToDate(startTime) : null;

  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  const endDate = selectedEndDate
    ? selectedEndDate
    : startTimeAsDate
    ? new Date(findNextBoundary(timeZone, startTimeAsDate).getTime() - 1)
    : null;

  const selectedTimeRange = timeRanges.find(t => isInRange(startTimeAsDate, t.start, t.end));

  const endTimes = getAvailableEndTimes(startTime, endDate, selectedTimeRange, intl, timeZone);
  const endTime =
    endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp ? endTimes[0].timestamp : null;

  return { startTime, endDate, endTime, selectedTimeRange };
};

// React-dates returns wrapped date objects
const extractDateFromFieldDateInput = dateValue =>
  dateValue && dateValue.date ? dateValue.date : null;

// Get first available start time as timestamp or null.
const firstAvailableStartTime = availableStartTimes =>
  availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
    ? availableStartTimes[0].timestamp
    : null;

const endOfAvailabilityExceptionRange = (timeZone, date) => {
  return resetToStartOfDay(date, timeZone, MAX_AVAILABILITY_EXCEPTIONS_RANGE - 1);
};

// Get time-ranges on given date.
const getTimeRanges = (timeRanges, date, timeZone) => {
  return timeRanges && timeRanges[0] && date
    ? timeRanges.filter(t => isInRange(date, t.start, t.end, 'day', timeZone))
    : [];
};

const timeRangeEndOrNextMidnight = (timeRangeEnd, dayAfterSelectedEnd) =>
  dateIsAfter(dayAfterSelectedEnd, timeRangeEnd) ? timeRangeEnd : dayAfterSelectedEnd;

// Get available end times for new exceptions on selected time range.
const getAvailableEndTimes = (
  selectedStartTime,
  selectedEndDate,
  selectedTimeRange,
  intl,
  timeZone
) => {
  if (!selectedTimeRange || !selectedEndDate || !selectedStartTime) {
    return [];
  }

  const timeRangeEnd = selectedTimeRange.end;
  const selectedStartTimeAsDate = timestampToDate(selectedStartTime);
  const isSingleDayRange = isSameDay(selectedStartTimeAsDate, selectedEndDate, timeZone);

  // Midnight of selectedEndDate
  const startOfSelectedEndDate = resetToStartOfDay(selectedEndDate, timeZone);
  // Next midnight after selectedEndDate
  const dayAfterSelectedEndDate = resetToStartOfDay(selectedEndDate, timeZone, 1);

  const limitStart = startTimeOrStartOfSelectedEndDay(
    selectedStartTimeAsDate,
    startOfSelectedEndDate
  );
  const limitEnd = timeRangeEndOrNextMidnight(timeRangeEnd, dayAfterSelectedEndDate);

  return isSingleDayRange
    ? getEndHours(intl, timeZone, limitStart, limitEnd)
    : getSharpHours(intl, timeZone, limitStart, limitEnd);
};

// Convert exceptions list to inverted array of time-ranges that are available for new exceptions.
const getAvailableTimeRangesForExceptions = (exceptions, timeZone) => {
  const lastBoundary = endOfAvailabilityExceptionRange(timeZone, TODAY);

  // If no exceptions, return full time range as free of exceptions.
  // if (!exceptions || exceptions.length < 1) {
  return [{ start: new Date(), end: lastBoundary }];
};

// Get available start times for new exceptions on given date.
const getAvailableStartTimes = (date, timeRangesOnSelectedDate, intl, timeZone) => {
  if (timeRangesOnSelectedDate.length === 0 || !timeRangesOnSelectedDate[0] || !date) {
    return [];
  }

  // Ensure 00:00 on correct time zone
  const startOfDate = resetToStartOfDay(date, timeZone);
  const nextDay = resetToStartOfDay(startOfDate, timeZone, 1);

  const allHours = timeRangesOnSelectedDate.reduce((availableHours, t) => {
    // time-range: start and end
    const { start, end } = t;

    // If the date is after start, use the date.
    // Otherwise use the start time.
    const startLimit = dateIsAfter(startOfDate, start) ? startOfDate : start;

    // If the end date is after "the next day", use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(end, nextDay) ? nextDay : end;

    const hours = getStartHours(intl, timeZone, startLimit, endLimit).filter(t => {
      return parseInt(t.timeOfDay.split(':')[0]) >= 7 && parseInt(t.timeOfDay.split(':')[0]) <= 22;
    });
    return availableHours.concat(hours);
  }, []);
  return allHours;
};

const startTimeOrStartOfSelectedEndDay = (selectedStartTimeAsDate, startOfSelectedEndDay) =>
  dateIsAfter(selectedStartTimeAsDate, startOfSelectedEndDay)
    ? selectedStartTimeAsDate
    : startOfSelectedEndDay;

const parseFieldDateInput = timeZone => v =>
  v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v;

// React Dates calendar needs isDayBlocked function as props
// We check if the day belongs to one of the available time ranges
const isDayBlocked = (availableTimeRanges, timeZone) =>
  availableTimeRanges
    ? day =>
        !availableTimeRanges.find(timeRange =>
          isDayMomentInsideRange(day, timeRange.start, timeRange.end, timeZone)
        )
    : () => false;

const onExceptionStartDateChange = (value, timeRanges, props) => {
  const { timeZone, intl, form } = props;
  // reinit form
  // form.initialize({
  // exceptionStartDate: null,
  // exceptionStartTime: null,
  // exceptionEndDate: null,
  // exceptionEndTime: null,
  // });

  if (!value || !value.date) {
    form.batch(() => {
      // form.change('exceptionStartTime', null);
      // form.change('exceptionEndDate', { date: null });
      // form.change('exceptionEndTime', null);
    });
    return;
  }
  // This callback function (onExceptionStartDateChange) is called from react-dates component.
  // It gets raw value as a param - browser's local time instead of time in listing's timezone.
  const startDate = value.date;
  const timeRangesOnSelectedDate = getTimeRanges(timeRanges, startDate, timeZone);
  const { startTime, endDate, endTime } = getAllTimeValues(
    intl,
    timeZone,
    timeRangesOnSelectedDate,
    startDate
  );
  form.batch(() => {
    form.change('exceptionStartTime', startTime);
    form.change('exceptionEndDate', { date: endDate });
    form.change('exceptionEndTime', endTime);
  });
};

// Update current month and call callback function.
const onMonthClick = (currentMonth, setCurrentMonth, timeZone, onMonthChanged) => monthFn => {
  const updatedMonth = monthFn(currentMonth, timeZone);
  setCurrentMonth(updatedMonth);

  if (onMonthChanged) {
    const monthId = monthIdStringInTimeZone(updatedMonth, timeZone);
    onMonthChanged(monthId);
  }
};

const identity = v => v;

const LocationSearchFormComponent = props => {
  const [location, setLocation] = React.useState({});
  const [error, setError] = React.useState('');
  const handleChange = locations => {
    if (locations.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      setLocation(locations);
      // props.onSubmit({ location });
    }
  };

  const handleSubmit = values => {
    let data = {
      location: location.selectedPlace ? location : null,
      value: values.exceptionStartDate ? { ...values } : null,
    };
    props.onSubmit({ ...data });
  };
  const [currentMonth, setCurrentMonth] = React.useState(
    getMonthStartInTimeZone(TODAY, props.timeZone)
  );
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          intl,
          invalid,
          onMonthChanged,
          availabilityExceptions,
          timeZone,
          values,
          initialValues,
          updateInProgress,
          fetchErrors,
          disabled,
          valid,
          touched,
          errors,
        } = formRenderProps;
        const {
          exceptionStartDate,
          exceptionStartTime = null,
          exceptionEndDate,
          exceptionEndTime,
        } = (values !== {} && values) || initialValues || {};
        const classes = classNames(rootClassName || css.root, className);
        // Allow form submit only when the place has changed
        // const preventFormSubmit = e => e.preventDefault();

        const exceptionStartDay = extractDateFromFieldDateInput(exceptionStartDate);
        const exceptionEndDay = extractDateFromFieldDateInput(exceptionEndDate);

        const startTimeDisabled = !exceptionStartDate;
        const endTimeDisabled = !exceptionStartDate || !exceptionStartTime || !exceptionEndDate;

        // Get all the available time-ranges for creating new AvailabilityExceptions
        const availableTimeRanges = getAvailableTimeRangesForExceptions(
          availabilityExceptions,
          timeZone
        );
        const timeRangesOnSelectedDate = getTimeRanges(
          availableTimeRanges,
          exceptionStartDay,
          timeZone
        );

        const availableStartTimesRaw = getAvailableStartTimes(
          exceptionStartDay,
          timeRangesOnSelectedDate,
          intl,
          timeZone
        );
        const availableStartTimes = availableStartTimesRaw.filter(t => {
          return (
            parseInt(t.timeOfDay.split(':')[0]) >= 7 && parseInt(t.timeOfDay.split(':')[0]) <= 22
          );
        });
        const { startTime, endDate, selectedTimeRange } = getAllTimeValues(
          intl,
          timeZone,
          timeRangesOnSelectedDate,
          exceptionStartDay,
          exceptionStartTime || firstAvailableStartTime(availableStartTimes),
          exceptionEndDay || exceptionStartDay
        );

        const availableEndTimes = getAvailableEndTimes(
          exceptionStartTime || startTime,
          exceptionEndDay || endDate,
          selectedTimeRange,
          intl,
          timeZone
        ).filter(t => {
          return (
            parseInt(t.timeOfDay.split(':')[0]) >= 7 && parseInt(t.timeOfDay.split(':')[0]) <= 22
          );
        });

        // Returns a function that changes the current month
        // Currently, used for hiding next&prev month arrow icons.
        const handleMonthClick = onMonthClick(
          currentMonth,
          setCurrentMonth,
          timeZone,
          onMonthChanged
        );

        const submitInProgress = updateInProgress;
        const hasData =
          exceptionStartDate && exceptionStartTime && exceptionEndDate && exceptionEndTime;
        const submitDisabled = !hasData || invalid || disabled || submitInProgress;
        const emptySpaceDate =
          (touched.exceptionStartDate || touched.location) && !valid && props.showErrorMessage
            ? true
            : false;
        const emptySpaceLocation =
          (touched.exceptionStartDate || touched.location) && !valid && props.showErrorMessage
            ? true
            : false;
        return (
          <Form className={`${classes} LocationSearchFormValMsg`} onSubmit={handleSubmit}>
            <div className={css.field}>
              <FieldDateInput
                className={css.fieldDateInput}
                name="exceptionStartDate"
                id={`exceptionStartDate`}
                label="Search for a reservation"
                placeholderText={'Pick a date'}
                format={formatFieldDateInput(timeZone)}
                parse={parseFieldDateInput(timeZone)}
                isDayBlocked={isDayBlocked(availableTimeRanges, timeZone)}
                onChange={value => {
                  onExceptionStartDateChange(value, availableTimeRanges, formRenderProps);
                }}
                onPrevMonthClick={() => handleMonthClick(prevMonthFn)}
                onNextMonthClick={() => handleMonthClick(nextMonthFn)}
                navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
                navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
                useMobileMargins
                showErrorMessage={false}
               // validate={props.showErrorMessage ? bookingDateRequired('Date is required') : null}
                showEmpty={emptySpaceDate}
                // value={new Date()}
              />
            </div>

            <div className={css.LocationAutocompleteInputLanding}>
              <Field
                name="location"
                format={identity}
                validate={locationRequired('Location is required')}
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {
                    onChange(value);
                    handleChange(value);
                  };

                  const searchInput = { ...restInput, onChange: searchOnChange };
                  return (
                    <>
                      <LocationAutocompleteInput
                        // placeholder={intl.formatMessage({ id: 'LocationSearchForm.placeholder' })}
                        placeholder={'Choose your city'}
                        iconClassName={css.searchInputIcon}
                        inputClassName={css.searchInput}
                        predictionsClassName={css.searchPredictions}
                        input={searchInput}
                        meta={meta}
                        showErrorMessage={props.showErrorMessage}
                        showEmpty={emptySpaceLocation}
                      />
                    </>
                  );
                }}
              />
            </div>

            <div className={css.submitButton}>
              <Button
                disabled={props.showErrorMessage ? invalid : false}
                inProgress={submitInProgress}
                onClick={e => {
                  e.preventDefault();
                  handleSubmit({ ...values });
                }}
              >
                {/* <FormattedMessage id="EditListingAvailabilityExceptionForm.addException" /> */}
                View reservations
              </Button>
              {/* {(touched.exceptionStartDate || touched.location) &&
              !valid &&
              props.showErrorMessage ? (
                <div> &nbsp;</div>
              ) : (
                ''
              )} */}
            </div>
          </Form>
        );
      }}
    />
  );
};

LocationSearchFormComponent.defaultProps = { rootClassName: null, className: null };

LocationSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LocationSearchForm = injectIntl(LocationSearchFormComponent);

export default LocationSearchForm;
