/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import {
  FieldCheckbox,
  FieldTextInput,
  NamedLink,
  Form,
  IconSpinner,
  PrimaryButton,
  SavedCardDetails,
  StripePaymentAddress,
  StripePaymentPromoCode,
} from '../../components';
import config from '../../config';
import { ensurePaymentMethodCard } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './StripePaymentFormNoMessage.css';

/**
 * Translate a Stripe API error object.
 *
 * To keep up with possible keys from the Stripe API, see:
 *
 * https://stripe.com/docs/api#errors
 *
 * Note that at least at moment, the above link doesn't list all the
 * error codes that the API returns.
 *
 * @param {Object} intl - react-intl object from injectIntl
 * @param {Object} stripeError - error object from Stripe API
 *
 * @return {String} translation message for the specific Stripe error,
 * or the given error message (not translated) if the specific error
 * type/code is not defined in the translations
 *
 */
const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;

  if (!code || !type) {
    // Not a proper Stripe error object
    return intl.formatMessage({ id: 'StripePaymentFormNoMessage.genericError' });
  }

  const translationId =
    type === 'validation_error'
      ? `StripePaymentFormNoMessage.stripe.validation_error.${code}`
      : `StripePaymentFormNoMessage.stripe.${type}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

const stripeElementsOptions = {
  fonts: [
    {
      family: 'poppins',
      fontSmoothing: 'antialiased',
      src:
        'local("poppins"), local("Poppins"), url("https://assets-sharetribecom.sharetribe.com/webfonts/poppins/Poppins-Medium.ttf") format("truetype")',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: '"poppins", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const OneTimePaymentWithCardElement = props => {
  const { cardClasses, formId, handleStripeElementRef, hasCardError, error } = props;

  return (
    <React.Fragment>
      <label className={css.paymentLabel} htmlFor={`${formId}-card`}>
        <FormattedMessage id="StripePaymentFormNoMessage.paymentCardDetails" />
      </label>
      <div className={cardClasses} id={`${formId}-card`} ref={handleStripeElementRef} />
      {hasCardError ? (
        <span className={css.error}>
          {error}
          <div className={css.errorMessage}>
            If the errors persist, please contact your financial institution.
          </div>
        </span>
      ) : null}
      <div className={css.saveForLaterUse}>
        {/* <FieldCheckbox
          className={css.saveForLaterUseCheckbox}
          textClassName={css.saveForLaterUseLabel}
          id="saveAfterOnetimePayment"
          name="saveAfterOnetimePayment"
          label={labelText}
          value="saveAfterOnetimePayment"
          useSuccessColor
        /> */}
        <span className={css.saveForLaterUseLegalInfo}>
          I <span className={css.red}>authorize</span>
          <FormattedMessage id="StripePaymentFormNoMessage.saveforLaterUseLegalInfo" />
        </span>
      </div>
    </React.Fragment>
  );
};

const PaymentMethodSelector = props => {
  const {
    cardClasses,
    formId,
    changePaymentMethod,
    defaultPaymentMethod,
    handleStripeElementRef,
    hasCardError,
    error,
    paymentMethod,
    intl,
  } = props;
  const last4Digits = defaultPaymentMethod.attributes.card.last4Digits;
  const labelText = intl.formatMessage(
    { id: 'StripePaymentFormNoMessage.replaceAfterOnetimePayment' },
    { last4Digits }
  );

  return (
    <React.Fragment>
      <h3 className={css.paymentHeading}>
        <FormattedMessage id="StripePaymentFormNoMessage.payWithHeading" />
      </h3>
      <SavedCardDetails
        className={css.paymentMethodSelector}
        card={defaultPaymentMethod.attributes.card}
        onChange={changePaymentMethod}
      />
      {paymentMethod === 'replaceCard' ? (
        <OneTimePaymentWithCardElement
          cardClasses={cardClasses}
          formId={formId}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          label={labelText}
          intl={intl}
        />
      ) : null}
    </React.Fragment>
  );
};

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
    ? 'replaceCard'
    : selectedPaymentMethod;
};

const initialState = {
  error: null,
  cardValueValid: false,
  // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
  // Check SavedCardDetails component for more information
  paymentMethod: null,
  havePromoCodeChecked: false,
  handleDiscountPercentage: true,
  hasCouponPromoCode: true,
};

/**
 * Payment form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle payment. `stripe.confirmCardPayment`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class StripePaymentFormNoMessage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleCardValueChange = this.handleCardValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleCheckboxChecked = this.handleCheckboxChecked.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.initializeStripeElement = this.initializeStripeElement.bind(this);
    this.handleStripeElementRef = this.handleStripeElementRef.bind(this);
    this.setTermsError = this.setTermsError.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.finalFormAPI = null;
    this.cardContainer = null;
  }

  componentDidMount() {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentFormNoMessage');
    }

    if (config.stripe.publishableKey) {
      const {
        onStripeInitialized,
        hasHandledCardPayment,
        defaultPaymentMethod,
        loadingData,
      } = this.props;
      this.stripe = window.Stripe(config.stripe.publishableKey);
      onStripeInitialized(this.stripe);

      if (!(hasHandledCardPayment || defaultPaymentMethod || loadingData)) {
        this.initializeStripeElement();
      }
    }
  }

  componentWillUnmount() {
    if (this.card) {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
  }

  setTermsError(val) {
    this.setState({ termsError: val });
  }
  initializeStripeElement(element) {
    const elements = this.stripe.elements(stripeElementsOptions);

    //if (!this.card) {
      this.card = elements.create('card', { style: cardStyles });
      this.card.mount(element || this.cardContainer);
      this.card.addEventListener('change', this.handleCardValueChange);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (this.card) {
          if (window.innerWidth < 1024) {
            this.card.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
          } else {
            this.card.update({ style: { base: { fontSize: '20px', lineHeight: '32px' } } });
          }
        }
      });
   // }
  }

  changePaymentMethod(changedTo) {
    if (this.card && changedTo === 'defaultCard') {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
    this.setState({ paymentMethod: changedTo });
  }

  handleStripeElementRef(el) {
    this.cardContainer = el;
    if (this.stripe && el) {
      this.initializeStripeElement(el);
    }
  }

  handleCardValueChange(event) {
    const { intl } = this.props;
    const { error, complete } = event;

    const postalCode = event.value.postalCode;
    if (this.finalFormAPI) {
      this.finalFormAPI.change('postal', postalCode);
    }

    this.setState(prevState => {
      return {
        error: error ? stripeErrorTranslation(intl, error) : null,
        cardValueValid: complete,
      };
    });
  }
  handleSubmit(values) {
    const {
      onSubmit,
      inProgress,
      formId,
      hasHandledCardPayment,
      defaultPaymentMethod,
    } = this.props;
    const { initialMessage } = values;
    const hasPromoCode = values.promocode;
    const { cardValueValid, paymentMethod } = this.state;
    const billingDetailsKnown = hasHandledCardPayment || defaultPaymentMethod;
    const onetimePaymentNeedsAttention = !billingDetailsKnown && !cardValueValid;

    if(!hasPromoCode) {
      if (inProgress || onetimePaymentNeedsAttention) {
        // Already submitting or card value incomplete/invalid
        return;
      }
    } else {
      if (inProgress) {
        // Already submitting
        return;
      }
    }
    
    const params = {
      message: initialMessage ? initialMessage.trim() : null,
      card: this.card,
      formId,
      formValues: values,
      paymentMethod: getPaymentMethod(
        paymentMethod,
        ensurePaymentMethodCard(defaultPaymentMethod).id
      ),
    };
    onSubmit(params);
  }

  handleCheck(checked, secondparam) {

    this.setState({ havePromoCodeChecked: checked });
    if(secondparam.toLowerCase() === 'snatch21') {
      this.setState({ hasCouponPromoCode: true });
    } else {
      this.setState({ hasCouponPromoCode: false });
    }

    const {
      onSubmit,
      inProgress,
      formId,
      // hasHandledCardPayment,
      // defaultPaymentMethod,
    } = this.props;
    //const { initialMessage } = checked;
    const getPromoCode = checked;
    const promoCodeValue = secondparam;
    // const { cardValueValid, paymentMethod } = this.state;
    // const billingDetailsKnown = hasHandledCardPayment || defaultPaymentMethod;
    // const onetimePaymentNeedsAttention = !billingDetailsKnown && !cardValueValid;
    if (inProgress) {
      // Already submitting
      return;
    }

    const params = {
      formId,
      formValues: {getPromoCode:getPromoCode,promoCodeValue:promoCodeValue}
    };

    if (getPromoCode && promoCodeValue) {
      onSubmit(params);
    } else {
      return;
    }
  }

  handleCheckboxChecked(checkboxValue, havePromoCode) {
    if(checkboxValue && havePromoCode) {
      this.setState({ havePromoCodeChecked: true });
    } else {
      this.setState({ havePromoCodeChecked: false });
    }

    if(havePromoCode) {
      this.setState({ hasCouponPromoCode: true });
    } else {
      this.setState({ hasCouponPromoCode: false });
    }

    this.handleCheck(true, 'handlelistingfee'); //handle listing fee
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      loadingData,
      formId,
      paymentInfo,
      intl,
      initiateOrderError,
      hasPromCodeError,
      hasPromCodeSuccess,
      discountPercentage,
      confirmCardPaymentError,
      confirmPaymentError,
      invalid,
      handleSubmit,
      // handleCheck,
      // handleCheckboxChecked,
      form,
      hasHandledCardPayment,
      defaultPaymentMethod,
      values,
    } = formRenderProps;
    this.finalFormAPI = form;
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
    const billingDetailsNeeded = !(hasHandledCardPayment || confirmPaymentError);
    const billingDetailsKnown = hasHandledCardPayment || ensuredDefaultPaymentMethod;
    const onetimePaymentNeedsAttention = !billingDetailsKnown && !this.state.cardValueValid;
    const submitDisabled = invalid || onetimePaymentNeedsAttention || submitInProgress;
    const hasCardError = this.state.error && !submitInProgress;
    const hasPaymentErrors = confirmCardPaymentError || confirmPaymentError;
    const classes = classNames(rootClassName || css.root, className);
    const cardClasses = classNames(css.card, {
      [css.cardSuccess]: this.state.cardValueValid,
      [css.cardError]: hasCardError,
    });

    // TODO: confirmCardPayment can create all kinds of errors.
    // Currently, we provide translation support for one:
    // https://stripe.com/docs/error-codes
    const piAuthenticationFailure = 'payment_intent_authentication_failure';
    const paymentErrorMessage =
      confirmCardPaymentError && confirmCardPaymentError.code === piAuthenticationFailure
        ? intl.formatMessage({ id: 'StripePaymentFormNoMessage.confirmCardPaymentError' })
        : confirmCardPaymentError
        ? confirmCardPaymentError.message
        : confirmPaymentError
        ? intl.formatMessage({
            id: 'StripePaymentFormNoMessage.confirmPaymentError',
            values: { error: confirmPaymentError.message },
          })
        : intl.formatMessage({ id: 'StripePaymentFormNoMessage.genericError' });

    const billingDetailsNameLabel = intl.formatMessage({
      id: 'StripePaymentFormNoMessage.billingDetailsNameLabel',
    });

    const billingDetailsNamePlaceholder = intl.formatMessage({
      id: 'StripePaymentFormNoMessage.billingDetailsNamePlaceholder',
    });

    // Asking billing address is recommended in PaymentIntent flow.
    // In CheckoutPage, we send name and email as billing details, but address only if it exists.
    const billingAddress = (
      <StripePaymentAddress intl={intl} form={form} fieldId={formId} card={this.card} />
    );

    const havePromoCode = (
      <StripePaymentPromoCode intl={intl} form={form} fieldId={formId} card={this.card}  handleCheck={this.handleCheck} handleCheckboxChecked={this.handleCheckboxChecked} />
    );

    const termsLink = (
      <NamedLink className={css.termsLink} name={'TermsOfServicePage'}>
        <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
      </NamedLink>
    );

    const hasStripeKey = config.stripe.publishableKey;
    const showPaymentMethodSelector = ensuredDefaultPaymentMethod.id;
    const selectedPaymentMethod = getPaymentMethod(
      this.state.paymentMethod,
      showPaymentMethodSelector
    );
    const showOnetimePaymentFields = ['onetimeCardPayment', 'replaceCard'].includes(
      selectedPaymentMethod
    );

    let discountPerVariable = discountPercentage;

    if(!this.state.hasCouponPromoCode) {
      discountPerVariable = null;
    } else if(discountPerVariable === '100' || discountPerVariable === '20') {
      //Do nothing herer
    } else {
      discountPerVariable = null;
    }

    return hasStripeKey ? (
      <Form className={classes} onSubmit={handleSubmit}>
        {billingDetailsNeeded && !loadingData ? (
          <React.Fragment>
            <div>{havePromoCode}
                {hasPromCodeError && this.state.havePromoCodeChecked ? (
                  <span className={css.error}>
                    <div className={css.error}>
                      Invalid promo code<br />
                    </div>
                  </span>
                ) : hasPromCodeSuccess && this.state.havePromoCodeChecked && (discountPerVariable === '100' || discountPerVariable === '20') ? (
                  <span className={css.error}>
                    <div className={css.error}>
                      Promo Code Applied Successfully<br />
                    </div>
                  </span>
                ) : null}
                <br />
                <br />
            </div>
            { (showPaymentMethodSelector && discountPerVariable !== '100') ? (
              <React.Fragment>
              <PaymentMethodSelector
                cardClasses={cardClasses}
                formId={formId}
                defaultPaymentMethod={ensuredDefaultPaymentMethod}
                changePaymentMethod={this.changePaymentMethod}
                handleStripeElementRef={this.handleStripeElementRef}
                hasCardError={hasCardError}
                error={this.state.error}
                paymentMethod={selectedPaymentMethod}
                intl={intl}
              />
              </React.Fragment>
            ) :  (showOnetimePaymentFields && discountPerVariable !== '100') ? (
              <React.Fragment>
                <h3 className={css.paymentHeading}>
                  <FormattedMessage id="StripePaymentFormNoMessage.paymentHeading" />
                </h3>
                <OneTimePaymentWithCardElement
                  cardClasses={cardClasses}
                  formId={formId}
                  handleStripeElementRef={this.handleStripeElementRef}
                  hasCardError={hasCardError}
                  error={this.state.error}
                  intl={intl}
                />
              </React.Fragment>
            ) : null}
            

            {(showOnetimePaymentFields && discountPerVariable !== '100') ? (
              <div className={css.paymentAddressField}>
                <h3 className={css.billingHeading}>
                  <FormattedMessage id="StripePaymentFormNoMessage.billingDetails" />
                </h3>
                <FieldTextInput
                  className={css.field}
                  type="text"
                  id="name"
                  name="name"
                  autoComplete="cc-name"
                  label={billingDetailsNameLabel}
                  placeholder={billingDetailsNamePlaceholder}
                />

                {billingAddress}
              </div>
            ) : null}
          </React.Fragment>
        ) : loadingData ? (
          <p className={css.spinner}>
            <IconSpinner />
          </p>
        ) : null}

        {initiateOrderError ? (
          <span className={css.errorMessage}>
            {initiateOrderError.message}
            <div className={css.errorMessage}>
              If the errors persist, please contact your financial institution.
            </div>
          </span>
        ) : null}

        <div className={css.submitContainer}>
          {hasPaymentErrors ? (
            <>
              <span className={css.errorMessage}>
                {paymentErrorMessage}
                <div className={css.errorMessage}>
                  If the errors persist, please contact your financial institution.
                </div>
              </span>
              <br />
              {confirmPaymentError && (
                <span className={css.errorMessage}>
                  {confirmPaymentError.message}
                  <div className={css.errorMessage}>
                    If the errors persist, please contact your financial institution.
                  </div>
                </span>
              )}
            </>
          ) : null}
          <p className={css.paymentInfo}>{paymentInfo}</p>

          <p className={css.bottomWrapperText}>
            <FieldCheckbox
              id={`hasAcceptedSignupTerms`}
              className={css.textInputRow}
              name={`hasAcceptedSignupTerms`}
              label={
                <span className={css.termsText}>
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsAcceptText"
                    values={{ termsLink }}
                  />
                </span>
              }
              value={true}
            />
            {this.state.termsError && (
              <span className={css.error}>You must accept the Terms of Use</span>
            )}
          </p>

          {values?.hasAcceptedSignupTerms?.[0] ? (
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled && !values.hasAcceptedSignupTerms}
            >
              {billingDetailsNeeded ? (
                <>
                  <FormattedMessage id="StripePaymentForm.submitPaymentInfoPostIt" />{' '}
                  <span className={css.it}>It</span>
                </>
              ) : (
                <FormattedMessage id="StripePaymentForm.submitConfirmPaymentInfo" />
              )}
            </PrimaryButton>
          ) : (
            <PrimaryButton
              className={css.submitButton}
              // type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled && !values.hasAcceptedSignupTerms}
              onClick={e => {
                e.preventDefault();
                this.setTermsError(true);
              }}
            >
              {billingDetailsNeeded ? (
                <>
                  <FormattedMessage id="StripePaymentForm.submitPaymentInfoPostIt" />{' '}
                  <span className={css.it}>It</span>
                </>
              ) : (
                <FormattedMessage id="StripePaymentForm.submitConfirmPaymentInfo" />
              )}
            </PrimaryButton>
          )}
        </div>
      </Form>
    ) : (
      <div className={css.missingStripeKey}>
        <FormattedMessage id="StripePaymentFormNoMessage.missingStripeKey" />
      </div>
    );
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm onSubmit={this.handleSubmit} {...rest} render={this.paymentForm} />;
  }
}

StripePaymentFormNoMessage.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  showInitialMessageInput: true,
  hasHandledCardPayment: false,
  defaultPaymentMethod: null,
  initiateOrderError: null,
  hasPromCodeError: null,
  hasPromCodeSuccess: null,
  discountPercentage: null,
  confirmCardPaymentError: null,
  confirmPaymentError: null,
};

StripePaymentFormNoMessage.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  hasPromCodeError: bool,
  hasPromCodeSuccess: object,
  discountPercentage: string,
  confirmCardPaymentError: object,
  confirmPaymentError: object,
  formId: string.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  paymentInfo: string.isRequired,
  authorDisplayName: string.isRequired,
  showInitialMessageInput: bool,
  hasHandledCardPayment: bool,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
};

export default injectIntl(StripePaymentFormNoMessage);
