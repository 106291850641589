export const isSeller = user => {
  return user && !!user?.attributes?.profile?.publicData?.isSeller;
};

export const getPropByName = (theObject, search) => {
  var result = null;
  if (!!theObject) {
                     // eslint-disable-next-line 
                     for (const [key, value] of Object.entries(theObject)) {
                       if (key === search) {
                         result = value;
                       } else if (typeof value === 'object') {
                         result = getPropByName(value, search);
                       }
                       if (result) {
                         break;
                       }
                     }
                     return result;
                   }
};
