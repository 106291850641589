import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldTextInput, Form, IconHourGlass } from '../../components';
import { injectIntl, intlShape } from '../../util/reactIntl';
import css from './KeywordSearchForm.css';



class KeywordSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    // this.onChange = this.onChange.bind(this);
    this.searchInput = null;
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            values,
          } = formRenderProps;

          const classes = classNames(rootClassName, className);


          // Allow form submit only when the place has changed

          return (
            <Form
              className={classes}
              onSubmit={e => {
                e.preventDefault();
                this.props.onSubmit(values);
              }}
            >
              <FieldTextInput
              rootClassName={css.textInputRoot}
                inputRootClass={css.searchInput}
                icon={<IconHourGlass className={css.searchInputIcon} />}
                iconRootClass={css.iconWrapper}
                name={'keywords'}
                id={`keywords`}
                type="text"
                label={null}
                placeholder={'Steakhouse'}
                autoComplete="off"
              />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

KeywordSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

KeywordSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const KeywordSearchForm = injectIntl(KeywordSearchFormComponent);

export default KeywordSearchForm;
