import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-atlanta',
    predictionPlace: {
      address: 'Atlanta, Georgia',
      bounds: new LatLngBounds(
        new LatLng(34.22389042, -83.97611052),
        new LatLng(33.129873, -84.77874155)
      ),
    },
  },
  {
    id: 'default-dallas',
    predictionPlace: {
      address: 'Dallas, Texas',
      bounds: new LatLngBounds(
        new LatLng(33.016494008014, -96.5555139885032),
        new LatLng(32.6185720016946, -97.04103101)
      ),
    },
  },
  {
    id: 'default-miami',
    predictionPlace: {
      address: 'Miami, Florida',
      bounds: new LatLngBounds(
        new LatLng(25.9750167311984, -80.1233051180015),
        new LatLng(25.3976657642882, -80.87317902)
      ),
    },
  },

  {
    id: 'default-new-orleans',
    predictionPlace: {
      address: 'New Orleans, Louisiana',
      bounds: new LatLngBounds(
        new LatLng(30.177501984872, -89.6250500709309),
        new LatLng(29.8270090690494, -90.25627101)
      ),
    },
  },
  {
    id: 'default-orlando',
    predictionPlace: {
      address: 'Orlando, Florida',
      bounds: new LatLngBounds(
        new LatLng(28.6404040169798, -80.65064899),
        new LatLng(28.34708598, -81.6153389960402)
      ),
    },
  },

  // {
  //   id: 'default-los-angeles',
  //   predictionPlace: {
  //     address: 'Los Angeles, California, USA',
  //     bounds: new LatLngBounds(
  //       new LatLng(34.161440999758, -118.121305008073),
  //       new LatLng(33.9018913203336, -118.521456965901)
  //     ),
  //   },
  // },
  // {
  //   id: 'default-san-francisco',
  //   predictionPlace: {
  //     address: 'San Francisco, California, USA',
  //     bounds: new LatLngBounds(
  //       new LatLng(37.8324430069081, -122.354995082683),
  //       new LatLng(37.6044780500533, -122.517910874663)
  //     ),
  //   },
  // },
  // {
  //   id: 'default-seattle',
  //   predictionPlace: {
  //     address: 'Seattle, Washington, USA',
  //     bounds: new LatLngBounds(
  //       new LatLng(47.7779392908564, -122.216605992108),
  //       new LatLng(47.3403950185547, -122.441233019046)
  //     ),
  //   },
  // },
  // {
  //   id: 'default-portland',
  //   predictionPlace: {
  //     address: 'Portland, Oregon, USA',
  //     bounds: new LatLngBounds(
  //       new LatLng(45.858099013046, -122.441059986416),
  //       new LatLng(45.3794799927623, -122.929215816001)
  //     ),
  //   },
  // },
];
